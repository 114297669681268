import React from 'react';
import {Button, Card, Input} from "antd";
import {getDataFile} from "../../utils/apiUtils";
import useReadyState from "../../hooks/useReadyState";

const TurnaroundData = ({downloadFileWithSplitName, downloadFile}) => {
    const {state: turnValueId, onChangeReadyState: setTurnValueId} = useReadyState("")

    const handleOnChangeTurnId = (value) => setTurnValueId(value);

    const handleDownloadTurnPayload = async () => {
        const params = {turnaround_id: turnValueId}
        const payload = await getDataFile({path: 'turnarounds/payload', config: {params}, method: 'GET'})
        if (payload) {
            return downloadFile(payload, `payload_${params['turnaround_id']}`);
        }
    }

    const handleDownloadTurnEvents = async () => {
        const params = {turnaround_id: turnValueId, as_file: true}
        const events = await getDataFile({path: 'turnarounds/events', config: {params, responseType: 'blob'}, method: 'GET'})
        if (events) {
            return downloadFile(events, `${params['turnaround_id']}_events.csv`);
        }
    }

    const handleDownloadTurnValidInfo = async () => {
        const params = {turnaround_id: turnValueId}
        const validInfo = await getDataFile({path: 'turnarounds/validation', config: {params}, method: 'GET'})
        downloadFileWithSplitName(validInfo)
    }


    return (
        <Card title={'Turnaround data'} className={'downloadSection__systemCardBlock turnCardDataBlock'}>
            <section style={{width: '250px', margin: '0 auto 10px auto'}}>
                <Input placeholder={'turn id'} value={turnValueId}
                       onChange={(e) => handleOnChangeTurnId(e.target.value)}/>
            </section>
            <footer>
                <Button disabled={!turnValueId} onClick={handleDownloadTurnPayload}>Payload</Button>
                <Button disabled={!turnValueId} onClick={handleDownloadTurnEvents}>Events</Button>
                <Button disabled={!turnValueId} onClick={handleDownloadTurnValidInfo}>Validation info</Button>
            </footer>
        </Card>
    );
};

export default TurnaroundData;