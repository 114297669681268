import {createSlice} from "@reduxjs/toolkit";
import {
    addEvent,
    getEventFiles,
    getEventsName, getPositionsName,
    getTurnPotentialEvents,
    getProcessesName, getEventModes
} from "../../asyncThunkFunctions/Api/EventsRequest";


const initialState = {
    addEventObj: {
      isAddEvent: false,
      addEventError: null
    },

    eventFilesSetting: {
        eventFiles: null,
        isEventFiles: false,
        eventFilesError: null
    },

    potentialTurnEventsObj: {
        turnPotentialEvents: [],
        isTurnPotentialEvents: false,
        turnPotentialEventsError: null,
    },

    potentialEventsObj: {
        potentialEvents: {gate: "", camera: "", data: []},
        isPotentialEvents: false,
        potentialEventsError: null,
    },

    processesNameObj: {
        processes_name: [],
        isProcessesName: false,
        processesNameError: null
    },

    eventsNameObj: {
        event_names: [],
        isEventsName: false,
        eventsNameError: null
    },

    positionNamesObj: {
        position_names: [],
        isPositionsName: false,
        positionsNameError: null
    },

    scrollEventTableObj: {},
}


const eventSlice = createSlice({
    name: 'events',
    initialState,
    reducers: {
        setEventScrollTableSettings(state, {payload}) {
            state.scrollEventTableObj = payload
        },

        returnSomeInitialState(state, {payload}) {
            state[payload] = initialState[payload]
        },

        returnInitialEventState: () => initialState
    },
    extraReducers: (builder) => {
        builder.addCase(addEvent.pending, (state) => {
            state.addEventObj.isAddEvent = true
            state.addEventObj.addEventError = null
        })
            .addCase(addEvent.fulfilled, (state) => {
                state.addEventObj.isAddEvent = false
            })
            .addCase(addEvent.rejected, (state, {error}) => {
                state.addEventObj.addEventError = error['message']
                state.addEventObj.isAddEvent = false
            })

        builder.addCase(getEventFiles.pending, (state) => {
            state.eventFilesSetting.isEventFiles = true
            state.eventFilesSetting.eventFilesError = null
        })
            .addCase(getEventFiles.fulfilled, (state, {payload}) => {
                state.eventFilesSetting.eventFiles = payload
                state.eventFilesSetting.isEventFiles = false
            })
            .addCase(getEventFiles.rejected, (state, {error}) => {
                state.eventFilesSetting.isEventFiles = false
                state.eventFilesSetting.eventFilesError = error['message']
            })

        builder.addCase(getTurnPotentialEvents.pending, (state, ) => {
            state.potentialTurnEventsObj.isTurnPotentialEvents = true
            state.potentialTurnEventsObj.turnPotentialEventsError = null
        })
            .addCase(getTurnPotentialEvents.fulfilled, (state, {payload}) => {
                state.potentialTurnEventsObj.turnPotentialEvents = payload
                state.potentialTurnEventsObj.isTurnPotentialEvents = false
            })
            .addCase(getTurnPotentialEvents.rejected, (state, {error}) => {
                state.potentialTurnEventsObj.isTurnPotentialEvents = false
                state.potentialTurnEventsObj.turnPotentialEventsError = error['message']
            })


        builder.addCase(getProcessesName.pending, (state) => {
            state.processesNameObj.isProcessesName = true
            state.processesNameObj.processesNameError = null
        })
            .addCase(getProcessesName.fulfilled, (state, {payload}) => {

                state.processesNameObj.processes_name = payload
                state.processesNameObj.isProcessesName = false
            })
            .addCase(getProcessesName.rejected, (state, {error: {message}}) => {
                state.processesNameObj.processesNameError = message
                state.processesNameObj.isProcessesName = false
            })

        builder.addCase(getEventsName.pending, (state) => {
            state.eventsNameObj.isEventsName = true
            state.eventsNameObj.eventsNameError = null
        })
            .addCase(getEventsName.fulfilled, (state, {payload}) => {

                state.eventsNameObj.event_names = payload
                state.eventsNameObj.isEventsName = false
            })
            .addCase(getEventsName.rejected, (state, {error: {message}}) => {
                state.eventsNameObj.eventsNameError = message
                state.eventsNameObj.isEventsName = false
            })

        builder.addCase(getPositionsName.pending, (state) => {
            state.positionNamesObj.isPositionsName = true
            state.positionNamesObj.positionsNameError = null
        })
            .addCase(getPositionsName.fulfilled, (state, {payload}) => {

                state.positionNamesObj.position_names = payload
                state.positionNamesObj.isPositionsName = false
            })
            .addCase(getPositionsName.rejected, (state, {error: {message}}) => {
                state.positionNamesObj.positionsNameError = message
                state.positionNamesObj.isPositionsName = false
            })

        builder.addCase(getEventModes.pending, (state, ) => {
            state.potentialEventsObj.isPotentialEvents = true
            state.potentialEventsObj.potentialEventsError = null
        })
            .addCase(getEventModes.fulfilled, (state, {payload}) => {
                state.potentialEventsObj.potentialEvents = payload
                state.potentialEventsObj.isPotentialEvents = false
            })
            .addCase(getEventModes.rejected, (state, {error}) => {
                state.potentialEventsObj.isPotentialEvents = false
                state.potentialEventsObj.potentialEventsError = error['message']
                state.potentialEventsObj.potentialEvents = []
            })
    }
})


export default eventSlice.reducer

export const {returnInitialEventState, returnSomeInitialState, setEventScrollTableSettings} = eventSlice.actions