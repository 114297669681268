import {useDispatch} from "react-redux";
import usePagination from "../../hooks/usePagination";
import {getPerformances} from "../../store/asyncThunkFunctions/Api/PerformanceRequests";
import {Table} from "antd";
import {paginationSettings} from "../../utils/tableUtils/tableSettings";
import {useEffect, useMemo} from "react";
import {scrollByY} from "../../utils/helpers/tableScrollHelpers";
import {PERF_BUSY_SPACE} from "../../utils/Sizes";
import {performanceExpandableColumns} from "../../utils/tableUtils/columns/performance";

const Hours24 = ({dataSource, columns, loading, total}) => {
    const dispatch = useDispatch();
    const {page, limit, setSettings} = usePagination(1, 10);

    const refreshPerformanceData = (page = 1, limit = 10) => setSettings(page, limit);

    const onChange = async ({ current, pageSize }, filters, {order, field}) => refreshPerformanceData(current, pageSize);

    const setPeckedges = useMemo(() => {
        return (obj) => {
            let list_of_packages = []
            for (let key in obj) {
                if (key.includes('ia-')) {
                    list_of_packages.push({
                        'package': key,
                        'version': obj[key]
                    })
                }
            }
            return list_of_packages
        }
    }, [dataSource])

    useEffect(() => dispatch(getPerformances({page, limit, body: {"service": "aggregator"}})), [page, limit])

    return (
        <Table
            dataSource={dataSource}
            columns={columns}
            loading={loading}
            onChange={onChange}
            pagination={{...paginationSettings(page, limit, total)}}
            scroll={{y: scrollByY(PERF_BUSY_SPACE)}}
            rowKey={({_id}) => _id}
            expandable={{expandedRowRender: (record) => {
                    return <Table dataSource={setPeckedges(record)} columns={performanceExpandableColumns()} className={'nestedTable'}/>
                },
                defaultExpandedRowKeys: ['0']
            }}
        />
    )
}

export default Hours24