import React from 'react';
import {Form, Table} from "antd";
import {globalProps} from "../../utils/tableUtils/tableSettings";

const EditableTable = ({form, table}) => {
    return (
        <Form {...form}>
            <Table {...globalProps} {...table}/>
        </Form>
    );
};

export default EditableTable;