import React from 'react';
import {Tooltip} from "antd";
import {STOP} from "../icons/antd";
import classNames from "classnames";
import {ReactComponent as CalibrationResultIcon} from "../../assets/svg/target-30.svg"
import CalibrationTooltipInformation from "./CalibrationTooltipInformation";
import {calibrationIconColorResults, calibrationTooltipColors} from "../../utils/constants/objects/configuration";
import {extraCalibrationStatues} from "../../utils/constants/arrays/configuration";

const CalibrationStatusTooltip = ({status, position, error}) => {

    const calibrationResultClasses = classNames("camera-icon-result-container__calibration-result", {
        "animated": status === "validating" || status === "calibrating",
        "failed": status === "failed",
        "bridge": position === "bridge"
    });
    const statusColor = extraCalibrationStatues.includes(status) ? "#ffffff" : "#000000"
    const tooltipColor = calibrationTooltipColors[status]
    const iconResColor = calibrationIconColorResults[status]

    const tooltipTitle = <CalibrationTooltipInformation status={status} error={error} statusColor={statusColor}/>

    return (
        <div className={calibrationResultClasses}>
            <Tooltip placement={"topLeft"} title={tooltipTitle} color={tooltipColor}>
                <CalibrationResultIcon color={iconResColor}/>
                {status === "failed" && <span className={"failed-icon-result"}>{STOP}</span>}
            </Tooltip>
        </div>
    );
};

export default CalibrationStatusTooltip;