export const calibrationTooltipColors = {
    excellent: "#00C322",
    good: "yellow",
    bad: "#FF0000",
    calibrating: "#1F618D",
    validating: "#1F618D",
    failed: "#FF0000",
    changed: "black"
}

export const calibrationIconColorResults = {
    excellent: "#00C322",
    good: "#e3f542",
    bad: "#FF0000",
    failed: "#FF0000",
    validating: "#1F618D",
    calibrating:"#1F618D",
}


export const ConfirmTitles = {
    showCamera: 'Do you want to see it?',
    edit: "Sure to edit it?",
    remove: "Sure to remove it?",
    disable: "Sure to disable it?",
    save: "Sure to save it ?",
    addCamera: "Sure to add cameras?",
    removeGate: "Sure to remove gate?"
}


export const TooltipTitles = {
    previewCameraInform: 'Preview camera',
    editCamera: 'edit camera',
    removeCamera: 'remove camera',
    disableCamera: 'disable camera',
    activeCamera: 'active camera',
    removeGate: 'remove gate',
    editGateName: 'edit gate name',
    saveGateName: 'save gate name',
    exitForm: 'exit form',
    addCamera: 'add camera'
}