import React from 'react';
import {Route, Routes} from "react-router-dom";
import {CONFIGURATION_PATHS, fallbackPath, homeLink} from "../utils/routeUtils/Paths";
import FallBackRoute from "./security/FallBackRoute";
import GatesCameras from "../pages/configuration/GatesCameras";
import ImportExport from "../pages/configuration/ImportExport";
import PresentationSettings from "../pages/configuration/PresentationSettings";
import EventsModes from "../pages/configuration/EventsModes";
import MonitoringObjects from "../pages/configuration/MonitoringObjects";

const Configuration = () => {
    const {gates_cameras, import_export, presentation, events, objects} = CONFIGURATION_PATHS

    return (
        <Routes>
            <Route path={gates_cameras} element={<GatesCameras/>}/>
            <Route path={import_export} element={<ImportExport/>}/>
            <Route path={presentation} element={<PresentationSettings/>}/>
            <Route path={events} element={<EventsModes/>}/>
            <Route path={objects} element={<MonitoringObjects/>}/>
            <Route path={fallbackPath} element={<FallBackRoute homeLink={homeLink}/>}/>
        </Routes>
    );
};

export default Configuration;