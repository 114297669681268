import {useEffect, useState} from 'react';
import {Checkbox} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {
    editEventRequired,
    getEventModes
} from "../../../store/asyncThunkFunctions/Api/EventsRequest";
import {selectPotentialEvents} from "../../../store/selectors/Api/events";

const RequiredEventModes = ({isActive, event}) => {
    const dispatch = useDispatch();
    const {potentialEvents: {gate, camera}} = useSelector(selectPotentialEvents);

    const [active, setActive] = useState(isActive);

    const asyncHandler = async (value, dispatch) => {
        await dispatch(editEventRequired({required: value, gate, ...event}));
        await dispatch(getEventModes({gate, camera}));
    }

    const handleOnChangeIsRequired = (event) => {
        const value = event.target.checked
        setActive(value);
        asyncHandler(value, dispatch);
    }


    useEffect(() => setActive(isActive), [isActive]);

    return <Checkbox checked={active} onChange={handleOnChangeIsRequired} className={"required_event_checkbox"}/>
};

export default RequiredEventModes;