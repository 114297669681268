import {createAsyncThunk} from "@reduxjs/toolkit";
import {Saved_Turn_Websocket} from "../../../services/Websocket";
import {returnToInitSaveTurnState} from "../../slices/Websocket/ArchiveTurn";
import {getFeedbackNameSession, onChangeFinishPrepareState} from "../../slices/Ui/Notification";
import {toast} from "react-toastify";
import {onChangeIsOpenSession} from "../../slices/Websocket/General";
import {v4 as uuidv4} from "uuid";
import {getTurnaround} from "../Api/TurnaroundRequests";

export const connectionToArchiveTurnSession = createAsyncThunk(
    'ws_archive_turn/connectionToSaveTurnSession',
    async (_, {dispatch, getState}) => {
        const {systemSlice: {settingsUI}} = getState().api
        const path = `${settingsUI.WEBSOCKET_URL}?identifier=${uuidv4()}`
        await Saved_Turn_Websocket.connection(path);
        dispatch(getMessageInArchiveTurnSession());
    })

export const sendMessageInArchiveTurnSession = createAsyncThunk(
    'ws_archive_turn/sendMessageInSaveTurnSession',
    async (message, {dispatch}) => {
        await Saved_Turn_Websocket.sendMessage(message);
    })

export const getMessageInArchiveTurnSession = createAsyncThunk(
    'ws_archive_turn/getMessageInSaveTurnSession',
    async (_, {dispatch, getState}) => {
        await Saved_Turn_Websocket.getMessage((event) => {
            const parsed_data = JSON.parse(event.data);
            const message = parsed_data.message;
            const turnaround_id = getState().api.turnaroundSlice.turnaround?._id

            if (message !== 'message recieved' && message !== 'connection accepted') {
                console.log(parsed_data, 'parsed_data')

                if (parsed_data.data === "error_already_archived") toast.warning('This turnaround is already archived');

                if (parsed_data.data === 'success') toast.success('The turnaround has been archived');
                if (parsed_data.data === "removed") toast.warning("The turnaround has been remove from archive");

                dispatch(getFeedbackNameSession('archive_and_remove_turn'));
                dispatch(onChangeFinishPrepareState(true));
                dispatch(closeArchiveTurnSession());
                dispatch(getTurnaround(turnaround_id))
            }
        })
    }
)

export const closeArchiveTurnSession = createAsyncThunk('ws_archive_turn/closeSaveTurnSession',
    async (_, {dispatch}) => {
    await Saved_Turn_Websocket.disconnect();
    dispatch(onChangeIsOpenSession(false));
    dispatch(returnToInitSaveTurnState());
})