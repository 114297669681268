import React from 'react';
import {Modal} from "antd";
import TurnaroundDetails from "./TurnaroundDetails";

const TurnaroundDetailsModal = ({turnaround, isOpen, onClose}) => {
    return (
        <Modal open={isOpen} onCancel={onClose} footer={null}>
            <TurnaroundDetails turnaround={Object.entries(turnaround)}/>
        </Modal>
    )
};

export default TurnaroundDetailsModal;