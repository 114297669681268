export const normalizeEventPositionFields = ['Process', 'State', 'Position', 'Camera', 'Timestamp', 'Offset', 'Frame_Id', 'Track_Id', 'Bbox'];

export const typesOcclusionsOption = [
    {value: "Fully visible", label: "Fully visible"},
    {value: "Partially occluded", label: "Partially occluded"},
    {value: "Fully occluded", label: "Fully occluded"}
];

export const defaultEventEditFormState = [
    {value: 'time', label: 'time', isShowSelect: false, isShowDatePicker: true, isShowOcclusion: true},
    {value: 'wrong detections', label: 'wrong detections', isShowSelect: true, isShowDatePicker: true, hideLabelSelect: 'Detections', isShowOcclusion: true},
    {value: 'no detection', label: 'no detection', isShowSelect: true, isShowDatePicker: true, hideLabelSelect: 'Detections', isShowOcclusion: true},
    {value: 'location', label: 'location', isShowSelect: true, hideLabelSelect: 'Positions', isShowOcclusion: true},
    {value: "event didn't happen", label: "event didn't happen", isShowSelect: false, isShowOcclusion: true},
    {value: 'other', label: 'other', isShowSelect: false, isShowLabel: false, isShowOcclusion: false},
]