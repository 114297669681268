import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../services/airAxios";
import {toast} from "react-toastify";

export const addTurnPost = createAsyncThunk('mock/addTurnPost',
    async (YML_File ) => {
    try {
        const {data} = await air_axios_settings.post(`mock/add_turnaround`, YML_File);
        const statusText = data[0][1][0];
        const statusErrorText = data[0][1][1]
        const text = String(statusErrorText).split('.')[0]

        console.log(data[0][1][1], 'statusText')

        if (statusText === 'ok') {
            toast.success('Successful added turnaround');
        } else if (statusText.endsWith('Error')) {
            toast.error(text);
        } else {
            toast.warning(String(statusErrorText));
        }

        return data[0][0];
    } catch (error) {
        throw new Error(error)
    }
})