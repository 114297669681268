import {useDispatch} from "react-redux";
import {imgFilePattern} from "../utils/constants/variables/regex";
import {toast} from "react-toastify";
import {onUploadInMultiFrameObj, onUploadNewFrame} from "../store/slices/Api/Frames";
import {setFile, setFiles} from "../store/slices/Ui/Files";

const useUploadFrameHandler = (cctv_id) => {
    const dispatch = useDispatch();

    return (e) => {
        const file = e.target.files[0];
        const isImageType = imgFilePattern.test(file?.name);
        if (file && !isImageType) {
            toast.error("Invalid file type");
            return
        }

        if (file && isImageType) {
            dispatch(onUploadInMultiFrameObj({cctv_id, uploadedFrame: URL.createObjectURL(file)}))
            dispatch(setFiles({fileKey: cctv_id, fileData: file}));
            toast.success("Uploaded successfully");
        }
    }
}

export default useUploadFrameHandler;