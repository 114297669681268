import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../services/airAxios";
import dayjs from "dayjs";
import {returnCorrectFormatDate} from "../../../utils/helpers/timeHelper";
import {changeCanvasLayersFieldPositions} from "../../../utils/constants/objects/canvas";
import {saveAs} from 'file-saver';

export const getUiSettings = createAsyncThunk('system/getUiSettings',
    async () => {
        try {
            const {data} = await air_axios_settings.get('/system/ui_settings');
            return {...data, CANVAS_LAYERS: changeCanvasLayersFieldPositions(data['CANVAS_LAYERS'])};
        } catch (error) {
            throw new Error(error)
        }
    })

export const editUiSettings = createAsyncThunk('system/editUiSettings',
    async (body = {}) => {
        try {
            const {data} = await air_axios_settings.put('/system/ui_settings', body)
            return {...data, CANVAS_LAYERS: changeCanvasLayersFieldPositions(data['CANVAS_LAYERS'])};
        } catch (error) {
            throw new Error(error)
        }
    })

export const getBackupFile = createAsyncThunk('system/getBackup',
    async () => {
        try {
            const {data} = await air_axios_settings.get('/system/backup', {responseType: 'blob'})
            const file_name = `backup/${returnCorrectFormatDate(dayjs(), 'HH:mm:ss')}`;
            saveAs(data, `${file_name}.json`);
            return data
        } catch (error) {
            throw new Error(error)
        }
    })

export const sendRestoreFile = createAsyncThunk('system/sendRestore',
    async (restore) => {
        try {
            const {data} = await air_axios_settings.post('/system/restore', restore)
            return data
        } catch (error) {
            throw new Error(error)
        }
    })