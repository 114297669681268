import {memo} from 'react';
import {Tag} from "antd";
import {useSelector} from "react-redux";
import {returnCorrectTimeZoneTime} from "../../utils/helpers/timeHelper";
import {selectTimeZone} from "../../store/selectors/Ui/TimeZone";

const MarkTurn = ({state, color, value, handler, icon, mode, title}) => {
    const timeZone = useSelector(selectTimeZone);

    if (state && Object.keys(state).length) {

        switch (mode) {
            case 'timeLine':
                return (
                    <Tag color={color} className={'turnTimeLineMark'}>
                        <span>{returnCorrectTimeZoneTime(state?.ata_utc || state?.sta_utc, timeZone)}</span>
                        -
                        <span>{returnCorrectTimeZoneTime(state?.atd_utc || state?.std_utc, timeZone)}</span>
                    </Tag>
                )
            case 'status':
                return <Tag icon={icon} color={color}>{value}</Tag>
            case 'turn':
                return (
                    <Tag color={color} onClick={handler} title={title}>
                        {state?.airline} {state?.arrival_flight_number} {state?.arrival_airport}
                        -
                        {state?.departure_flight_number} {state?.departure_airport}
                    </Tag>
                )
            default:
                return <Tag color={'default'}>These data are not available</Tag>
        }
    }
    return null
};

export default memo(MarkTurn);