import React from 'react';
import {getCashProfile} from "../../utils/helpers/cacheHelpers";
import {Outlet} from "react-router-dom";
import FallBackRoute from "./FallBackRoute";
import {homeLink} from "../../utils/routeUtils/Paths";

const RoleAccess = ({roles}) => {
    const profile = getCashProfile();
    return roles.includes(profile?.role) ? <Outlet/> : <FallBackRoute homeLink={homeLink}/>
};

export default RoleAccess;