import MarkTurn from "../../../components/LiveView/MarkTurn";
import {CLOCK_CIRCLE, SYNC_LOADING} from "../../../components/icons/antd";
import EmptyField from "../../../components/Customs/EmptyField";

export const liveViewColumns = (handleGetTurnDate) => ([
    {
        key: '_id',
        title: 'Gate',
        dataIndex: '_id',
        render: (_id) => <EmptyField isEmpty={!_id} message={'NGN'}>{_id}</EmptyField>
    },
    {
        key: 'turnaround',
        title: 'Turnaround',
        dataIndex: 'turnaround',
        render: (turnaround, {next_turnaround}) => {
            return (
                <div className={'live-view-wrapper__column turnaround_column'}>
                    <MarkTurn
                        mode={'turn'}
                        state={turnaround}
                        color={'processing'}
                        handler={() => handleGetTurnDate(turnaround)} title={'CLICK HERE'}
                    />
                    <MarkTurn
                        mode={'turn'}
                        state={next_turnaround}
                        color={'waiting'} handler={() => handleGetTurnDate(next_turnaround)}
                    />
                </div>

            )
        }
    },
    {
        key: 'Schedule',
        title: 'Schedule',
        dataIndex: 'Schedule',
        render: (_, {turnaround, next_turnaround}) => {
            return (
                <div className={'live-view-wrapper__column'}>
                    <MarkTurn mode={'timeLine'} state={turnaround} color={'processing'}/>
                    <MarkTurn mode={'timeLine'} state={next_turnaround} color={'waiting'}/>
                </div>
            )
        }
    },
    {
        key: 'status',
        title: 'status',
        dataIndex: 'status',
        render: (_, {turnaround, next_turnaround}) => {
            return (
                <div className={'live-view-wrapper__column'}>
                    <MarkTurn
                        mode={'status'}
                        state={turnaround}
                        status={'processing'}
                        value={'turnaround'}
                        color={'processing'}
                        icon={SYNC_LOADING}
                    />
                    <MarkTurn
                        mode={'status'} state={next_turnaround}
                        value={'coming soon'}
                        color={'waiting'}
                        icon={CLOCK_CIRCLE}
                    />
                </div>

            )
        }
    }
])