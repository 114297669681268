import {useDispatch} from "react-redux";
import useReadyState from "../useReadyState";
import {getListOfCctvID} from "../../store/asyncThunkFunctions/Api/GateRequest";
import {resetPartOfGateState} from "../../store/slices/Api/sliceGateRequest";

export default function ({onCanselEditField, cctvIds, position}) {
    const dispatch = useDispatch();

    const {state: camera, onChangeReadyState: setCamera} = useReadyState("default");
    const {state: gate, onChangeReadyState: setGate} = useReadyState("default");

    const onSelectGate = (selectedGate) => {
        onCanselEditField()
        setGate(selectedGate)
        if (camera !== "default") setCamera("default");
        if (selectedGate !== "default") dispatch(getListOfCctvID({_id: selectedGate, position}));
        if (selectedGate === "default" && cctvIds.length > 0) dispatch(resetPartOfGateState('cctvIdsObj'));
    }

    const onSelectCctvId = (selectedCctvId) => {
        onCanselEditField()
        setCamera(selectedCctvId)
    }

    return {camera, gate, onSelectGate, onSelectCctvId}
}