import React from 'react';
import {TooltipTitles} from "../../utils/constants/objects/configuration";
import {Button, Tooltip} from "antd";
import classNames from "classnames";
import {crossedCamera, unCrossedCamera} from "../icons/personal";

const PreviewCameraButton = ({handler, isConnected, isDisabled}) => {
    const cameraIconClasses = classNames("camera-icon-result-container__camera-icon", {
        "connected": isConnected && !isDisabled,
        "disconnected": !isConnected && !isDisabled,
        "disabled": isDisabled
    });

    const Icon = isDisabled ? unCrossedCamera : isConnected ? unCrossedCamera : crossedCamera

    return (
        <Tooltip
            placement="bottom"
            title={TooltipTitles.previewCameraInform}
        >
            <Button icon={<Icon/>} className={cameraIconClasses} onClick={handler}/>
        </Tooltip>
    );
};

export default PreviewCameraButton;