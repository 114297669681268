export const drawCircle = (ctx, centerX, centerY, radius, lineWidth, color, fill= false) => {
    if (!ctx) return
    ctx.beginPath();
    ctx.arc(Math.round(centerX), Math.round(centerY), radius, 0, 2 * Math.PI, false);
    if (fill) {
        ctx.fillStyle = color;
        ctx.fill();
    }
    ctx.lineWidth = lineWidth;
    ctx.strokeStyle = color;
    ctx.stroke();
    ctx.closePath();
}