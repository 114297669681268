import {useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {toast} from "react-toastify";
import {onChangeFinishPrepareState, removePrepareToastId} from "../store/slices/Ui/Notification";
import {
    closeDownloadSession, sendToDownloadFileMessageSession
} from "../store/asyncThunkFunctions/Weboscket/DownloadFileMessages";
import {selectProfile} from "../store/selectors/Api/auth";
import {saveAs} from 'file-saver';
import {
    selectDownloadFileName,
    selectDownloadListPartsFile,
    selectDownloadWsFile
} from "../store/selectors/Websocket/download";
import {selectNotifSlice} from "../store/selectors/Ui/notification";

const WebsocketProcess = () => {
    const dispatch = useDispatch();

    const {
        isFinishToPrepare,
        objOfPrepareToasts,
        closeNamePrepareToast,
        isDownloadFile
    } = useSelector(selectNotifSlice);
    const array_file_parts = useSelector(selectDownloadListPartsFile);
    const {path} = useSelector(selectDownloadWsFile);
    const download_file_name = useSelector(selectDownloadFileName);
    const profile = useSelector(selectProfile);

    const download_video_file = () => {
        const blob_file = new Blob(array_file_parts)
        saveAs(blob_file, `${download_file_name}.mp4`);
        dispatch(sendToDownloadFileMessageSession({type: "delete", params: {path, token: profile.token}}));
        dispatch(closeDownloadSession());
        toast.success('Downloaded file')
    }

    useEffect(() => {
        if (isFinishToPrepare) {
            toast.dismiss(objOfPrepareToasts[closeNamePrepareToast])
            dispatch(onChangeFinishPrepareState(false));
            dispatch(removePrepareToastId());
        }
    }, [isFinishToPrepare]);

    useEffect(() => {
        if (isDownloadFile && path) {
            download_video_file()
        }
    }, [isDownloadFile, path]);

    return null
};

export default WebsocketProcess;