import {useEffect} from 'react';
import {Table} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getAllGates} from "../store/asyncThunkFunctions/Api/GateRequest";
import {returnCorrectTimeZoneTime} from "../utils/helpers/timeHelper";
import {resetGateState, handleOnChangeIsUpdateDataStatus} from "../store/slices/Api/sliceGateRequest";
import {paginationSettings} from "../utils/tableUtils/tableSettings";
import usePagination from "../hooks/usePagination";
import {liveViewColumns} from "../utils/tableUtils/columns/liveView";
import {cameraColumns} from "../utils/tableUtils/columns/camera";
import {clearImgStates} from "../store/slices/Api/Frames";
import {returnResendingInitialState, setIsOpenExpandableRowItem} from "../store/slices/Ui/Resending";
import {selectTimeZone} from "../store/selectors/Ui/TimeZone";
import {selectAllGatesSlice} from "../store/selectors/Api/gates";
import {getStorageValue, setCacheTableSettingsGroup} from "../utils/helpers/cacheHelpers";
import {scrollByY} from "../utils/helpers/tableScrollHelpers";
import {LIVE_VIEW_BUSY_SPACE} from "../utils/Sizes";
import TurnaroundDetailsModal from "../components/TurnaroundInformation/TurnaroundDetailsModal";
import useReadyState from "../hooks/useReadyState";

const LiveView = () => {
    const {page, limit, setSettings} = usePagination();
    const dispatch = useDispatch();

    const timeZone = useSelector(selectTimeZone);
    const {gates, loading, total, isUpdateGateData} = useSelector(selectAllGatesSlice);

    const {state: turn, onChangeReadyState: setTurn} = useReadyState({});
    const {state: openTurnDetails, onChangeReadyState: setOpenTurnDetails} = useReadyState(false);

    const updateLastCameraDate = (date) => returnCorrectTimeZoneTime(date, timeZone);

    const handleCloseTurnModal = () => {
        setOpenTurnDetails(false)
        setTurn({});
    }

    const handleGetTurnDate = async (turnaround) => {
        await setTurn(turnaround)
        await setOpenTurnDetails(true)
    }

    const refreshLiveView = ({page = 1, limit = 5, tools}) => {
        dispatch(handleOnChangeIsUpdateDataStatus(true))
        setSettings(page, limit, tools);
    }

    const handleChangePage = ({ current: page, pageSize: limit }) => {
        const liveViewSettings = {page, limit};
        setCacheTableSettingsGroup('liveView', liveViewSettings);
        refreshLiveView(liveViewSettings);
    }

    const handleOnChangeExpandedRowState = (_id, isOpen) => dispatch(setIsOpenExpandableRowItem({_id, isOpen}));


    const ExpandedLiveVIewTable = ({cameras, turnaround, _id}) => {
        return (
            <Table
                rowClassName={record => record.mode === 'disabled' && 'disabled-row'}
                columns={cameraColumns(updateLastCameraDate, turnaround?.id, _id)}
                dataSource={cameras} pagination={false}
                rowKey={({cctv_id}) => cctv_id} className={'nestedTable'}
            />
        )
    }

    useEffect(() => {
        const liveViewCacheSettings = getStorageValue('tableGroupsSetting', {}, 'local')

        if (liveViewCacheSettings.liveView) refreshLiveView(liveViewCacheSettings.liveView);
        else dispatch(handleOnChangeIsUpdateDataStatus(true))
    }, [])

    useEffect(() => {
        if (isUpdateGateData) {
            dispatch(getAllGates({page, limit}))
            dispatch(handleOnChangeIsUpdateDataStatus(false))
        }
    }, [isUpdateGateData]);

    useEffect(() => {
        return () => {
            console.log('lost liveView page')
            dispatch(resetGateState())
            dispatch(returnResendingInitialState())

            dispatch(clearImgStates())
        }
    }, [])

    return (
        <div className={'live-view-wrapper'}>
            <Table
                columns={liveViewColumns(handleGetTurnDate)}
                onChange={handleChangePage}
                dataSource={gates}
                expandable={{
                    expandedRowRender: (turn) => <ExpandedLiveVIewTable {...turn}/>,
                    defaultExpandedRowKeys: ['0'],
                    onExpand: (isOpen, row) => handleOnChangeExpandedRowState(row['_id'], isOpen)
                }}
                rowKey={({_id}) => _id}
                loading={loading}
                pagination={{...paginationSettings(page, limit, total)}}
                scroll={{y: scrollByY(LIVE_VIEW_BUSY_SPACE)}}
            />
            <TurnaroundDetailsModal
                turnaround={turn}
                isOpen={openTurnDetails}
                onClose={handleCloseTurnModal}
            />
        </div>
    );
};

export default LiveView;