import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    ArrowUpOutlined,
    CameraFilled,
    CameraOutlined,
    CheckOutlined,
    ClearOutlined,
    CloseOutlined,
    DeleteOutlined,
    DownloadOutlined,
    EditOutlined,
    EyeInvisibleOutlined,
    EyeOutlined,
    HomeOutlined,
    LoadingOutlined,
    LoginOutlined,
    LogoutOutlined,
    ReloadOutlined,
    SaveOutlined,
    SearchOutlined,
    SendOutlined,
    UndoOutlined,
    UserOutlined,
    VideoCameraAddOutlined,
    CheckCircleOutlined,
    StopOutlined,
    CheckCircleFilled,
    LinkOutlined,
    VideoCameraOutlined,
    FileImageOutlined,
    SyncOutlined,
    HourglassOutlined,
    ClockCircleOutlined,
    PlusCircleOutlined,
    CaretDownOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
    InfoOutlined,
    UploadOutlined,
    CloseCircleOutlined,
    DownCircleOutlined, EllipsisOutlined,
} from "@ant-design/icons";


export const antIcon = <LoadingOutlined style={{fontSize: 20}} spin/>;
export const SAVE = <SaveOutlined/>
export const UNDO = <UndoOutlined/>
export const CLEAR = <ClearOutlined/>
export const CLOSE = <CloseOutlined/>
export const ARROW_RIGHT = <ArrowRightOutlined/>
export const ARROW_LEFT = <ArrowLeftOutlined/>
export const ARROW_UP = <ArrowUpOutlined/>
export const FINISH = <SendOutlined/>
export const ADD_CAMERA = <VideoCameraAddOutlined/>
export const TRASH = <DeleteOutlined/>
export const HOME = <HomeOutlined/>
export const CAMERA = <CameraOutlined/>
export const RegCAMERA = <CameraFilled/>
export const LOGOUT = <LogoutOutlined rotate={180}/>
export const RELOAD = <ReloadOutlined/>
export const PENCIL = <EditOutlined/>
export const MARK = <CheckOutlined/>
export const EYE_OPEN = <EyeOutlined/>
export const EYE_CLOSE = <EyeInvisibleOutlined/>
export const DOWNLOAD = <DownloadOutlined/>
export const USER = <UserOutlined/>
export const SEARCH = <SearchOutlined/>
export const LOGIN = <LoginOutlined/>
export const CHECK_CIRCLE = <CheckCircleOutlined/>
export const CHECK_CIRCLE_FIELD = <CheckCircleFilled/>
export const STOP = <StopOutlined/>
export const LINK = <LinkOutlined/>
export const VIDEO_CAMERA = <VideoCameraOutlined/>
export const IMG = <FileImageOutlined/>

export const PLUS_CIRCLE = <PlusCircleOutlined/>
export const SYNC_LOADING = <SyncOutlined spin/>
export const HOUR_GLASS = <HourglassOutlined spin/>
export const CLOCK_CIRCLE = <ClockCircleOutlined spin/>

export const CARET_DOWN_ARROW = <CaretDownOutlined/>
export const ZOOM_IN = <ZoomInOutlined/>
export const ZOOM_OUT = <ZoomOutOutlined/>

export const INFO = <InfoOutlined />
export const UPLOAD = <UploadOutlined />
export const CIRCLE_CLOSE = <CloseCircleOutlined />

export const DOWN_ARROW_CIRCLE = <DownCircleOutlined />
export const  ELLIPSIS_POINTS = <EllipsisOutlined/>



export const BIG_LOADING = <LoadingOutlined style={{fontSize: 150}} spin/>;

export const SIMPLE_LOADING = (size, color) => <LoadingOutlined style={{fontSize: size, color}} spin/>;