import React from 'react';
import PreviewGate from "../PreviewGate";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {ADD_CAMERA, FINISH} from "../../icons/antd";
import {Button} from "antd";
import {FORCE_FORM_GATE_STEP} from "../../../utils/routeUtils/Links";
import {selectFormGate} from "../../../store/selectors/Api/formAddGate";
import {selectFiles} from "../../../store/selectors/Ui/files";
import {sendCalibFrame} from "../../../store/asyncThunkFunctions/Api/CamerasRequests";

const Finish = ({onSendRequest}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const {name, id} = useParams();
    const {gate: {_id, cameras}, editCamera: {camera}} = useSelector(selectFormGate);

    const files = useSelector(selectFiles)

    const styles = {justifyContent: cameras.length > 1 ? 'space-between' : 'center'}

    const handleAddNewCamera = () => {
        navigate(FORCE_FORM_GATE_STEP({_id: name, cctv_id: id, form_step: 'step_two'}))
        sessionStorage.removeItem('camera')
    }

    const handleFinish = () => {
        onSendRequest()
        const edit_cctv_id = camera.cctv_id

        if (Object.keys(files).length) {
            if (cameras.length) {
                cameras.forEach(({cctv_id}) => {
                    if (files[cctv_id]) {
                        dispatch(sendCalibFrame({cctv_id, file: files[cctv_id]}))
                    }
                })
        }

            if (Object.keys(camera).length && files[edit_cctv_id]) {
                dispatch(sendCalibFrame({cctv_id: edit_cctv_id, file: files[edit_cctv_id]}))
            }
        }
    }

    return (
        <div className={'wrapperCameras'} style={{ width: cameras?.length > 1 ? '1280px' : '700px'}}>
            <h2>Gate Name => {_id}</h2>
            <div className={'cameras'} style={{...styles}}>
                <PreviewGate camera={camera} cameras={cameras}/>
            </div>
            <div className={'wrapperCameraButtons'}>
                {!id && <Button
                    onClick={handleAddNewCamera}
                    size={'large'}
                    type={'primary'}
                    icon={ADD_CAMERA}
                    value={'Add new camera'}>Add new camera</Button>
                }
                {(cameras.length > 0 || Object.keys(camera).length > 0)
                    && <Button
                        onClick={handleFinish}
                        size={'large'}
                        type={'primary'}
                        icon={FINISH}
                        value={'Finish'}>Finish</Button>
                }
            </div>
        </div>
    );
};

export default Finish;