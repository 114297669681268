import React from 'react';
import {Button, Card, DatePicker} from "antd";
import useReadyState from "../../hooks/useReadyState";
import {getDataFile} from "../../utils/apiUtils";
import {getMillisecondsUnixTimestamp} from "../../utils/helpers/timeHelper";

const Validations = ({downloadFileWithSplitName}) => {
    const {RangePicker} = DatePicker;

    const {state: rangePickerTime, onChangeReadyState: setRangePickerTime} = useReadyState(null);

    const transRangePickerDates = () => {
        const [start, end] = rangePickerTime
        return {start: getMillisecondsUnixTimestamp(start), end: getMillisecondsUnixTimestamp(end)}
    }

    const handleDownloadAllValidInfo = async (body = {start: '', end: ''}) => {
        const valid_info = await getDataFile({path: 'events/validation_info', method: 'POST', body})
        downloadFileWithSplitName(valid_info);
    }

    const handleDownloadAllEventsInfo = async () => {
        const events_info = await getDataFile({path: 'events/events_info', method: 'POST', body: transRangePickerDates()})
        downloadFileWithSplitName(events_info)
    }

    const handleDownloadValidLast24Hour = async () => {
        const last_24_hour = await getDataFile({path: 'events/validation_data', method: 'GET'})
        downloadFileWithSplitName(last_24_hour)
    }

    return (
        <Card title={'Validations'} className={'downloadSection__systemCardBlock validCardDataBlock'}>
            <section className={'validCardDataBlock__rangeDateSection'}>
                <RangePicker value={rangePickerTime} onChange={setRangePickerTime} showTime size={'middle'}/>
            </section>
            <footer>
                <Button onClick={() => handleDownloadAllValidInfo(transRangePickerDates())}
                        disabled={!rangePickerTime}>Validations by range</Button>
                <Button onClick={handleDownloadAllEventsInfo}
                        disabled={!rangePickerTime}>Events by range</Button>
                <Button onClick={handleDownloadValidLast24Hour}>Last 24 hour data</Button>
                <Button onClick={() => handleDownloadAllValidInfo()}>All validations
                    metadata</Button>
            </footer>
        </Card>
    );
};

export default Validations;