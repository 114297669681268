export const drawText = ({context, fillStyle, font, fillText: {text, x, y}}) => {
    context.fillStyle = fillStyle;
    context.font = font;
    context.fillText(text, x, y);
}

////

export const drawLines = ({context, lineWidth, strokeStyle, startX, startY, endX, endY, moveTo, closePath, opacity}) => {
    context.globalAlpha = opacity || 1
    context.beginPath();
    context.lineWidth = lineWidth;
    context.strokeStyle = strokeStyle;
    context.moveTo(startX, startY);


    context.lineTo(endX, endY);
    context.stroke();
    if (closePath) {
        context.closePath()
    }
}