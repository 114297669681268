import React from 'react';
import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import './index.sass';
import Intell_Act from './Intell_Act';
import {setupStore} from "./store/setupStore";
import {Provider} from "react-redux";
import {BrowserRouter as Router} from "react-router-dom";
import {ToastContainer} from "react-toastify";
import AxiosInterceptor from "./hoc/AxiosInterceptor";
import {ConfigProvider} from "antd";

const store = setupStore();

const local = {
    components: {
        Table: {
            headerBg: "#1f618d",
            headerBorderRadius: "5px",
            borderColor: "#c0c0c0",
            headerColor: "#ffffff",
            cellPaddingBlock: 7,
            cellPaddingInline: 7,
            cellFontSize: 13,
            cellFontSizeMD: 12,
            cellFontSizeSM: 11,
        }
    }
}

ReactDOM.render(
    <Provider store={store}>
        <Router>
            <AxiosInterceptor>
                <ConfigProvider theme={local}>
                    <Intell_Act/>
                </ConfigProvider>
            </AxiosInterceptor>
            <ToastContainer position={'bottom-right'} autoClose={3000}/>
        </Router>
    </Provider>,
    document.getElementById('root')
);