import React from 'react';
import {useDispatch} from "react-redux";
import {getTurnaround} from "../../store/asyncThunkFunctions/Api/TurnaroundRequests";

const FidsModalButton = ({id, handleOpenModal}) => {
    const dispatch = useDispatch()

    const handleClickGetTurn = async () => {
        await dispatch(getTurnaround(id))
        await handleOpenModal()
    }

    return (
        <div className={'wrapperFidsIdButton turnarounds-id-btn-container'}>
            <button
                className={'wrapperFidsIdButton__fidsIdButton turnarounds-id-btn-container__btn'}
                onClick={handleClickGetTurn}>
                {id}
            </button>
        </div>

    )
};

export default FidsModalButton;