import React from 'react';
import {Drawer} from "antd";

const ModalSidebar = ({children, close, state, placement, width, isFixed, title, className}) => {
    return (
        <Drawer
            title={title}
            placement={placement}
            width={width}
            onClose={close}
            open={state}
            getContainer={isFixed}
            className={className}
            style={{height: '100%'}}
        >
            {children}
        </Drawer>
    )
}

export default ModalSidebar;