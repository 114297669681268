import React from 'react';
import {Tooltip} from "antd";
import {Link} from "react-router-dom"
import {useSelector} from "react-redux";
import {returnCorrectTimeZoneTime} from "../../utils/helpers/timeHelper";
import {RegCAMERA} from "../icons/antd";
import {selectTimeZone} from "../../store/selectors/Ui/TimeZone";

const Cameras = ({cameras, parking_gate, id}) => {
    const timeZone = useSelector(selectTimeZone);
    const replacedChangeTimeZone = (time) => returnCorrectTimeZoneTime(time, timeZone)

    return (
        <div className={'turnarounds-cameras'}>
            {cameras?.map(({cctv_id, total_inferences, first_inference: first, last_inference: last}) => {
                const path = `/turnaround/${id}/${parking_gate}/${cctv_id}`
                const tooltip = `First frame: ${first && replacedChangeTimeZone(first)} : Last frame: ${last && replacedChangeTimeZone(last)} :`

                return (
                    <div className={'turnarounds-cameras__camera-wrapper'} key={cctv_id}>
                        <Tooltip title={tooltip}>
                            <Link to={path} disabled={total_inferences <= 0} className={"camera"}>
                                <span className={"camera__camera-icon"}>{RegCAMERA}</span>
                                <span className={'camera__camera-info'}>{cctv_id}</span>
                            </Link>
                        </Tooltip>
                    </div>
                )
            })}
        </div>
    );
};

export default Cameras;