import {All, WithoutViewer} from "../../routeUtils/Roles";
import {sortByDate} from "../../helpers/dataHelpers/arrayObjectSorts";
import ActionsButton from "../../../components/Turnaround/ActionsButton";
import {Button} from "antd";
import React from "react";

export const eventColumns =
    (
        handleOpenEditEventForm,
        getColumnSearchProps, camerasOption,
        jumpToEvent, transformTime
    ) => ([
        {
            title: "№",
            dataIndex: '№',
            width: '50px',
            roles: All,
            className: 'indexedEventColumn',
            render: (array, value, index) => <span>{index + 1}</span>
        },
        {
            key: 'timestamp',
            title: 'Timestamp',
            dataIndex: 'Timestamp',
            width: '120px',
            className: 'timestamp',
            roles: All,
            sorter: (a, b) => sortByDate(a['Timestamp'], b['Timestamp']),
            render: (_, event) => {
                return (
                    <div className={'timestampButton'}>
                        <Button onClick={() => jumpToEvent(event)} size={"small"}>
                            {transformTime(event['Frame_Id'])}
                        </Button>
                    </div>
                )
            }
        },
        {
            key: 'offset',
            title: 'Offset',
            dataIndex: 'Offset',
            width: '80px',
            roles: All,
            className: 'offset',
            ...getColumnSearchProps('Offset'),
            render: (offset) => <span>{offset.replace(/(\d+:\d+:\d+)\.\d+/g, '$1')}</span>
        },
        {
            key: 'camera',
            title: 'Camera',
            dataIndex: 'Camera',
            width: '90px', // just for the test
            className: 'camera',
            roles: All,
            filters: camerasOption,
            filterSearch: true,
            onFilter: (value, record) => record?.Camera?.indexOf(value) === 0,
        },
        {
            key: 'confidence',
            title: 'Confidence',
            dataIndex: 'confidence',
            width: '80px',
            className: 'confidence',
            roles: WithoutViewer,
            render: (_, b) => parseFloat(b['Confidence'] * 100).toFixed(2)
        },
        {
            key: 'process',
            title: 'Process',
            dataIndex: 'Process',
            width: '90px',
            className: 'process',
            roles: All,
            ...getColumnSearchProps('Process')
        },
        {
            key: 'state',
            title: 'Event',
            dataIndex: 'State',
            width: '120px',
            className: 'event',
            roles: All,
            ...getColumnSearchProps('State')
        },
        {
            key: 'position',
            title: 'Position',
            dataIndex: 'Position',
            width: '120px',
            className: 'position',
            roles: All,
            ...getColumnSearchProps('Position')
        },
        {
            key: 'action',
            title: 'Action',
            dataIndex: 'Action',
            width: '120px',
            roles: All,
            render: (_, event) => {
                const handleOpenEditForm = () => handleOpenEditEventForm(event)
                return <ActionsButton event={event} handleOpenEditForm={handleOpenEditForm}/>
            }
        }
    ])