export const drawRectangle =   (ctx, x, y, w, h, lineWidth, color, text="", fill=false, broken_line=false) => {
    ctx.beginPath();
    ctx.rect(x, y, w, h);
    ctx.fillStyle = color;
    if (fill) ctx.fill();
    if (broken_line) ctx.setLineDash([20, 10]);
    else ctx.setLineDash([0, 0]);
    ctx.strokeStyle = color;
    ctx.lineWidth = lineWidth;
    ctx.font = "30px serif";

    ctx.stroke();
    ctx.fillText(text, x+2, y+h-2);
    ctx.closePath();
}