import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import Header from "../../components/Header/Header";
import {Route, Routes, useLocation} from "react-router-dom";
import ProtectedRoute from "../security/ProtectedRoute";
import Not_Found from "../../components/Errors/Not_Found";
import {errorMessages} from "../../utils/constants/objects/errorMessages";
import {getUiSettings} from "../../store/asyncThunkFunctions/Api/SystemRequests";
import {handleLogout} from "../../store/slices/Api/Auth";
import WebsocketProcess from "../../components/WebsocketProcess";
import {selectAuthUser} from "../../store/selectors/Api/auth";
import {connectionToAlerts, getAlertMessage} from "../../store/asyncThunkFunctions/Weboscket/AlertRequests";
import {toast} from 'react-toastify';
import {primaryList} from "../../utils/routeUtils/routes/primary";
import RoleAccess from "../security/RoleAccess";
import {fallbackPath, homeLink} from "../../utils/routeUtils/Paths";
import FallBackRoute from "../security/FallBackRoute";
import {isAuthenticated} from "../../utils/helpers/cacheHelpers";

const PrimaryRoutes = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const {isAuth} = useSelector(selectAuthUser);

    useEffect(() => {
        if (!isAuthenticated()) {
            toast.error('Unauthorized');
            dispatch(handleLogout());
        }
    }, [location.pathname]);

    const firstRequests = async () => {
        await dispatch(getUiSettings());
        await dispatch(connectionToAlerts());
        await dispatch(getAlertMessage());
    }

    useEffect(() => {
        firstRequests()
    }, [])

    return (
        <>
            <Header/>
            <WebsocketProcess/>
            <Routes>
                <Route element={<ProtectedRoute isAuth={isAuth}/>}>
                    {primaryList?.map(({roles, path, element}) => {
                        return (
                            <Route element={<RoleAccess roles={roles}/>} key={path}>
                                <Route path={path} element={element}/>
                            </Route>
                        )
                    })}
                </Route>
                <Route path={'/404'} element={<Not_Found errorMessage={errorMessages.broken_page}/>}/>
                <Route path={fallbackPath} element={<FallBackRoute homeLink={homeLink}/>}/>
            </Routes>
        </>
    );
}


export default PrimaryRoutes