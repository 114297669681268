import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../services/airAxios";


export const createUser = createAsyncThunk('User/createUser',
    async (newUser) => {
    try {
        const response = await air_axios_settings.post('/user', newUser)
        return response['data']
    } catch (error) {
        throw new Error(error)
    }
})

export const getUser = createAsyncThunk('User/getUser',
    async (_id) => {
    try {
        const response = await air_axios_settings.get(`/user/${_id}`);
        return response['data']
    } catch (error) {
        throw new Error(error)
    }
})

export const getUsers = createAsyncThunk('User/getUsers',
    async (filter) => {
    try {
        const response = await air_axios_settings.get('/user', {params: {...filter, sort_by: '_id', asc: '0'}})
        return response['data'].map(item => ({...item, is_active: item['is_active'] ? 'active' : 'inactive'}))
    } catch (error) {
        throw new Error(error)
    }
})

export const editUser = createAsyncThunk('User/editUser',
    async (settings = {_id: '', body: {}}) => {
    try {
        const response = await air_axios_settings.put(`/user/${settings['_id']}`, settings['body'])
        return response['data']
    } catch (error) {
        throw new Error(error)
    }
})

export const removeUser = createAsyncThunk('User/removeUser',
    async (_id) => {
    try {
        const response = await air_axios_settings.delete(`/user/${_id}`)
        return response['data']
    } catch (error) {
        throw new Error(error)
    }
})