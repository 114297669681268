import {useEffect, useMemo} from 'react';
import {Button} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {getAllGates} from "../../store/asyncThunkFunctions/Api/GateRequest";
import useTable from "../../hooks/useTable";
import {useNavigate} from "react-router-dom";
import classNames from "classnames";
import {resetGateState, handleOnChangeIsUpdateDataStatus} from "../../store/slices/Api/sliceGateRequest";
import {paginationSettings} from "../../utils/tableUtils/tableSettings";
import usePagination from "../../hooks/usePagination";
import {configurationColumns} from "../../utils/tableUtils/columns/configuration";
import {FORM_GATE_LINKS} from "../../utils/routeUtils/Links";
import {yupCheckName} from "../../utils/helpers/yupGateFormHelper";
import {disableButton, initialStateFormGate, switchModalShowCamera} from "../../store/slices/Api/newGate";
import EditableCell from "../../components/ANTD/EditableCell";
import {selectCalibrationResult, selectCctvFrames} from "../../store/selectors/Api/frames";
import {getCalibrationResult, getLastFrame} from "../../store/asyncThunkFunctions/Api/ImageRequests";
import {selectAbortController} from "../../store/selectors/AbortController";
import {selectFormGate} from "../../store/selectors/Api/formAddGate";
import {selectAllGatesSlice, selectGateNames} from "../../store/selectors/Api/gates";
import {getStorageValue, setCacheTableSettingsGroup} from "../../utils/helpers/cacheHelpers";
import {scrollByY} from "../../utils/helpers/tableScrollHelpers";
import {CONFIGURATION_BUSY_SPACE} from "../../utils/Sizes";
import ModalWindow from "../../components/Customs/ModalWindow";
import {onRemoveCamera, onReturnInitCalibrationResult} from "../../store/slices/Api/Frames";
import {handleAbortRequest} from "../../store/slices/Api/AbortController";
import Camera from "../../components/Configuration/Camera";
import EditableTable from "../../components/Customs/EditableTable";

const GatesCameras = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const {page, limit, setSettings} = usePagination();

    const {gates, loading, total, isUpdateGateData} = useSelector(selectAllGatesSlice);
    const {gatesName} = useSelector(selectGateNames);
    const {switchModal, editCamera: {camera}} = useSelector(selectFormGate);

    const frames = useSelector(selectCctvFrames);
    const {calibrationResultError, calibrationResult, isCalibrationResLoading} = useSelector(selectCalibrationResult);

    const cctv_id = camera?.cctv_id

    const frameList = [frames[cctv_id]?.imgLink, calibrationResult];
    const isLoadingFrame = !frames[cctv_id]?.isImgLoading && !isCalibrationResLoading;
    const cameraFrameError = frames[cctv_id]?.imgError && calibrationResultError

    const {mergedColumns, form, defaultEditPropsConfig, cancel} = useTable();

    const {abortController} = useSelector(selectAbortController);

    const wrapperModalCameraClasses = classNames('gates-cameras-container__camera-modal-info-wrapper', {
        'open': switchModal, 'close': !switchModal
    });

    const handleCloseModalCamera = () => {
        dispatch(switchModalShowCamera(false))
        dispatch(initialStateFormGate())
        dispatch(onReturnInitCalibrationResult())

        dispatch(onRemoveCamera(camera.cctv_id))
        if (abortController) dispatch(handleAbortRequest())
    }

    const names = useMemo(() => (_id) =>  gatesName.filter(name => name !== _id), [gatesName]);

    const yupSync = (_id)  => ({
        async validator({ field }, value) {
            await yupCheckName(names(_id)).validateSyncAt(field, { [field]: value });
        },
    });

    const handleOnCheckIsGetEditAccess = (event, _id) => {
        const value = event.target.value
        dispatch(disableButton(names(_id).includes(value) || !value.length))
    }
    const handleAddNewGate = () => {
        const {step_one} = FORM_GATE_LINKS
        navigation(step_one);
    }
    const refreshConfiguration = ({page = 1, limit = 5, tools = {}}) => {
        dispatch(handleOnChangeIsUpdateDataStatus(true))
        setSettings(page, limit, tools);
    }
    const handleChangePage = ({ current: page, pageSize: limit }) => {

        const configurationSettings = {page, limit}
        setCacheTableSettingsGroup('configuration', configurationSettings)
        refreshConfiguration(configurationSettings);
    }

    const columns = mergedColumns(defaultEditPropsConfig, configurationColumns, '_id', {
        yupSync,
        onChange: handleOnCheckIsGetEditAccess
    });


    const ConfigurationTableTitle = () => (
        <div className={'gates-cameras-title'}>
            <Button onClick={handleAddNewGate}>Add new gate</Button>
        </div>
    )

    const configurationTableProps = {
        form: {
            form,
            component: false
        },
        table: {
            columns,
            dataSource: gates,
            title: ConfigurationTableTitle,
            components: {body: {cell: EditableCell}},
            className: "gates-cameras-table",
            loading: loading,
            rowKey: ({_id}) => _id,
            pagination: {...paginationSettings(page, limit, total), onChange: cancel},
            scroll: {y: scrollByY(CONFIGURATION_BUSY_SPACE)},
            onChange: handleChangePage,
        }
    }

    useEffect(() => {
        const configurationCacheSettings = getStorageValue('tableGroupsSetting', {}, 'local');

        if (configurationCacheSettings.configuration) refreshConfiguration(configurationCacheSettings.configuration);

        else dispatch(handleOnChangeIsUpdateDataStatus(true));

        return () => {
            console.log('lost configuration page')
            dispatch(resetGateState())
            // dispatch(initialStateFormGate())
        }
    }, []);

    useEffect(() => {
        if (isUpdateGateData) {
            dispatch(getAllGates({page, limit}));
            dispatch(handleOnChangeIsUpdateDataStatus(false))
        }
    }, [isUpdateGateData]);


    useEffect(() => {
        if (switchModal) {
            const {url, cctv_id} = camera
            dispatch(getLastFrame({url, cctv_id, isReSendReadStreamHere: true}))
            dispatch(getCalibrationResult(cctv_id))
        }
    }, [switchModal]);

   useEffect(() => {
       return () => {
           if (abortController) {
               abortController.abort('Loading too much')
           }
       }
   }, [abortController]);

    return (
        <div className={'wrapper_config gates-cameras-container'}>
            <EditableTable {...configurationTableProps} />
            <ModalWindow modalClasses={wrapperModalCameraClasses} handleClose={handleCloseModalCamera}>
                <Camera
                    frames={frameList}
                    camera={camera}
                    onClose={handleCloseModalCamera}
                    loading={!isLoadingFrame}
                    isReadyToRender={switchModal}
                    error={cameraFrameError}
                />
            </ModalWindow>
        </div>
    );
};

export default GatesCameras;