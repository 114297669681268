import React from "react";
import ZoomImg from "./ZoomImg";
import CanvasList from "../Canvas/CanvasList";
import {defaultZoomProps} from "../../utils/constants/objects/zoomPanPinch";

const ZoomableCanvasFrameComponent = ({trackingZoomScale, url, isReadyZoomToDef, ...rest}) => {
    return (
        <ZoomImg zoom_settings={defaultZoomProps} trackingZoomScale={trackingZoomScale} isReadyZoomToDef={isReadyZoomToDef}>
            <img src={url} alt="IMG"/>
            <CanvasList {...rest}/>
        </ZoomImg>
    );
};

export default ZoomableCanvasFrameComponent;