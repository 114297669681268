import React from 'react';
import {MARK} from "../icons/antd";
import {Tag} from "antd";

const CameraEvents = ({cameras, is_validated}) => {
    return (
        <div className={"turnarounds-events"}>
            <div className={"turnarounds-events__events"}>
                {
                    cameras.map(({cctv_id, total_events, total_validated}) => {
                        return <p key={cctv_id}>{total_events}/{total_validated}</p>
                    })
                }
            </div>
            {is_validated &&
                <div className={"turnarounds-events__validated-status"}>
                    <Tag color={'#52c41a'}>{MARK}</Tag>
                </div>
            }
        </div>
    )
};

export default CameraEvents;


