import React from 'react';

const EntitiesColorInstructions = ({isReadyToRender, cameras}) => {
    if (isReadyToRender) {
        return (
            <>
                <h4>Other Projection Line Colors</h4>
                <div
                    style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
                    {cameras?.map(({cctv_id, cameraColor}) => {
                        return (
                            <p key={cctv_id}>
                                {cctv_id}:
                                <span
                                    style={{
                                        width: "15px",
                                        height: "15px",
                                        backgroundColor: cameraColor,
                                        marginLeft: "5px",
                                        display: "inline-block"
                                    }}/>
                            </p>
                        )
                    })}
                </div>
            </>
        )
    }
    return null
};

export default EntitiesColorInstructions;