import React from 'react';
import {Navigate, Outlet, useLocation} from "react-router-dom";
import {LOGIN} from "../../utils/routeUtils/Paths";

const ProtectedRoute = ({isAuth}) => {
    const location = useLocation();
    if (!isAuth) return <Navigate state={{from: location}} to={LOGIN}/>
    return <Outlet/>
}

export default ProtectedRoute