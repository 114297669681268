import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getEventModes, getProcessesName} from "../../store/asyncThunkFunctions/Api/EventsRequest";
import {scrollByY} from "../../utils/helpers/tableScrollHelpers";
import {BUSY_SPACE_IN_PAGE_JUST_WITH_TABLE} from "../../utils/Sizes";
import {createFilterOptionsSelect} from "../../utils/helpers/dataHelpers/arrayObjectModifiers";
import {addUuidFiled} from "../../utils/helpers/dataHelpers/arrayObjectModifiers";
import useTable from "../../hooks/useTable";
import {returnSomeInitialState} from "../../store/slices/Api/Events";
import {selectPotentialEvents, selectProcessNames} from "../../store/selectors/Api/events";
import {getListOfGateNames} from "../../store/asyncThunkFunctions/Api/GateRequest";
import {selectCctvIds} from "../../store/selectors/Api/gates";
import EditableSelectCell from "../../components/ANTD/EditableSelectCell";
import {resetGateState} from "../../store/slices/Api/sliceGateRequest";
import ModesMonitoringHeader from "../../components/System/Common/ModesMonitoringHeader";
import useConsistencyEventRowChecks from "../../hooks/System/useConsistencyEventRowChecks";
import useSysEventHeaderStates from "../../hooks/System/useSysEventHeaderStates";
import EditableTable from "../../components/Customs/EditableTable";
import useEventModeHandlers from "../../hooks/System/useEventModeHandlers";
import {eventModeColumns} from "../../utils/tableUtils/columns/eventModes";

const EventsTools = () => {
    const dispatch = useDispatch();

    const {cctvIds} = useSelector(selectCctvIds);
    const {potentialEvents: {data: events}, isPotentialEvents} = useSelector(selectPotentialEvents);
    const {processes_name} = useSelector(selectProcessNames);

    const {
        mergedColumns, getColumnSearchProps, cancel,
        editingKey, form,
        defaultEditPropsConfig, save
    } = useTable();

    const {gate, camera, onSelectGate, onSelectCctvId} = useSysEventHeaderStates({
        cctvIds, onCanselEditField: () => {if (editingKey !== "") cancel()}
    });

    const {isVisibility, isRequired} = useConsistencyEventRowChecks(events);
    const {onUpdatedEventModes, onUpdateVisibility} = useEventModeHandlers({
        gate, camera, events, onUpdate: save
    })

    const processes = useMemo(() => createFilterOptionsSelect(processes_name), [processes_name])
    const eventsList = useMemo(() => addUuidFiled(events), [events])

    const setColumns = () => {
        const props = {
            ...defaultEditPropsConfig,
            save: onUpdateVisibility,
            getColumnSearchProps,
            processes, isVisibility, isRequired,
            sysEventAsyncHandler: onUpdatedEventModes,
        }

        return mergedColumns(props, eventModeColumns, "uuid")
            .map(({key, ...rest}) => ({...rest, key, isFiltered: key === "Expected" && camera !== "default"}))
            .filter(row => !row.isFiltered)
    }

    const eventModeTableProps = {
        form: {form, component: false},
        table: {
            dataSource: eventsList,
            loading: isPotentialEvents,
            columns: setColumns(),
            components: {body: {cell: EditableSelectCell}},
            scroll: {y: scrollByY(BUSY_SPACE_IN_PAGE_JUST_WITH_TABLE)},
            rowKey: ({uuid}) => uuid,
            pagination: false,
            className: "event_mode_table",
        }
    }

    useEffect(() => {
        dispatch(getEventModes({gate, camera}));
    },[gate, camera, dispatch])

    useEffect(() => {
        dispatch(getListOfGateNames());
        dispatch(getProcessesName());
        return () => {
            dispatch(returnSomeInitialState('potentialEventsObj'))
            dispatch(resetGateState())
        }
    }, []);

    //fix unmount warning that getting after close edit visibility switch component

    return (
        <div className={"event_mode_container"}>
            <ModesMonitoringHeader
                gate={gate}
                camera={camera}
                onSelectGate={onSelectGate}
                onSelectCctvId={onSelectCctvId}
                mainClass={"event_mode_container__header"}
            />
            <EditableTable {...eventModeTableProps} />
        </div>
    );
};

export default EventsTools;