export const generateDetectionPlaceholder = (state) => {

    if (!['DETECTIONS', 'TRACKERS', 'PROJECTION'].includes(state)) {
        return "If you want activate this select, just click on detections, trackers or projection"
    }

    if (state['DETECTIONS'] && state['TRACKERS']) return 'Detection and Tracker options'

    if (state['DETECTIONS']) return 'Detection options'

    if (state['TRACKERS']) return 'Tracker options'
}