import React from 'react';
import {Button, Card, Form, Input, Select} from "antd";
import {useSelector} from "react-redux";
import {ADMIN} from "../../utils/routeUtils/Roles";
import {useNavigate} from "react-router-dom";
import AdminSkeletonForm from "../Loadings/AdminSkeletonForm";
import {selectUser} from "../../store/selectors/Api/user";
import {userRoles, userStatuses} from "../../utils/constants/arrays/administration";
import {ADMINISTRATION_LINKS} from "../../utils/routeUtils/Links";

const UserForm = ({bodyClass, formName, submitText, onFinish, defaultState, isNewUser}) => {
    const navigate = useNavigate()
    const {user, userLoading} = useSelector(selectUser);
    const {users} = ADMINISTRATION_LINKS;
    const isAdmin = user['role'] === ADMIN

    const handleExit = () => navigate(users, {replace: true})



    if (userLoading) return <AdminSkeletonForm/>

    return (
        <Form name={formName} onFinish={onFinish} initialValues={{...defaultState, is_active: defaultState['is_active'] ? 'active' : 'inactive'}}>
            {!isNewUser &&
                <header className={"user_header_form"}>
                    <h3>User: <span>{defaultState._id}</span></h3>
                </header>
            }
            <section className={bodyClass}>
                <Card title={'information'} className={`${bodyClass}__userFormDetails`}>
                    {isNewUser &&
                        <Form.Item name={'_id'} label={'Name:'} rules={[{required: true, message: 'Please input your login'}]}>
                            <Input placeholder={'qwerty1234456'}/>
                        </Form.Item>
                    }

                    <Form.Item name={'password'} label={'Password: '}>
                        <Input.Password placeholder={'Qwerty1235'}/>
                    </Form.Item>

                    <Form.Item name={'is_active'} label={'Status'} rules={[{required: true, message: 'Please chose the status'}]}>
                        <Select placeholder="select status" disabled={user && isAdmin} options={userStatuses}/>
                    </Form.Item>
                </Card>
                <Card title={'permissions'} className={`${bodyClass}__userFormPermissions`}>
                    <Form.Item name={'role'} label={'Role'} rules={[{required: true, message: 'Please chose role'}]}>
                        <Select placeholder="select role" disabled={user && isAdmin} options={userRoles}/>
                    </Form.Item>
                </Card>
            </section>

            <footer className={'submitUserFooter'}>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        {submitText}
                    </Button>
                </Form.Item>
                <Button onClick={handleExit}>Exit</Button>
            </footer>
        </Form>
    );
};

export default UserForm;