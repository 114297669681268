import {useCallback, useRef} from 'react';
import {GoogleMap} from "@react-google-maps/api";
import Markers from "./Markers";
import CurrentIconMarker from '../../assets/svg/map_icon_mark.svg'
import CurrentMarker from "./CurrentMarker";
import defMapOptions from "../../utils/constants/objects/googleMaps";
import {encodedSvgGoogleMapsMarker} from "../../utils/svgUtils/googleMapsMarker";

const LandmarksMap = ({landmarks, prevElem, setLandmarks, mode, setMode, center, color}) => {
    const mapRef = useRef(null);
    const onLoad = useCallback((map) => mapRef.current = map, []);
    const onUnmount = useCallback(()  => mapRef.current = null, []);

    const iconProps  = (width, height, icon) => ({
        url: icon, scaledSize: new window.google.maps.Size(width, height)
    })

    const onMarkedIconMarker = useCallback((index, colorMarker) => {
        const isMarked = prevElem.index === index && prevElem.prev?.lat && prevElem.prev?.lng
        return iconProps(40, 40,  encodedSvgGoogleMapsMarker(isMarked ? "#FF0000" : colorMarker));
    }, [prevElem]);


    const handleAddAndUpdateMarker = (marker) => {
        if (mode && mode !== "editLeft") {
            setLandmarks(prevList => {
                const copyPrevList = [...prevList];
                const currentIndex = prevElem.index ?? copyPrevList.length - 1;

                if (mode === "start") {
                    copyPrevList.push({...marker, color});
                    setMode("preparing");
                }

                if (mode !== "start") {
                    if (mode === "preparing" && copyPrevList[currentIndex]?.x && copyPrevList[currentIndex]?.y) {
                        setMode("finish");
                    }
                    copyPrevList[currentIndex] = {...copyPrevList[currentIndex], ...marker}
                }
                return copyPrevList
            });
        }
    };

    return (
        <div className={"google_map_wrap"}>
            <GoogleMap
                zoom={10}
                center={center}
                mapContainerClassName={'google_maps'}
                options={defMapOptions}
                onDblClick={({latLng: {lat, lng}}) => handleAddAndUpdateMarker({lat: lat(), lng: lng()})}
                onLoad={onLoad}
                onUnmount={onUnmount}
            >
                <CurrentMarker position={center} icon={iconProps(45, 45, CurrentIconMarker)}/>

                <Markers
                    markers={landmarks}
                    onMarkedIconMarker={onMarkedIconMarker}
                />
            </GoogleMap>
        </div>
    );
};

export default LandmarksMap;