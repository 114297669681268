import {useCallback, useEffect, useMemo, useState} from "react";
import {Navigate, useNavigate, useParams} from "react-router-dom"
import {Button, Select} from "antd";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.min.css';
import {useDispatch, useSelector} from "react-redux";
import {
    getEvents, getFidsFrames, getInference, getTurnaround, getCameraFramesTimes
} from "../store/asyncThunkFunctions/Api/TurnaroundRequests";
import TurnaroundDetails from "../components/TurnaroundInformation/TurnaroundDetails";
import {initialTurnInfoState} from "../store/slices/Api/turnaroundInfo";
import Canvas from "../components/Canvas/Canvas";
import {addSeconds, getTimestampInCorrectMilliSeconds, returnCorrectTimeZoneTime} from "../utils/helpers/timeHelper";
import BigLoading from "../components/Loadings/BigLoading";
import TimeLine from "../components/TimeLine/TimeLine";
import {ARROW_LEFT, ARROW_RIGHT} from "../components/icons/antd";
import SlideMark from "../components/TimeLine/SlideMark";
import classNames from "classnames";
import _ from 'lodash'
import {generateDetectionPlaceholder} from "../utils/helpers/detectionUtils";
import {getCameraRequest} from "../store/asyncThunkFunctions/Api/CamerasRequests";
import useToggle from "../hooks/useToogle";
import ModalSidebar from "../components/Customs/ModalSidebar";
import {getIsValidFields,} from "../store/asyncThunkFunctions/Api/ValidationRequests";
import LinkImg from "../components/Turnaround/LinkImg";
import {returnInitialEventState, returnSomeInitialState,} from "../store/slices/Api/Events";
import ValidationForms from "../components/Turnaround/EventForms/ValidationForms";
import JumpFrameHelpers from "../components/Turnaround/JumpFrameHelpers";

import {createOptionsSelect} from "../utils/helpers/dataHelpers/arrayObjectModifiers";
import {getUniqueValues} from "../utils/helpers/dataHelpers/arrayObjectFilters";
import {saveAs} from 'file-saver';
import CollapseTurnTables from "../components/Turnaround/CollapseTurnTables";

import {getPairedEvent} from "../store/slices/Api/Validation";
import {setEventScrollTableSettings} from "../store/slices/Api/Events";
import {getVideoFrame} from "../store/asyncThunkFunctions/Api/ImageRequests";
import {selectCctvFrames} from "../store/selectors/Api/frames";
import {clearImgStates, onRemoveCamera} from "../store/slices/Api/Frames";
import {setErrorImage} from "../utils/helpers/setErrorImageHelper";
import {
    connectionToArchiveTurnSession, sendMessageInArchiveTurnSession
} from "../store/asyncThunkFunctions/Weboscket/ArchiveTurnRequests";
import {getPrepareToast} from "../store/slices/Ui/Notification";
import {onChangeIsOpenSession} from "../store/slices/Websocket/General";
import {onChangeIsDisabled} from "../store/slices/Websocket/ArchiveTurn";
import {toast} from "react-toastify";
import {selectProfile} from "../store/selectors/Api/auth";
import {selectSettingsUi} from "../store/selectors/Api/system";
import {selectTimeZone} from "../store/selectors/Ui/TimeZone";
import {selectFrameState, selectTurnaroundSlice} from "../store/selectors/Api/turnaround";
import {selectAddEvent, selectEventScrollTable} from "../store/selectors/Api/events";
import {selectPairedEvent, selectValidationSlice} from "../store/selectors/Api/validation";
import {selectConnectionToArchive, selectIsDisableArchive} from "../store/selectors/Websocket/archive";
import {ADMIN, VIEWER} from "../utils/routeUtils/Roles";
import {selectIsOpenWsSession} from "../store/selectors/Websocket/general";
import DetectionCheckboxes from "../components/Detections/DetectionCheckboxes";
import {selectEditCamera} from "../store/selectors/Api/formAddGate";
import SwitchArchiveBtnComponent from "../components/Turnaround/SwitchArchiveBtnComponent";
import EntitiesColorInstructions from "../components/Turnaround/EntitiesColorInstructions";

const archiveTooltipMessage = { // save here for now
    "redis": "disabled while turn is 'active'",
    "removed": "disabled because it was deleted from 'archive'"
}

const Turnaround = () => {
    const {TIMELINE_STEPS, AIRPORT_IATA} = useSelector(selectSettingsUi);
    const {turnaround, timestamps, inference, isTurnInfo} = useSelector(selectTurnaroundSlice);
    const {frames} = useSelector(selectFrameState);
    const timeZone = useSelector(selectTimeZone);
    const {isAddValidation} = useSelector(selectValidationSlice);
    const {camera} = useSelector(selectEditCamera);

    const {isAddEvent} = useSelector(selectAddEvent);

    const {isFromPotentialEventTable} = useSelector(selectPairedEvent);
    const scrollEventTableSettings = useSelector(selectEventScrollTable);
    const imagesState = useSelector(selectCctvFrames)

    const isDisableArchiveButton = useSelector(selectIsDisableArchive);
    const {isConnected} = useSelector(selectConnectionToArchive);
    const profile = useSelector(selectProfile);

    const isOpenSession = useSelector(selectIsOpenWsSession);

    const {gate_id, cctv_id, turnaround_id} = useParams();
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const leftSidebar = useToggle();
    const rightSidebar = useToggle();
    const eventNewForm = useToggle();
    const eventEditForm = useToggle();

    const [turnPageLoading, setTurnPageLoading] = useState(false);
    const [turnErrorPage, setTurnErrorPage] = useState(null);

    const [options, setOptions] = useState(['all']);
    const [checkedBoxes, setCheckBoxes] = useState({});
    const [event, setEvent] = useState({});
    const [bbox, setBbox] = useState({});

    const [currentFrame, setCurrentFrame] = useState(0);

    const [isReadyToGetFrame, setIsReadyToGetFrame] = useState(false);

    const RIGHT_SIDEBAR_CLASSES = (array) => classNames('turnaroundSection__wrapperRightHideBlock',
        {'bigSidebar': array.length > 2}
    );

    const returnInitialStates = () => {
        dispatch(initialTurnInfoState());
        dispatch(returnInitialEventState());
        dispatch(clearImgStates())

    }
    const transformTime = (time) => returnCorrectTimeZoneTime(time, timeZone)
    const clearBboxEventTrack = () => {
        if (Object.keys(bbox).length) setBbox({});
        if (Object.keys(scrollEventTableSettings).length) {
            dispatch(returnSomeInitialState('scroll_event_table_obj'))
        }
    };

    const onChangeCurrentFrame = useCallback((state) => {
        setCurrentFrame(state)
    }, [])

    const jumpToTimeByIndex = (time) => {
        const index = timestamps.findIndex(timestamp => {
            return getTimestampInCorrectMilliSeconds(timestamp) === getTimestampInCorrectMilliSeconds(time)
        });

        if (index > -1) {
            onChangeCurrentFrame(index);
            setIsReadyToGetFrame(true)
        }
    }

    const callRequests = async () => {
        setTurnPageLoading(true)
        const promises = await Promise.all([
            dispatch(getCameraFramesTimes({turnaround_id, cctv_id})),
            dispatch(getEvents({turnaround_id, cctv_id})),
            dispatch(getIsValidFields(turnaround_id)),
            dispatch(getCameraRequest({name: gate_id, id: cctv_id})),
            dispatch(getFidsFrames(turnaround_id)),
            dispatch(getTurnaround(turnaround_id))
        ])

        const rejected = promises.find(({error}) => error);

        if (!rejected) setIsReadyToGetFrame(true)

        if (rejected) setTurnErrorPage(rejected.error.message);

        setTurnPageLoading(false);
    }

    const jumpToEvent = (event) => {
        const {role} = profile
        const {Camera, Position, Track_Id, Confidence, Bbox, Frame_Id, Process, State} = event
        const positions = Array.isArray(Bbox) ? Bbox : []
        const label = role === VIEWER ? State : `${State} (${Track_Id}, ${(Confidence * 100).toFixed(2)}%)`

        dispatch(setEventScrollTableSettings({Process, State, Frame_Id, Position}))
        setEvent(event);
        setBbox({frame_id: Frame_Id, positions, label})

        if (Camera !== cctv_id) {
            navigate(`/turnaround/${turnaround_id}/${gate_id}/${Camera}`);
            dispatch(initialTurnInfoState());
            dispatch(onRemoveCamera(cctv_id))
        }
    }

    const handleNextSlide = () => {
        if (currentFrame <= timestamps.length) {
            onChangeCurrentFrame(prevState => prevState + 1)
            setIsReadyToGetFrame(true)
            clearBboxEventTrack()
        }
    }

    const handlePrevSlide = () => {
        if (currentFrame !== 0) {
            onChangeCurrentFrame(prevState => prevState - 1)
            setIsReadyToGetFrame(true)
            clearBboxEventTrack()
        }
    }

    const getImgVideoFrame = (cctv_id) => {
        dispatch(getVideoFrame({cctv_id, timestamp: timestamps[currentFrame]}))
    }
    const downloadImage = () => {
        const file_name = `${AIRPORT_IATA}--${turnaround_id}--${cctv_id}--${timestamps[currentFrame]}.jpg`
        if (imagesState[cctv_id].imgLink) {
            return saveAs(imagesState[cctv_id].imgLink, file_name)
        }
    }
    const handleChangeOption = (value) => setOptions(value);

    const goToCameras = useMemo(() => {
        const defaultCctvIdColors = ["#002c8c", "#ff0000", "#ff00ff", "#873800"]
        return frames.reduce((array, camera, index) => {
            if (camera.cctv_id !== cctv_id) array.push({...camera, cameraColor: defaultCctvIdColors[index]});
            return array
        },[])
    }, [frames, cctv_id]);

    const getMarks = useMemo(() => {
        const marksObj = {}
        for (let i = 0; i < timestamps.length; i++) {
            const time = transformTime(timestamps[i]);
            if (i === 0 || i === timestamps.length - 1) {
                marksObj[i] = <SlideMark time={time} frameNumber={i}/>
            } else {
                marksObj[i] = ''
            }
        }
        return marksObj
    }, [timestamps, timeZone])

    const onChangeCompleteSlideTime = (value) => {
        onChangeCurrentFrame(value)
        setIsReadyToGetFrame(true)
        clearBboxEventTrack()

    }
    const slideTimeFormatter = (index) => `${transformTime(timestamps[index])} (${index})`

    const getUniqSuperClassName = useMemo(() => {
        const getUniqueSuperClassNames = getUniqueValues(inference?.detected_objects, 'superclass_name')
        return createOptionsSelect(['all', ...getUniqueSuperClassNames])
    }, [inference])

    const filterOptions = () => {
        const detected_objects = inference?.detected_objects;


        const projection = inference?.entities?.filter((item, _, array) => {
            if (!options.includes('all') && options.includes(item['superclass'])) return item

            if (options.includes('all')) return array
        })

        const selected = detected_objects?.filter((item, _, array) => {
            if (!options.includes('all') && options.includes(item['superclass_name'])) return item

            if (options.includes('all')) return array
        })

        //superclass key is different between entities and inference and this is not a bug.
        // for detections in inference this is "superclass_name" and for detections in entities is 'superclass'

        return {projection, selected}
    }


    const handleGoTo = (cctv_id) => {
        navigate(`/turnaround/${turnaround_id}/${gate_id}/${cctv_id}`);
        onChangeCurrentFrame(0)
        clearBboxEventTrack();
        dispatch(returnInitialEventState());
        dispatch(clearImgStates())
        rightSidebar.close()
    }
    const handleOpenLeftSidebar = () => leftSidebar.open()
    const handleOpenAddNewEvent = () => eventNewForm.open();
    const handleOpenEditEventForm = async (event) => {
        await jumpToEvent(event);
        await eventEditForm.open();
    }
    const handleCloseEventFormModal = () => {
        if (eventNewForm.state && !isAddEvent) {
            eventNewForm.close()
            if (isFromPotentialEventTable) {
                dispatch(getPairedEvent({process: null, event: null, isFromPotentialEventTable: false}))
            }

        }
        if (eventEditForm.state && !isAddValidation) eventEditForm.close()
    }
    const jumpToTime = (timestamp) => {
        jumpToTimeByIndex(timestamp);
        clearBboxEventTrack()
    }
    const findClosestTimeForward = (currentTimestamp, arrayTimestamps, seconds) => {
        const time = addSeconds(currentTimestamp, seconds)

        let index = currentFrame;
        while (index < arrayTimestamps?.length - 1) {
            if (time <= arrayTimestamps[index]) {
                break
            }
            index = index + 1
        }
        return index
    }

    const findClosestTimeBackward = (currentTimestamp, arrayTimestamps, seconds) => {
        const time = addSeconds(currentTimestamp, seconds)

        let index = currentFrame;
        while (index > 0) {
            if (time >= arrayTimestamps[index]) {
                break
            }
            index = index - 1
        }
        return index
    }

    const plusSecond = () => {
        const index = findClosestTimeForward(timestamps[currentFrame], timestamps, +TIMELINE_STEPS);
        if (currentFrame <= timestamps.length) {
            onChangeCurrentFrame(index)
            setIsReadyToGetFrame(true)
            clearBboxEventTrack()
        }
    }
    const minusSecond = () => {
        const index = findClosestTimeBackward(timestamps[currentFrame], timestamps, -TIMELINE_STEPS);

        if (currentFrame !== 0) {
            onChangeCurrentFrame(index)
            setIsReadyToGetFrame(true)
            clearBboxEventTrack()
        }
    }

    const debouncedClick = useCallback(_.debounce((callback) => {
        callback()
    }, 500, {leading: true, trailing: false, maxWait: 1000}), []);

    const debouncedPlusSecond = () => debouncedClick(plusSecond);
    const debouncedMinusSecond = () => debouncedClick(minusSecond);
    const debounceNextFrame = () => debouncedClick(handleNextSlide);
    const debouncePrevFrame = () => debouncedClick(handlePrevSlide);
    const handleOpenRightSidebar = () => rightSidebar.open()

    const handleArchiveTurn = () => {
        dispatch(onChangeIsOpenSession(true));
        dispatch(onChangeIsDisabled(true))
    }

    const archiveSendMessage = () => {
        const isArchived = turnaround?.status === "archived"
        const archiveParameters = {
            type: isArchived ? 'delete_archived_turnaround' : 'archive_turnaround',
            params: {turnaround_id, token: profile.token}
        }
        const toastText = isArchived ? "Prepare to extract from archiving" : "Prepare for archiving";

        dispatch(sendMessageInArchiveTurnSession(archiveParameters));
        dispatch(getPrepareToast({
            toast_name: "archive_and_remove_turn",
            toast_id: toast.loading(toastText, {closeButton: true})
        }))
    }


    const getInferenceAndFrame = () => {
        const importantFields = {timestamp: timestamps[currentFrame], cctv_id, turnaround_id}
        dispatch(getVideoFrame({...importantFields, is_archived: turnaround.status === "archived"}))
        dispatch(getInference(importantFields))
        setIsReadyToGetFrame(false)
    }

    const shouldDisableWsBtn = useMemo(() => {
        return [
            turnaround.status === "removed",
            turnaround?.source !== 'mongodb',
            isDisableArchiveButton
        ].some(btn_condition => btn_condition)
    }, [turnaround?.status, turnaround?.source, isDisableArchiveButton])

    useEffect(() => {
        callRequests()
    }, [cctv_id]);

    useEffect(() => {
        if (isReadyToGetFrame) {
            getInferenceAndFrame()
        }
    }, [isReadyToGetFrame]);

    useEffect(() => {
        if (timestamps.length) {
            jumpToTimeByIndex(bbox['frame_id'])
        }
    }, [timestamps, bbox]);

    useEffect(() => {
        if (isOpenSession && !isConnected) {
            dispatch(connectionToArchiveTurnSession());
        }

        if (isOpenSession && isConnected) {
            archiveSendMessage()
        }
    }, [isOpenSession, isConnected]);

    useEffect(() => {
        return () => returnInitialStates()
    }, []);

    if (turnErrorPage) return <Navigate to="/404"/>

    if (turnPageLoading) return <BigLoading/>

    return (
        <div className={'turnaroundSection'}>
            <ModalSidebar placement={'left'} width={350} state={leftSidebar.state} close={leftSidebar.close}
                          isFixed={false} className={'turnaroundSection__wrapperLeftHideBlock'}
                          title={'Turnaround info'}>
                <TurnaroundDetails turnaround={Object.entries(turnaround)}/>
            </ModalSidebar>

            <ModalSidebar placement={'right'} width={goToCameras?.length > 2 ? 650 : 400}
                          state={rightSidebar.state}
                          close={rightSidebar.close} isFixed={false}
                          className={RIGHT_SIDEBAR_CLASSES(goToCameras)}>
                <div className="textRightContainer">
                    <div className={'textRightContainer__wrapperSecondCamera'}>
                        {goToCameras?.map(({cctv_id, cameraColor}) => {
                            return <LinkImg key={cctv_id} cctv_id={cctv_id}
                                            totalFrames={frames.length}
                                            handleGoTo={handleGoTo}
                                            isOpenModalGoTo={rightSidebar.state}
                                            getImgVideoFrame={getImgVideoFrame}
                                            imgLink={setErrorImage(+imagesState[cctv_id]?.imgError,
                                                imagesState[cctv_id]?.imgLink)}
                                            cameraColor={cameraColor}

                            />
                        })}
                    </div>
                </div>
            </ModalSidebar>

            <section className="turnaroundSection__turnaroundInformation">
                <header className="turnaroundInfoHeader">
                    <Button color={'#1f618d'} type={'primary'} onClick={handleOpenLeftSidebar}
                            loading={isTurnInfo} className={'openSidebarButton left'}>
                        Turnaround Info
                    </Button>
                    {profile.role === ADMIN &&
                        <SwitchArchiveBtnComponent
                            isArchived={turnaround.status === "archived"}
                            handler={handleArchiveTurn}
                            isDisabled={shouldDisableWsBtn}
                            removed_btn_text={"Extract from archiving"}
                            archived_btn_text={"Push to archive"}
                            tooltip_description={
                            archiveTooltipMessage[turnaround?.source]
                                ||
                                archiveTooltipMessage[turnaround?.status]
                            }
                            pop_confirm_title={"Unarchiving will delete inferences and images. Are you sure ?"}
                        />
                    }

                    <TimeLine marks={getMarks} maxSize={timestamps.length - 1} minSize={0}
                              formatter={slideTimeFormatter} onChangeComplete={onChangeCompleteSlideTime}
                              currentFrame={currentFrame}
                    />

                    {goToCameras.length > 0 &&
                        <Button color={'#1f618d'} type={'primary'} onClick={handleOpenRightSidebar}
                                className={'openSidebarButton right'}
                        >
                            Go To
                        </Button>}
                </header>
                <section className={'bodyTurnaroundInfo'}>
                    <div className="imgContainer">
                        <h1 className="cctv-text">Turnaround: {turnaround_id} | Camera: {cctv_id}</h1>
                        <Canvas
                            downloadImage={downloadImage}
                            turnaround_id={turnaround_id}
                            image={setErrorImage(+imagesState[cctv_id]?.imgError, imagesState[cctv_id]?.imgLink)}
                            cctv_id={cctv_id}
                            gate_id={gate_id}
                            checkedBoxes={checkedBoxes}
                            bbox={bbox}
                            selectedData={filterOptions()}
                            inference={inference}
                            options={options}
                            isCorrectOption={options?.find((option) => option === 'all' || option === 'aircraft')}
                            goToCameras={goToCameras}
                        />
                        <JumpFrameHelpers
                            onNext={debounceNextFrame}
                            onPrev={debouncePrevFrame}
                            onMinusSecond={debouncedMinusSecond}
                            onPlusSecond={debouncedPlusSecond}
                            icons={{ARROW_LEFT, ARROW_RIGHT}}
                            isMoreThenLast={currentFrame >= timestamps.length - 1}
                            isSmallerThenFirst={currentFrame <= 0}
                            currentFrame={currentFrame}
                            totalInferences={timestamps.length - 1}
                            value={TIMELINE_STEPS}
                        />
                        <span className={'turnTimeDate'}>
                            {transformTime(timestamps[currentFrame])} | timestamp: {timestamps[currentFrame]}
                        </span>

                        <DetectionCheckboxes
                            camera_position={camera?.position}
                            checkedBoxes={checkedBoxes}
                            setCheckBoxes={setCheckBoxes}
                        />

                        <Select
                            mode="multiple" allowClear value={options} style={{width: '100%'}}
                            placeholder={generateDetectionPlaceholder(checkedBoxes)} onChange={handleChangeOption}
                            options={getUniqSuperClassName}
                            disabled={!checkedBoxes['DETECTIONS'] && !checkedBoxes['TRACKERS'] && !checkedBoxes['PROJECTIONS'] && !checkedBoxes['OTHER_PROJECTIONS']}
                        />

                        <EntitiesColorInstructions
                            cameras={goToCameras}
                            isReadyToRender={checkedBoxes['OTHER_PROJECTIONS'] && goToCameras.length}
                        />
                    </div>


                    <div className={'bodyTurnaroundInfo__tablesWrapper'}>
                        <CollapseTurnTables
                            handleOpenAddNewEvent={handleOpenAddNewEvent}
                            handleOpenEditEventForm={handleOpenEditEventForm}
                            jumpToEvent={jumpToEvent}
                        />

                        <ValidationForms
                            event={event} isAddEventForm={eventNewForm.state}
                            isEditEventForm={eventEditForm.state}
                            inference={timestamps[currentFrame]}
                            closeForm={handleCloseEventFormModal}
                            jumpToTime={jumpToTime} jumpToEvent={jumpToEvent}
                        />

                    </div>
                </section>
            </section>
        </div>
    );
}

export default Turnaround
