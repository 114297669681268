import {getMillisecondsUnixTimestamp} from "../timeHelper";

export const sortByDate = (a, b) => {
    if (!a || !b) return -1
    return getMillisecondsUnixTimestamp(a) - getMillisecondsUnixTimestamp(b)
}

export const sortByKeysLength = (a, b) => {
    if (!a || !b) return -1
    return Object.keys(b).length - Object.keys(a).length
}

export const resortObjectFieldsFunction = (object, arrayCorrectPositionFields) => {
    return Object.entries(object).sort(([keyA], [keyB]) => {
        return arrayCorrectPositionFields.indexOf(keyA) - arrayCorrectPositionFields.indexOf(keyB)
    })
}