export const getStorageValue = (key, defaultValue = {}, isWhichStorage = 'session') => {
    const saved = isWhichStorage === 'session' ? sessionStorage.getItem(key) : localStorage.getItem(key);
    const initial = JSON.parse(saved);
    return initial || defaultValue;
}

export function setLocalStorageValue (key, value) {
   localStorage.setItem(key, value)
}

export const setSessionStorageValue = (key, value) => {
    sessionStorage.setItem(key, value)
}

export const removeCashFile = (elem) => {
    if (localStorage.getItem(elem)) {
        localStorage.removeItem(elem)
    }
}


export const clearLocalStorage = () => localStorage.clear()
export const clearSessionStorage = () => sessionStorage.clear()

export const setCacheTableSettingsGroup = (key, value) => {
    const cacheTableSettings = getStorageValue('tableGroupsSetting', {}, 'local');
    setLocalStorageValue('tableGroupsSetting', JSON.stringify({...cacheTableSettings, [key]: value}))
}

export const getCashProfile = () => getStorageValue("profile", null, "local");

export const isAuthenticated = () => {
    const profile = getCashProfile();
    return profile && profile.token
}

