import FidsModalButton from "../../../components/Turnarounds/FidsModalButton";
import Cameras from "../../../components/Turnarounds/Cameras";
import CameraEvents from "../../../components/Events/CameraEvents";
import EmptyField from "../../../components/Customs/EmptyField";
import TagItem from "../../../components/ANTD/TagItem";
import SimpleColumnTitle from "../../../components/Customs/SimpleColumnTitle";
import {turnaroundColorStatuses} from "../../constants/objects/colorStatuses";


export const fidsColumns = (
    {
        getColumnSearchProps,
        transformFidsDateTwo,
        gate_name_options,
        status_options,
        handleOpenModal,
    }) => ([
    {
        key: 'turnaround_id',
        title: <SimpleColumnTitle value={"id"} className={"antd-column-title"}/>,
        dataIndex: 'id',
        ...getColumnSearchProps('id'),
        filterSearch: true,
        render: (turn_id, _) => {
            return (
                <EmptyField isEmpty={!turn_id} message={'N/A'}>
                    <FidsModalButton id={turn_id} handleOpenModal={handleOpenModal}/>
                </EmptyField>
            )
        }
    },
    {
        key: 'gate',
        title: <SimpleColumnTitle value={"gate"} className={"antd-column-title"}/>,
        dataIndex: 'parking_gate',
        filters: gate_name_options,
        filterSearch: true,
        onFilter: (value, {parking_gate}) => parking_gate.indexOf(value) === 0,
        render: (parking_gate) => {
            return (
                <EmptyField isEmpty={!parking_gate} message={'NG'}>
                    <span>{parking_gate}</span>
                </EmptyField>
            )
        }

    },
    {
        key: 'sta',
        title: <SimpleColumnTitle value={"sta"} className={"antd-column-title"}/>,
        dataIndex: 'sta_utc',
        sorter: true,
        render: (sta) => {
            return <EmptyField isEmpty={!sta} message={'N/A'}>
                <TagItem value={transformFidsDateTwo(sta)}/>
            </EmptyField>
        }

    },
    {
        key: 'eta',
        title: <SimpleColumnTitle value={"eta"} className={"antd-column-title"}/>,
        dataIndex: 'eta_utc',
        sorter: true,
        render: (eta) => {
            return (
                <EmptyField isEmpty={!eta} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(eta)} color={'yellow'}/>
                </EmptyField>
            )
        },
    },
    {
        key: 'ata',
        title: <SimpleColumnTitle value={"ata"} className={"antd-column-title"}/>,
        dataIndex: 'ata_utc',
        sorter: true,
        render: (ata) => {
            return (
                <EmptyField isEmpty={!ata} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(ata)} color={'#FFE100'}/>
                </EmptyField>
            )
        },
    },
    {
        key: 'std',
        title: <SimpleColumnTitle value={"std"} className={"antd-column-title"}/>,
        dataIndex: 'std_utc',
        sorter: true,
        render: (std) => {
            return (
                <EmptyField isEmpty={!std} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(std)} empty_value={'ND'} />
                </EmptyField>
            )
        },
    },
    {
        key: 'etd',
        title: <SimpleColumnTitle value={"etd"} className={"antd-column-title"}/>,
        dataIndex: 'etd_utc',
        sorter: true,
        render: (etd) => {
            return (
                <EmptyField isEmpty={!etd} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(etd)}/>
                </EmptyField>
            )
        },
    },
    {
        key: 'atd',
        title: <SimpleColumnTitle value={"atd"} className={"antd-column-title"}/>,
        dataIndex: 'atd_utc',
        sorter: true,
        render: (atd) => {
            return (
                <EmptyField isEmpty={!atd} message={'N/A'}>
                    <TagItem value={transformFidsDateTwo(atd)} color={'#FFE100'}/>
                </EmptyField>
            )
        },
    },

    {
        key: 'cameras',
        title: <SimpleColumnTitle value={"cameras"} className={"antd-column-title"}/>,
        dataIndex: 'data',
        render: (cameras, {parking_gate, id}) => {
            return (
                <EmptyField isEmpty={!cameras.length} message={"N/A"}>
                    <Cameras cameras={cameras} parking_gate={parking_gate} id={id}/>
                </EmptyField>

            )
        }
    },
    {
        key: "frames",
        title: <SimpleColumnTitle value={"frames"} className={"antd-column-title"}/>,
        dataIndex: "data",
        render: (cameras) => {
            return cameras.map(({cctv_id, total_inferences}) => <p key={cctv_id}>{total_inferences}</p>)
        }

    },
    {
        key: 'events',
        title: <SimpleColumnTitle value={"events"} className={"antd-column-title"}/>,
        dataIndex: 'data',
        render: (cameras, {is_validated}) => {
            return (
                <EmptyField isEmpty={!cameras.length} message={'N/A'}>
                    <CameraEvents cameras={cameras} is_validated={is_validated}/>
                </EmptyField>
            )
        }
    },
    {
        key: 'status',
        title: <SimpleColumnTitle value={"status"} className={"antd-column-title"}/>,
        dataIndex: 'status',
        filters: status_options,
        filterSearch: true,
        onFilter: (value, record) => record?.status?.indexOf(value) === 0,
        render: (status) => {
            return (
                <EmptyField isEmpty={!status} message={'NS'}>
                    <TagItem value={status} color={turnaroundColorStatuses[status]} mark tag_class={'markTag status'}/>
                </EmptyField>
            )
        }
    },
])