import {createAsyncThunk} from "@reduxjs/toolkit";
import {Alert_Socket} from "../../../services/Websocket";
import {incrementAlerts} from "../../slices/Websocket/Alert";

export const connectionToAlerts = createAsyncThunk('ws_alert/connectionToAlerts',
    async (_, {getState}) => {
        const {systemSlice: {settingsUI}, Auth: {profile}} = getState().api
        const path = `${settingsUI.WEBSOCKET_URL}?token=${profile?.token}`
        await Alert_Socket.connection(path);
})

export const sendToAlertSession = createAsyncThunk('ws_alert/sendToAlertSession',
    async (message) => {
        await Alert_Socket.sendMessage(message);
})


export const getAlertMessage = createAsyncThunk('ws_alert/getAlertMessage',
    async (message, {dispatch}) => {
        await Alert_Socket.getMessage((message) => {
                const data = JSON.parse(message.data);

                if (data?.message !== 'message recieved' && data.message !== 'connection accepted') {
                        if (data.type && data.type === "alert") {
                                dispatch(incrementAlerts())
                        }

                }
        })
})