import {useEffect} from 'react';
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getLastFrame} from "../../store/asyncThunkFunctions/Api/ImageRequests";
import {Image} from "antd";
import {selectCctvFrames} from "../../store/selectors/Api/frames";
import {setErrorImage} from "../../utils/helpers/setErrorImageHelper";
import {selectIsOpenExpandableRow} from "../../store/selectors/Ui/resending";

const StreamIcon = ({_id, url_path, isActiveMode, cctv_id}) => {
    const dispatch = useDispatch()
    const isOpenExpandableRowState = useSelector(selectIsOpenExpandableRow);
    const listOfImages = useSelector(selectCctvFrames);

    useEffect(() => {
        if (isOpenExpandableRowState['_id'] === _id && isOpenExpandableRowState['isOpen'] && isActiveMode) {
            dispatch(getLastFrame({cctv_id, width: 60, height: 60,}))
        }
    }, [isOpenExpandableRowState]);

    return (
        <div className={'stream_liveView_container'}>
            <Link to={url_path}>
                <Image
                    width={'100%'} height={'100%'}
                    src={listOfImages[cctv_id]?.imgLink}
                    fallback={setErrorImage(+listOfImages[cctv_id]?.imgError)}
                />
            </Link>
        </div>
    )
};

export default StreamIcon;