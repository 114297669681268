import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../services/airAxios";

export const getEventFiles = createAsyncThunk(
    'turnaround/getEventFiles',
    async (turnaround_id) => {
        try {
            const {data} = await air_axios_settings(`turnarounds/events?turnaround_id=${turnaround_id}&as_file=true`);
            return data
        } catch (error) {
            throw new Error(error);
        }
    })

export const getTurnPotentialEvents = createAsyncThunk(
    'events/getTurnPotentialEvents',
    async (turnaround_id) => {
        try {
            const {data} = await air_axios_settings(`/events/match?turnaround_id=${turnaround_id}`)
            return data
        } catch (error) {
            throw new Error(error)
        }
    })

export const getEventModes = createAsyncThunk(
    'events/getPotentialEvents',
    async (params,) => {
        try {
            const {data} = await air_axios_settings('/events/event_modes', {params, paramsSerializer: {indexes: null}})
            return data
        } catch (error) {
            throw new Error(error)
        }

    })
export const addEvent = createAsyncThunk(
    'events/addEvent',
    async (settings = {turnaround_id: '', body: {}}) => {
        try {
            const {turnaround_id, body} = settings
            const {data} = await air_axios_settings.post(`/events/add`, body, {params: {turnaround_id}})
            return data
        } catch (error) {
            throw new Error(error)
        }
    })

export const getProcessesName = createAsyncThunk(
    'events/getProcessesName',
    async () => {
        try {
            const {data} = await air_axios_settings('/events/processes_names')
            return data
        } catch (error) {
            throw new Error(error)
        }
    })

export const getEventsName = createAsyncThunk(
    'events/getEventsName',
    async (process) => {
        try {
            const {data} = await air_axios_settings.get(`/events/events_names`, {params: {process}})
            return data
        } catch (error) {
            throw new Error(error)
        }
    })
export const getPositionsName = createAsyncThunk(
    'events/getPositionsName',
    async (params) => {
        try {
            const {data} = await air_axios_settings.get('/events/positions', {params})
            return data
        } catch (error) {
            throw new Error(error)
        }
    })

export const deleteEvent = createAsyncThunk(
    'evens/deleteEvent',
    async (settings = {turnaround_id: '', event: {}}) => {
        try {
            const {turnaround_id, event} = settings
            return await air_axios_settings.put('/events/remove', event, {params: {turnaround_id}})
        } catch (error) {
            throw new Error(error)
        }
    })


export const editEventVisibility = createAsyncThunk(
    "events/editEventVisibility",
    async (body) => {
    try {
       return await air_axios_settings.put(`events/visibility`, body)
    } catch (error) {
        throw new Error(error)
    }
})

export const editEventRequired = createAsyncThunk(
    "events/editEventRequired",
    async (body) => {
        try {
            return await air_axios_settings.put(`events/required`, body)
        } catch (error) {
            throw new Error(error)
        }
    })