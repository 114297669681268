import {Link} from "react-router-dom";
import {Admin} from "../Roles";
import {configurationChildren} from "./configurationChildren";
import {performanceChildren} from "./performanceChildren";
import {administrationChildren} from "./administrationChildren";
import {SYSTEM_LINKS} from "../Links";

const {mock} = SYSTEM_LINKS;

export const systemChildren = [
    {
        label: <Link to={mock}>Mock</Link>,
        key: mock
    },
    {
        label: "Configuration",
        key: "configuration",
        children: configurationChildren
    },
    {
        label: "Performance",
        key: "performance",
        children: performanceChildren
    },
    {
        label: "Administration",
        key: "administration",
        roles: Admin,
        children: administrationChildren
    }
]