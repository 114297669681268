import {useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {useDispatch} from "react-redux";
import {useForm} from "react-hook-form";
import {defaultSignInState} from "../../utils/constants/objects/formInitialStates";
import {EYE_CLOSE, EYE_OPEN, LOGIN} from "../icons/antd";
import {Button} from "antd";
import {login} from "../../store/asyncThunkFunctions/Api/AuthReqeusts";
import {homeLink} from "../../utils/routeUtils/Paths";

const Login = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [changeType, setChangeType] = useState(true);
    const {
        register,
        reset,
        handleSubmit,
        formState: {errors}} =
        useForm({defaultValues: defaultSignInState})

    const checkType = changeType ? 'password' : 'text'
    const handleChangeType = () => setChangeType(!changeType);

    const onSubmit = async (data) => {
        await dispatch(login(data))
        await reset(defaultSignInState)
        await navigate(location.pathname['from']?.pathname || homeLink)
    }

    return (
        <div className={'wrapperLoginForm'}>
            <header className={'wrapperLoginForm__title'}>
                <h1>login</h1>
            </header>
            <form onSubmit={handleSubmit(onSubmit)} className={'mainForm'}>
                <div className={'mainForm__wrapperInput'}>
                    <div className={'blockInput'}>
                        <input type="text" {...register('_id')} placeholder={'Enter your login'} required/>
                    </div>
                    {errors?.login && errors.login?.type && <span className={'inputError'}>{errors.login['message']}</span>}
                </div>
                <div className={'mainForm__wrapperInput'}>
                    <div className={'passwordBlockInput blockInput'}>
                        <input type={checkType} {...register('password')} placeholder={'password'} required/>
                        <button type={'button'} onClick={handleChangeType}>{changeType ? EYE_CLOSE : EYE_OPEN}</button>
                    </div>
                    {errors?.password && errors.password?.type && <span className={'inputError'}>{errors.password['message']}</span>}
                </div>
                <Button icon={LOGIN} className={'mainForm__submitButton'} htmlType={'submit'}>LOGIN</Button>
            </form>
        </div>
    );
};

export default Login;