import {useEffect, useState} from 'react';
import EmptyImg from "../../assets/no_image.jpg"
import {SIMPLE_LOADING} from "../icons/antd";

const ImageFallbackWrapper = ({img: frame, errorClassName, loadingClassName, externalLoading, externalError, children}) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        if (externalLoading === undefined && externalError === undefined) {
            const img = new Image();
            img.src = frame;
            img.onload = () => setLoading(false);
            img.onerror = () => setError("500");
            console.log("Render")
        }
    }, [frame, externalLoading, externalError]);


    const isLoading = externalLoading !== undefined ? externalLoading : loading;
    const isError = externalError !== undefined ? externalError : error;

    if (isLoading && !isError) {
        return (
            <div className={loadingClassName}>
                <span>{SIMPLE_LOADING(100, "blue")}</span>
            </div>
        )
    }

    if (!frame && isError) {
        return (
            <div className={errorClassName}>
                <img src={EmptyImg} alt="Error img"/>
            </div>
        )
    }

    return children
};

export default ImageFallbackWrapper;