import React from 'react';
import {Button, Descriptions, Empty} from "antd";
import {resortObjectFieldsFunction} from "../../utils/helpers/dataHelpers/arrayObjectSorts";
import {normalizeEventPositionFields} from "../../utils/constants/arrays/validationEventForm";

const EventInformation = ({event, onClick, transformTime}) => {

    const transformBboxList = (list) => {
        if (!Array.isArray(list)) return
        return list.map(coord => Number.isInteger(coord) ? coord : Number(coord.toFixed(2)))
    }

    return (
        <Descriptions bordered size={'small'} layout="vertical">
            {!Object.keys(event).length && <Empty/>}
            {event && Object.keys(event).length &&
                resortObjectFieldsFunction(event, normalizeEventPositionFields)?.map(([key, value]) => {
                if (!['validation', 'Confidence', 'Manual_Event', 'uuid'].includes(key)) {
                    const bbox = transformBboxList(value)
                    return <Descriptions.Item label={key === 'State' ? 'Event' : key} key={key}>
                        {
                            !value ? "N/A" : key === 'Timestamp' ? transformTime(value) :
                                key === "Bbox" ? bbox.join(", ") : value
                        }
                    </Descriptions.Item>
                }})}
            <Descriptions.Item>
                <Button onClick={() => onClick(event)}>Back to Frame</Button>
            </Descriptions.Item>
        </Descriptions>
    )
};

export default EventInformation;