import {createAsyncThunk} from "@reduxjs/toolkit";
import axios from "axios";
import {toast} from "react-toastify";
import {setLocalStorageValue} from "../../../utils/helpers/cacheHelpers";
import {baseUrl} from "../../../utils/constants/variables/api";

export const login = createAsyncThunk('Auth/login',
    async (body) => {
        try {
            const {data} = await axios.post(`${baseUrl}/auth/login`, body, {withCredentials: true});
            const {token, role, _id, is_active} = data
            const res = {token, role, _id, is_active}
            setLocalStorageValue("profile", JSON.stringify(res));
            return res
        } catch (error) {
            const status = error.response['status']
            if (status === 401 || status === 403) {
                toast.error("Incorrect login details")
            }
            console.log(error.response, 'ERROR')
            throw new Error(error)
        }
})