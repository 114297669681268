import {useEffect} from 'react';
import {useDispatch} from "react-redux";
import {resetGateState} from "../store/slices/Api/sliceGateRequest";
import {saveAs} from 'file-saver';
import TurnaroundData from "../components/Export/TurnaroundData";
import Video from "../components/Export/Video";
import Validations from "../components/Export/Validations";

const Export = () => {
    const dispatch = useDispatch()

    const downloadFile = (file, file_name) => saveAs(file, file_name);

    const downloadFileWithSplitName = (file_path) => {
        if (file_path) {
            const splits = file_path.split('/');
            return downloadFile(file_path, splits[splits.length-1]);
        }
    }

    useEffect(() => {
        return () => {
            dispatch(resetGateState())
        }
    }, []);

    return (
        <div className={"exportWrapper"}>
            <div className={'exportWrapper__downloadWrapper'}>
                <header><h2>EXPORT</h2></header>
                <section className={'downloadSection'}>
                    <TurnaroundData downloadFile={downloadFile} downloadFileWithSplitName={downloadFileWithSplitName} />
                    <Video/>
                    <Validations downloadFileWithSplitName={downloadFileWithSplitName}/>
                </section>
            </div>
        </div>

    );
};

export default Export;