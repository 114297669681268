export const MAIN_LINKS = {
    live_view: "/liveView",
    turnarounds: "/turnarounds",
    exports: "/export",
    system: "/system",
    profile: "/profile",
    login: "/login",
}

const {system} = MAIN_LINKS

export const SYSTEM_LINKS = {
    mock: `${system}/mock`,
}

export const PERFORMANCE_LINKS = {
    fullDay: `${system}/performance/24hours`,
    min_max: `${system}/performance/minmax`,
    date_compare: `${system}/performance/dateCompare`
}

export const ADMINISTRATION_LINKS = {
    users: `${system}/administration/users`,
    audit: `${system}/administration/audit`,
    add: `${system}/administration/add`,
    update: `${system}/administration/update`,
}


export const CONFIGURATION_LINKS = {
    gateCameras: `${system}/configuration/gates_cameras`,
    importExport: `${system}/configuration/import_export`,
    presentation: `${system}/configuration/presentation`,
    events: `${system}/configuration/events`,
    objects: `${system}/configuration/objects`,
}


/// old versions
export const FORM_GATE_LINKS = {
    step_one: '/formGate/step_one',
    step_two: '/formGate/step_two',
    step_three: '/formGate/step_three',
    preview: '/formGate/preview',
}


export const FORM_GATE_ADD_CAMERAS_LINK = (_id, form_step) => `/formGate/gate/${_id}/add_cameras/${form_step}`
export const FORM_GATE_EDIT_CAMERA_LINK = (_id, cctv_id, form_step) => `/formGate/gate/${_id}/edit_camera/${cctv_id}/${form_step}`

export const FORCE_FORM_GATE_STEP = ({_id, cctv_id, form_step}) => {
    if (cctv_id) return FORM_GATE_EDIT_CAMERA_LINK(_id, cctv_id, form_step)
    if (!cctv_id && _id) return FORM_GATE_ADD_CAMERAS_LINK(_id, form_step)
    return `/formGate/${form_step}`
}