import React from "react";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import {tableDefStyles} from "../constants/objects/defaultStyles";

export const filterIcon = (filtered) => <SearchOutlined style={{color: filtered ? '#1890ff' : undefined}}/>

export const onFilter = (record, dataIndex, value) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())

export const onFilterVisibleChange = (visible, searchInput) => {
    if (visible) setTimeout(() => searchInput.current?.select(), 100)
}

export const returnHighLighter = (text, searchText) => {
    return (
        <Highlighter highlightStyle={{...tableDefStyles.HighLighter}}
                     searchWords={[searchText]} autoEscape
                     textToHighlight={text ? text.toString() : ''}/>
    )
}

export const paginationSettings = (page, limit, total) => {
    return {
        total,
        showSizeChanger: true,
        current: page,
        pageSizeOptions: ['5', '10', '25', '50', '100'],
        pageSize: limit,
    }
}


export const injectNewAntdSortSettings = (sort_settings) => {
    if (!sort_settings || !sort_settings.order) return null
    const {order, field} = sort_settings
    const sort = {sort_by: field}
    if (order === 'ascend') sort.direction = 1
    if (order === 'descend') sort.direction = -1
    return sort
}

export const globalProps = {
    bordered: true,
    rowClassName: "editable-row",
}