import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../services/airAxios";
import {changeRefreshToggle, toggleIsFetching} from "../../slices/Api/turnaroundInfo";
import {injectNewAntdSortSettings} from "../../../utils/tableUtils/tableSettings";

const fidsRequest = async (settings = {urlParamsList: {}, isRefresh: false}, {dispatch}) => {
    try {
        const {urlParamsList, isRefresh} = settings
        const {sort, ...rest} = urlParamsList
        const modifiedSortData = injectNewAntdSortSettings(sort);

        const {data} = await air_axios_settings.get('/fids', {
            params: {...rest, ...modifiedSortData},
            paramsSerializer: {indexes: null}
        });

        if (isRefresh) dispatch(changeRefreshToggle(true))
        return data
    } catch (error) {
        throw new Error(error)
    } finally {
        dispatch(toggleIsFetching(false))
    }
}


export const getPageFids = createAsyncThunk('turnaround/getFidsPage', fidsRequest)

export const updatedFids = createAsyncThunk('turnaround/updatePage',  fidsRequest) // add it just for now - later I'll find the another solution


export const getTurnaround = createAsyncThunk('turnaround/getInfo',
    async (id ) => {
        try {
            if (id) {
                const {data} = await air_axios_settings.get(`/turnarounds/${id}`);
                return data
            }
            return null

        } catch (error) {
            throw new Error(error)
        }
    }
)

export const getFidsFrames = createAsyncThunk('turnaround/getFrames',
    async (turnaround_id) => {
    try {
        const {data} = await air_axios_settings.get(`/turnarounds/frames`, {params: {turnaround_id}})
        return data
    } catch (error) {
        throw new Error(error)
    }
})


export const getCameraFramesTimes = createAsyncThunk('turnaround/getCameraImages',
    async (params = {turnaround_id: '', cctv_id: ''}) => {
    try {
        const {data} = await air_axios_settings.get(`/turnarounds/timestamps`, {params})
        return data
    } catch (error) {
        throw new Error(error)
    }
})

export const getEvents = createAsyncThunk('turnaround/getEvents',
    async (params) => {
    try {
        const {data} = await air_axios_settings(`/turnarounds/events`, {params: {...params, as_file: false}})
        return data
    } catch (error) {
        throw new Error(error)
    }
})

export const getInference = createAsyncThunk('turnaround/getInference',
    async (params) => {
    try {
        const {data} = await air_axios_settings.get('turnarounds/inference', {params})
        return data
    } catch (error) {
        throw new Error(error)
    }
})

export const getStatuses = createAsyncThunk('turnaround/getStatuses',
    async () => {
    try {
        const {data} = await air_axios_settings('turnarounds/statuses');
        return data
    } catch (error) {
        throw new Error(error)
    }
})