import {configureStore} from "@reduxjs/toolkit";
import {composeWithDevTools} from '@redux-devtools/extension'
import thunk from "redux-thunk";
import Collector from "./combines/Collector";

const rootReducer = (state, action) => {
    if (action.type === 'Auth/handleLogout') {
        state = undefined;
    }
    return Collector(state, action);
}

export function setupStore () {
    return configureStore({
        reducer: rootReducer,
        devTools: composeWithDevTools,
        middleware: [thunk]
    })
}