import React from 'react';
import {Popconfirm} from "antd";

const DropDownLabelPopConfirmItem = ({onConfirm, value, placement}) => {
    return (
        <div onClick={(e) => e.stopPropagation()}>
            <Popconfirm
                title={"Are you sure ?"}
                onConfirm={onConfirm}
                placement={placement}
                okText={"Yes"}
                cancelText={"No"}
            >
                <span className={"popconfirm-trigger"}>{value}</span>
            </Popconfirm>
        </div>
    )
};

export default DropDownLabelPopConfirmItem;