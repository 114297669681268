import Mock from "../../../pages/system/Mock";
import Performance from "../../../routes/Performance";
import Administration from "../../../routes/Administration";
import Configuration from "../../../routes/Configuration";
import {SYSTEM_PATHS} from "../Paths";
import {Admin, WithoutViewerValidator} from "../Roles";

const {
    administration, configuration, events,
    mock, objects, performance
} = SYSTEM_PATHS


export const systemList =  [
    {
        path: mock,
        element: <Mock/>,
        roles: WithoutViewerValidator
    },
    {
        path: configuration,
        element: <Configuration/>,
        roles: WithoutViewerValidator
    },
    {
        path: performance,
        element: <Performance/>,
        roles: WithoutViewerValidator
    },
    {
        path: administration,
        element: <Administration/>,
        roles: Admin
    }
]