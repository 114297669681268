import {useState, useEffect, useCallback, useMemo} from 'react';
import AddEvent from "./AddEvent";
import EditEvent from "./EditEvent";
import {useDispatch, useSelector} from "react-redux";
import {Form} from "antd";
import {
    addEvent,
    getPositionsName,
    getTurnPotentialEvents
} from "../../../store/asyncThunkFunctions/Api/EventsRequest";
import {getTime, getTimestampInCorrectMilliSeconds, returnCorrectTimeZoneTime,} from "../../../utils/helpers/timeHelper";
import {useParams} from "react-router-dom";
import {
    addValidation, getDetections, getIsValidFields
} from "../../../store/asyncThunkFunctions/Api/ValidationRequests";
import {getEvents} from "../../../store/asyncThunkFunctions/Api/TurnaroundRequests";
import {createOptionsSelect} from "../../../utils/helpers/dataHelpers/arrayObjectModifiers";
import {defaultEventEditFormState} from "../../../utils/constants/arrays/validationEventForm";
import {
    getPairedEvent,
    resetSomeValidationState,
    returnInitValidationState
} from "../../../store/slices/Api/Validation";
import {returnSomeInitialState} from '../../../store/slices/Api/Events'
import ModalWindow from "../../Customs/ModalWindow";
import classNames from "classnames";
import {selectTimeZone} from "../../../store/selectors/Ui/TimeZone";
import {
    selectAddEvent,
    selectEventNames,
    selectPositionNames
} from "../../../store/selectors/Api/events";
import {selectPairedEvent, selectValidationSlice} from "../../../store/selectors/Api/validation";
import {initialValidationFormState} from "../../../utils/constants/objects/formInitialStates";
import {selectFrameState} from "../../../store/selectors/Api/turnaround";

const ValidationForms =
    ({
         isAddEventForm, isEditEventForm, inference, event,
         closeForm, jumpToTime, jumpToEvent

     }) => {
        const [form] = Form.useForm();
        const {cctv_id, turnaround_id} = useParams();

        const dispatch = useDispatch();
        const {detections, isDetections, isAddValidation} = useSelector(selectValidationSlice);
        const {process, event: paired_event, isFromPotentialEventTable} = useSelector(selectPairedEvent);
        const {event_names} = useSelector(selectEventNames);
        const {position_names, isPositionsName} = useSelector(selectPositionNames);
        const {isAddEvent} = useSelector(selectAddEvent);
        const timeZone = useSelector(selectTimeZone);
        const {frames} = useSelector(selectFrameState);

        const [editOption, setEditOption] = useState({});
        const [cameraOption, setCameraOption] = useState(cctv_id);


        const initialState = {
            ...initialValidationFormState,
            timestamp: getTime(inference),
            correct_time: inference,
            cctv_id: cameraOption
        }

        const eventWrapperFormClasses = classNames('eventModalForm',
            {
                'newForm': isAddEventForm, 'editForm': isEditEventForm,
                'disableEventModalForm': isAddEvent || isAddValidation
            }
        );

        const eventFormClasses = classNames('eventForm', {'newForm': isAddEventForm, 'editForm': isEditEventForm});

        const transformTime = useCallback((time) => returnCorrectTimeZoneTime(time, timeZone), [timeZone]);

        const correctOptions = useMemo(() => {
            const value = editOption?.value
            if (value === 'wrong detections' || value === 'no detection') {
                return createOptionsSelect(detections)
            }

            if (value === 'location') {
                return createOptionsSelect(position_names)
            }
        }, [editOption, detections, position_names]);

        const correctRequest = (reason_value) => {
            const {Process, State} = event
            if (['wrong detections', 'no detection'].includes(reason_value) && !detections.length) {
                return dispatch(getDetections())
            }
            if (reason_value === 'location') {
                return dispatch(getPositionsName({process: Process, event: State}))
            }
        };

        const filtered_camera_names = useMemo(() => {
            return frames?.map(frame => frame["cctv_id"]).filter(camera => camera !== cameraOption);
        }, [frames, cameraOption]);

        const resetFormFields = () => form.resetFields();
        const handleCloseForm = () => closeForm();
        const handleCloseNewForm = () => {
            handleCloseForm()
            resetFormFields();
            dispatch(resetSomeValidationState('pairedEvent'))
            dispatch(returnSomeInitialState('processesNameObj'))
        }
        const onChangeSomeEventFormFiled = (fields) => form.setFieldsValue(fields);

        const handleResetNewForm = () => {
            resetFormFields();
            setCameraOption(cctv_id)
            onChangeSomeEventFormFiled({cctv_id})
            dispatch(resetSomeValidationState('pairedEvent'));
        }
        const updateInferenceTimeFieldsInForm = () => {
            return onChangeSomeEventFormFiled({
                timestamp: getTime(inference),
                correct_time: inference,
            })
        }
        //settings for add new event

        const handleGetProcess = (process) => {
            dispatch(getPairedEvent({process, event: paired_event, isFromPotentialEventTable: false}))
            if (event_names.length) {
                onChangeSomeEventFormFiled({event: '', positions: ''})
                dispatch(returnSomeInitialState('eventsNameObj'));
                dispatch(returnSomeInitialState('positionNamesObj'))
            }
        }
        const handleGetEventName = (event_name) => {
            dispatch(getPairedEvent({process, event: event_name, isFromPotentialEventTable: false}))
            if (position_names.length) {
                onChangeSomeEventFormFiled({positions: ''})
                dispatch(returnSomeInitialState('positionNamesObj'))
            }
        }
        const handleGetCameraName = (camera_name) => setCameraOption(camera_name)

        const handleOnDropVisibleOptions = (isVisible, isHasData, async_func, params = null) => {
            if (isVisible && isHasData) {
                if (params) {
                    return dispatch(async_func(params))
                } else {
                    return dispatch(async_func())
                }
            }
        }

        //settings for add new event

        const updateRequestsInformation = () => {
            dispatch(getEvents({turnaround_id, cctv_id}))
            dispatch(getIsValidFields(turnaround_id))
            dispatch(getTurnPotentialEvents(turnaround_id))
        }

        const onFinishAddNewEvent = async (data) => {
            const body = {
                ...data, state: data['event'], ts: inference / 1000, frame_id: '',
                bbox: Array.isArray(data.Bbox) ? data.Bbox : [],
                timestamp: getTime(data['timestamp']['$d']).format('YYYY-MM-DDTHH:mm:ss.ss[Z]'),
            }
            await dispatch(addEvent({turnaround_id, body}))
            await closeForm();
            await resetFormFields();
            await updateRequestsInformation()
        }

        //editForm functions

        const resetEditForm = () => {
            setEditOption({})
            updateInferenceTimeFieldsInForm()
            resetFormFields();
            dispatch(returnInitValidationState())
        }
        const handleJumpToTime = () => jumpToTime(form.getFieldsValue()['correct_time']);
        const onChangeRejectReason = (value, option) => {
            setEditOption(option);
            correctRequest(value)
        }

        const handleFinishValidateEvent = async (state) => {
            const {uuid, ...arg} = event
            if (state['correct_time']) state['wrong_time'] = Number(getTimestampInCorrectMilliSeconds(event['Frame_Id']));
            const settings = {turnaround_id, cctv_id, event: {...arg, validation: {finished: true, ...state}}}

            await dispatch(addValidation(settings))
            await handleCloseForm()
            await resetEditForm()
        }

        const isWhichFormShouldBeCleanIt = () => {
            if (isAddEventForm) handleCloseNewForm()
            if (isEditEventForm) handleCloseEditForm()
        }

        const handleCloseEditForm = () => {
            resetEditForm()
            handleCloseForm()
        }
        //editForm functions

        const defaultFormProps = {
            form,
            transformTime,
            initialState,
            classesList: eventFormClasses,
            close: isWhichFormShouldBeCleanIt
        }

        const newEventFormProps = {
            ...defaultFormProps,
            handleGetProcess,
            handleGetEventName,
            handleGetCameraName,
            onFinish: onFinishAddNewEvent,
            updateFields: updateInferenceTimeFieldsInForm,
            onDropVisibleOptions: handleOnDropVisibleOptions,
            clear: handleResetNewForm,
            spinnerLoading: isAddEvent,
            filtered_camera_names,
        }

        const editEventFormProps = {
            ...defaultFormProps,
            event,
            resetEditForm,
            jumpToTime: handleJumpToTime,
            onFinish: handleFinishValidateEvent,
            updateFields: updateInferenceTimeFieldsInForm,
            multifunctionalOptions: correctOptions,
            onChangeRejectReason,
            editOption,
            isDetections,
            isPositionsName,
            jumpToEvent,
        }

        useEffect(() => {
            const editRejectReason = defaultEventEditFormState?.find(({value}) => value === event['validation']?.reject_reason);

            if (isEditEventForm) {
                const {validation: {
                        reject_reason, correct_detection,
                        correct_location, comment, correct_time, occlusion
                    }} = event

                if (editRejectReason) {
                    setEditOption(editRejectReason);
                    correctRequest(editRejectReason.value)
                    onChangeSomeEventFormFiled({
                        reject_reason, correct_detection,
                        correct_location, comment,
                        occlusion,
                        timestamp: getTime(correct_time || inference),
                        correct_time: correct_time || inference,
                    })
                }

                if (!editRejectReason) {
                    updateInferenceTimeFieldsInForm()
                }
            }
        }, [event, isEditEventForm]);

        useEffect(() => {
            if (isAddEventForm) updateInferenceTimeFieldsInForm()
        }, [isAddEventForm])


        useEffect(() => {
            if (isFromPotentialEventTable) {
                onChangeSomeEventFormFiled({
                    process: process,
                    event: paired_event
                })
            }
        }, [isFromPotentialEventTable]);


        if (isAddEventForm) {
            return (
                <ModalWindow modalClasses={eventWrapperFormClasses} handleClose={isWhichFormShouldBeCleanIt}>
                    <AddEvent {...newEventFormProps}/>
                </ModalWindow>
            )
        }

        if (isEditEventForm) {
            return (
                <ModalWindow modalClasses={eventWrapperFormClasses} handleClose={isWhichFormShouldBeCleanIt}>
                    <EditEvent {...editEventFormProps}/>
                </ModalWindow>
            )
        }

        return null
    };

export default ValidationForms;