import React from 'react';
import {Button, Tag} from "antd";
import {PENCIL, SAVE, STOP} from "../icons/antd";

const LandmarksEditTableButtons =
    ({
         coordinateOne, coordinateTwo,
         onMarkedElem, onRefuseElem,
         isReadyToRemove, isDisabledEditBtn,
         onApproveChanges
    }) => {

    return (
        <>
            <Tag color={isReadyToRemove && "red"}>{coordinateOne}, {coordinateTwo}</Tag>
            { isReadyToRemove ?
                (
                    <>
                        <Button onClick={onRefuseElem} className={"break-remove-btn-item"} icon={STOP}/>
                        <Button onClick={onApproveChanges} className={"save-btn-item"} icon={SAVE}/>
                    </>
                )
                :
                <Button
                    onClick={onMarkedElem}
                    className={'start-remove-btn-item'}
                    disabled={isDisabledEditBtn}
                    icon={PENCIL}
                />
            }
        </>
    )
};

export default LandmarksEditTableButtons;