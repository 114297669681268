import {useEffect} from 'react';
import {Dropdown, Button} from "antd";
import useReadyState from "../../../hooks/useReadyState";
import useOnclickOutside from "react-cool-onclickoutside";
import {ELLIPSIS_POINTS} from "../../icons/antd";

const VisibilityDropdownOptionRow = ({title, selectedKey, menuItems, classes}) => {
    const {state: selectedKeys, onChangeReadyState: setSelectedKeys} = useReadyState(null);
    const {state: isDropdownVisible, onChangeReadyState: setIsDropdownVisible} = useReadyState(false);

    const dropdownRef = useOnclickOutside(() => {
        setIsDropdownVisible(false);
    }, {ignoreClass: ["popconfirm-trigger", "ant-popover", "ant-dropdown-menu-item"]});

    const menuProps = {items: menuItems, selectable: true, selectedKeys}

    const handleToggleVisible = () => setIsDropdownVisible((prev) => !prev);

    useEffect(() => {
        setSelectedKeys([`${selectedKey}`])
    }, [selectedKey]);

    return (
        <div className={classes}>
            <span>{title}</span>
            <div ref={dropdownRef}>
                <Dropdown
                    overlayStyle={{minWidth: "10rem", textAlign:"center"}}
                    trigger={['click']}
                    menu={menuProps}
                    arrow={{pointAtCenter: true}}
                    placement={'bottomLeft'}
                    open={isDropdownVisible}
                >
                    <Button type={'text'} icon={ELLIPSIS_POINTS} onClick={handleToggleVisible}/>
                </Dropdown>
            </div>
        </div>
    )
};

export default VisibilityDropdownOptionRow;