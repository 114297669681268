import {createAsyncThunk} from "@reduxjs/toolkit";
import {Download_Video_Socket} from "../../../services/Websocket";
import {handlePushFilePart, returnToDefDownloadFileState, saveMessage} from "../../slices/Websocket/DownloadWsFile";
import {
    getFeedbackNameSession,
    onChangeFinishPrepareState, onChangeIsDownloadFile, returnNotifDefState
} from "../../slices/Ui/Notification";
import {v4 as uuidv4} from 'uuid';
import {toast} from "react-toastify";
import {isJSON} from "../../../utils/constants/functions/analiza";

export const connectionToDownloadFileSession = createAsyncThunk(
    'ws_download_file/downloadFileSession',
    async (_, {getState}) => {
        const {systemSlice: {settingsUI}} = getState().api
        const path = `${settingsUI.WEBSOCKET_URL}?identifier=${uuidv4()}`

        await Download_Video_Socket.connection(path);
    })

export const getMessage = createAsyncThunk(
    'ws_download_file/getMessage',
    async (_, {dispatch}) => {
        await Download_Video_Socket.getMessage(async (message) => {
            const data = message?.data;

            const defDispatches = (feedbackValue) => {
                dispatch(getFeedbackNameSession(feedbackValue));
                dispatch(onChangeFinishPrepareState(true))
            }

            if (!isJSON(data)) {
                dispatch(handlePushFilePart(data))
            } else {
                const message = JSON.parse(data);

                if (message.status === "error") {

                    defDispatches("download_video")
                    dispatch(closeDownloadSession())
                    toast.error(message.data)
                }

                if (message.status === "finished") {
                    dispatch(saveMessage(message));
                    defDispatches("download")
                    dispatch(onChangeIsDownloadFile(true));
                }
            }
        })
    })

export const sendToDownloadFileMessageSession = createAsyncThunk(
    'ws_download_file/sendMessage',
    async (message, {dispatch}) => {
        await Download_Video_Socket.sendMessage(message)
    })

export const closeDownloadSession = createAsyncThunk('ws_download_file/closeDownloadSession',
    async (_, {dispatch}) => {
        await Download_Video_Socket.disconnect()
        dispatch(returnNotifDefState())
        dispatch(returnToDefDownloadFileState())
    })