import {Link} from "react-router-dom";
import {PERFORMANCE_LINKS} from "../Links";

const {fullDay, date_compare, min_max} = PERFORMANCE_LINKS;

export const performanceChildren = [
    {
        label: <Link to={fullDay}>Day</Link>,
        key: fullDay
    },
    {
        label: <Link to={min_max}>MinMax</Link>,
        key: min_max
    },
    {
        label: <Link to={date_compare}>DateCompare</Link>,
        key: date_compare
    }
]