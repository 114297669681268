export const defaultEventColumns = ({processes, getColumnSearchProps, isShowFilter}) => ([
    {
        title: "№",
        dataIndex: '№',
        width: '50px',
        render: (array, value, index) => <span>{index + 1}</span>
    },
    {
        title: 'Processes',
        dataIndex: 'process',
        key: 'process',
        filters: processes,
        filterSearch: false,
        onFilter: (value, record) => record['process'].indexOf(value) === 0,
    },
    {
        title: 'Events',
        dataIndex: 'event_name',
        key: 'event_name',
        ...getColumnSearchProps('event_name', isShowFilter),
    },
])
