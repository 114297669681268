import {useEffect} from 'react';
import UserForm from "../../components/Administration/UserForm";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import {editUser, getUser} from "../../store/asyncThunkFunctions/Api/UserRequests";
import {selectUser} from "../../store/selectors/Api/user";
import {ADMINISTRATION_LINKS} from "../../utils/routeUtils/Links";

const EditUser = () => {
    const dispatch = useDispatch();
    const navigation = useNavigate();
    const {login} = useParams();
    const {user} = useSelector(selectUser);

    const {users} = ADMINISTRATION_LINKS

    const onFinish = async (values) => {
        const {is_active} = values
        await dispatch(editUser({_id: login, body: {...values, is_active: is_active === 'active'}}))
        await navigation(users)
    }

    useEffect(() => {
        dispatch(getUser(login))
    }, [])

    return (
        <div className={'wrapperUserForm'}>
            <UserForm
                bodyClass={'bodyEditForm'}
                formName={'edit_user'}
                submitText={'Save'}
                defaultState={user}
                onFinish={onFinish}

            />
        </div>
    );
};

export default EditUser;