import {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {changeEditCameraData, initializeGateName} from "../../store/slices/Api/newGate";
import {getStorageValue} from "../../utils/helpers/cacheHelpers";
import SwitchForm from "./SwitchForm";
import {editCameraById} from "../../store/asyncThunkFunctions/Api/CamerasRequests";
import SkeletonStepTwoFormAddGate from "../Loadings/SkeletonStepTwoFormAddGate";
import {FORM_GATE_EDIT_CAMERA_LINK} from "../../utils/routeUtils/Links";
import {CONFIGURATION_LINKS} from "../../utils/routeUtils/Links"
import {selectEditCamera} from "../../store/selectors/Api/formAddGate";

const EditCamera = () => {
    const {name, id} = useParams()
    const dispatch = useDispatch()
    const navigation = useNavigate()
    const {camera, isGetCamera} = useSelector(selectEditCamera);

    const defaultValues = getStorageValue('camera', camera);

    const onEditCamera = (data) => {
        dispatch(changeEditCameraData({...camera, ...data}))
        navigation(FORM_GATE_EDIT_CAMERA_LINK(name, id, 'step_three'))
    }

    const onSubmit = async () => {
        const {gateCameras} = CONFIGURATION_LINKS
        const {cctv_id, top_left, bottom_right, cctv_params, ...rest} = camera
        const crop = top_left && bottom_right ? `${top_left}, ${bottom_right}`.split(',').map(number => Number(number)) : []

        const arg = {old: id, new: cctv_id, _id: name, camera: {...rest, crop, cctv_id, cctv_params: JSON.parse(cctv_params)}}
        await dispatch(editCameraById(arg));
        navigation(gateCameras)
    }

    useEffect(() => {
        // You should solve problem with get date if you open edit form by link
        // const getCameraStorage = sessionStorage.getItem('camera')
        // if (getCameraStorage) dispatch(getCameraRequest({name, id}));
        dispatch(initializeGateName(name))
    }, []);


    return (
        <div className={'wrapperEditCamera'}>
            {isGetCamera
                ? <SkeletonStepTwoFormAddGate/>
                : <SwitchForm defaultValues={defaultValues} submitFunc={onEditCamera} onSendRequest={onSubmit}/>}
        </div>
    );
};

export default EditCamera;    