import {useLayoutEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import air_axios_settings from "../services/airAxios";
import axios from "axios";
import {useLocation, useNavigate} from "react-router-dom";
import RefreshToken from "./RefreshToken";
import {toast} from "react-toastify";
import {selectAuthUser} from "../store/selectors/Api/auth";
import {getCashProfile} from "../utils/helpers/cacheHelpers";


const AxiosInterceptor = ({children}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const {isAuth} = useSelector(selectAuthUser);

    let isFetchingToken = false, subscribers = [];
    let requests = [];

    const onAccessTokenFetched = (token) => subscribers = subscribers.filter(cb => cb(token));
    const addSubscriber = (cb) => subscribers.push(cb);

    useLayoutEffect(() => {
        const requestInterceptor = air_axios_settings.interceptors.request.use(config => {
            const profile = getCashProfile();
            if (!config.headers['authorization']) {
                config.headers.authorization = profile.token
            }

            return config
        }, (error) => Promise.reject(error))

        const responseInterceptor = air_axios_settings.interceptors.response.use(
            response => {
                return response
            },
            async (error) => {
                if (axios.isCancel(error)) {
                    console.log('Request canceled:', error.message);
                    return Promise.reject(error)
                }

                const {config: originalRequest, response: {status}} = error

                if (status >= 500) toast.error(`error:${status}, please try again`);
                // if (status === 404) toast.error(`error: ${status}, Not Found`);

                if (status === 401 || status === 403) {
                    console.log('Your access_token is dead', error.response['status']);
                    if (!isFetchingToken) {
                        isFetchingToken = true;
                        RefreshToken(dispatch, navigate, location).then(data => {
                            onAccessTokenFetched(data['token'])
                            isFetchingToken = false
                        })
                    }
                    return new Promise((resolve) => {
                        addSubscriber(token => {
                            originalRequest.headers['authorization'] = token
                            resolve(axios(originalRequest))
                        })
                    })
                }
                return Promise.reject(error)
            }
        )
        return () => {
            air_axios_settings.interceptors.response.eject(responseInterceptor);
            air_axios_settings.interceptors.request.eject(requestInterceptor);
        }
    }, [isAuth])

    return children
};

export default AxiosInterceptor;