import {useSelector} from "react-redux";
import {Button, Card, DatePicker, Form, Input, Spin} from "antd";
import CustomSelect from "../../ANTD/CustomSelect";
import {getEventsName, getPositionsName, getProcessesName} from "../../../store/asyncThunkFunctions/Api/EventsRequest";
import {CLEAR, CLOSE} from "../../icons/antd";
import {rules} from "../../../utils/constants/objects/validationFormRules";
import {getFidsFrames} from "../../../store/asyncThunkFunctions/Api/TurnaroundRequests";
import {useParams} from "react-router-dom";
import {selectEventNames, selectPositionNames, selectProcessNames} from "../../../store/selectors/Api/events";
import {selectPairedEvent} from "../../../store/selectors/Api/validation";
import {selectFrameState} from "../../../store/selectors/Api/turnaround";

const AddEvent =
    ({
         form, initialState, classesList, updateFields,
         handleGetEventName, handleGetProcess,
         onFinish, spinnerLoading, onDropVisibleOptions, transformTime, clear, close,
         filtered_camera_names, handleGetCameraName,
    }) => {

    const {turnaround_id} = useParams();
    const {processes_name, isProcessesName} = useSelector(selectProcessNames);
    const {event_names, isEventsName} = useSelector(selectEventNames);
    const {position_names, isPositionsName} = useSelector(selectPositionNames);
    const {frames} = useSelector(selectFrameState);
    const {process, event: paired_event} = useSelector(selectPairedEvent);

    const handleOnDropdownVisibleProcesses = (isVisible) => {
        return onDropVisibleOptions(isVisible, !processes_name.length, getProcessesName)
    }
    const handleOnDropdownVisibleEvents = (isVisible) => {
        return onDropVisibleOptions(isVisible, !event_names.length, getEventsName, process)
    }
    const handleOnDropdownVisiblePositions = (isVisible) => {
        const position_params = {process: process, event: paired_event}
        return onDropVisibleOptions(isVisible, !position_names.length, getPositionsName, position_params)
    }
    const handleOnDropdownVisibleCameras = (isVisible) => {
        return onDropVisibleOptions(isVisible, !frames.length, getFidsFrames, turnaround_id)
    }

    return (
        <div className={classesList}>
            <Card title={'New Event'} className={'newForm__cardBlock'}>
                <Button className={'newForm__closeFormButton'} onClick={close} title={'close form'} disabled={spinnerLoading}>{CLOSE}</Button>
                <Spin spinning={spinnerLoading} tip="Loading...">
                    <Form name={'new event'} onFinish={onFinish} initialValues={initialState} form={form} className={'cardBlockForm'}>
                        <CustomSelect
                            isFormItem={true}
                            data={processes_name}
                            onChange={handleGetProcess}
                            loading={isProcessesName}
                            onDropdownVisibleChange={handleOnDropdownVisibleProcesses}
                            formItemElemSettings={{name: 'process', label: 'Process', rules: rules['process']}}
                        />
                        <CustomSelect
                            isFormItem={true}
                            data={event_names}
                            disabled={!process}
                            onChange={handleGetEventName}
                            loading={isEventsName}
                            onDropdownVisibleChange={handleOnDropdownVisibleEvents}
                            formItemElemSettings={{name: 'event', label: 'Event', rules: rules['event']}}
                        />
                        <CustomSelect
                            isFormItem={true}
                            data={position_names}
                            loading={isPositionsName}
                            disabled={!process || !paired_event}
                            onDropdownVisibleChange={handleOnDropdownVisiblePositions}
                            formItemElemSettings={{name: 'positions', label: 'Positions'}}
                        />
                        <CustomSelect
                            isFormItem={true}
                            data={filtered_camera_names}
                            onDropdownVisibleChange={handleOnDropdownVisibleCameras}
                            onChange={handleGetCameraName}
                            formItemElemSettings={{name: 'cctv_id', label: 'Camera', rules: rules['cctv_id']}}
                            disabled={!process || !paired_event}
                        />

                        <div className={'cardBlockForm__timestampBlock'}>
                            <Form.Item name={'correct_time'} label={'Timestamp'} rules={rules['correct_time']}>
                                <Input placeholder={'347869543'} disabled/>
                            </Form.Item>
                            <Form.Item name={'timestamp'} rules={rules['correct_time']}>
                                <DatePicker showTime format={transformTime} disabled/>
                            </Form.Item>
                            <Form.Item>
                                <Button onClick={updateFields} title={'Update -> Date/Timestamp'}>Update</Button>
                            </Form.Item>
                        </div>

                        <footer className={'cardBlockForm__footer'}>
                            <Form.Item shouldUpdate>
                                {({isFieldsTouched}) => (
                                        <Button
                                            type={'primary'} disabled={!isFieldsTouched(['event'])} onClick={clear}
                                        >Reset {CLEAR}</Button>
                                    )}
                            </Form.Item>
                            <Form.Item shouldUpdate>
                                {({isFieldTouched}) => (
                                    <Button
                                        type="primary"
                                        disabled={!isFieldTouched('process')}
                                        htmlType="submit"
                                    >Finish</Button>
                                )}
                            </Form.Item>
                        </footer>
                    </Form>
                </Spin>

            </Card>
        </div>
    );
};

export default AddEvent;