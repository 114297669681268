import {useRef, useState} from "react";
import {filterIcon, onFilter, onFilterVisibleChange, returnHighLighter} from "../utils/tableUtils/tableSettings";
import SearchComponent from "../components/Turnarounds/SearchComponent";
import {Form} from "antd";

export default function () {
    const [form] = Form.useForm(); // Table GatesCameras page
    const [editingKey, setEditingKey] = useState(''); // Table GatesCameras page
    const [searchText, setSearchText] = useState(''); // Table Turnarounds page
    const [searchedColumn, setSearchedColumn] = useState(''); // Table Turnarounds page
    const searchInput = useRef(null); // Table Turnarounds page

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };

    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, isShowFilter = true) => {
        if (isShowFilter) {
            return ({
                filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
                    <SearchComponent setSelectedKeys={setSelectedKeys} selectedKeys={selectedKeys} confirm={confirm}
                                     clearFilters={clearFilters} searchInput={searchInput} dataIndex={dataIndex}
                                     search={handleSearch} reset={handleReset}
                    />
                ),
                filterIcon: (filtered) => filterIcon(filtered),

                onFilter: (value, record) => onFilter(record, dataIndex, value),

                onFilterDropdownOpenChange: (visible) => onFilterVisibleChange(visible, searchInput),

                render: (text) => searchedColumn === dataIndex ? returnHighLighter(text, searchText) : (text),
            });
        }
    }

    const isEditing = (value) => value === editingKey;// Table GatesCameras

    const edit = (initialFormValues, editValue) => { // Table GatesCameras
        form.setFieldsValue(initialFormValues);
        setEditingKey(editValue);
    };

    const cancel = () => setEditingKey(''); // Table GatesCameras

    const save = async (callback) => { // Table GatesCameras
        try {
            const row = await form.validateFields();
            await callback(row)
            setEditingKey('');
        } catch (errInfo) {
            console.log('Validate Failed:', errInfo);
        }
    };

    const defaultEditPropsConfig = {isEditing, save, cancel, editingKey, edit}

    const mergedColumns = (propsConfig, cb, editKey, onCellProps) => cb(propsConfig)?.map((col) => {
        if (!col.editable) return col;

        return {
            ...col,
            onCell: (record) => {
                const obj = {
                    record,
                    inputType: col.dataIndex,
                    dataIndex: col.dataIndex,
                    title: col.title,
                    editing: isEditing(record[editKey]),
                    ...onCellProps
                }
                if (col.editableKey) obj.editableKey = col.editableKey
                return obj
            }
        };
    }); // Table GatesCameras


    return {getColumnSearchProps, mergedColumns, form, isEditing, save, cancel, editingKey, edit, defaultEditPropsConfig}
}