import {useEffect, useMemo, useState} from "react";
import {Avatar, Badge, Menu, Popover, Switch} from "antd";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import logo from '../../assets/logo.jpg';
import {useDispatch, useSelector} from "react-redux";
import {changeTimeZone, getLocalStorageTimeZone} from "../../store/slices/Ui/Geotime";
import {handleLogout} from "../../store/slices/Api/Auth";
import {USER} from "../icons/antd";
import PopoverAvatarContent from "./PopoverAvatarContent";
import {WithoutViewerValidator} from "../../utils/routeUtils/Roles";
import {selectProfile} from "../../store/selectors/Api/auth";
import {selectSettingsUi} from "../../store/selectors/Api/system";
import {selectTimeZone} from "../../store/selectors/Ui/TimeZone";
import {selectAlertAmount} from "../../store/selectors/Websocket/alerts";
import {primaryNavigationMenu} from "../../utils/routeUtils/navigation/primaryNavigationMenu";
import {MAIN_LINKS} from "../../utils/routeUtils/Links";

const Header = () => {
    const {pathname} = useLocation()
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const settingsUI = useSelector(selectSettingsUi);
    const user = useSelector(selectProfile);
    const timeZone = useSelector(selectTimeZone);
    const alert_amount = useSelector(selectAlertAmount);

    const [open, setOpen] = useState(false);
    const {profile, login} = MAIN_LINKS


    const privetLinksV2 = useMemo(() => {
        const person_role = user['role']
        return primaryNavigationMenu.map(item => {
            if (item.children) {
                return {...item, children: item.children?.filter(child => {
                    return child.roles ? child.roles.includes(person_role) : child
                })}
            }
            return  item
        }).filter(({roles}) => roles.includes(person_role))
    }, [user])

    const handleOpenChange = (newOpen) => setOpen(newOpen);
    const handleOpenPopover = () => setOpen(true);

    const handleGoToProfile = () => {
        navigate(profile);
        setOpen(false);
    }

    const logout = () => {
        dispatch(handleLogout())
        navigate(login)
    }

    const handleTimeZone = (checked) => {
        const check = checked ? 'Locally' : 'GMT'
        dispatch(changeTimeZone(check))
    }

    const checkSwitchBoolean = () => {
        const Time_Zone = localStorage.getItem('Time_Zone')
        if (Time_Zone) {
            return Time_Zone === 'Locally'
        }
    }

    const getTimeZoneCash = () => {
        const TimeZone = localStorage.getItem('Time_Zone')
        return dispatch(getLocalStorageTimeZone(!TimeZone ? 'Locally' : TimeZone))
    }

    useEffect(() => {
        if (timeZone) {
            localStorage.setItem('Time_Zone', timeZone)
        }
    }, [timeZone])

    useEffect(() => {
        getTimeZoneCash()
    }, []);


    return (
        <header className={'main_header'}>
            <div id="block_container">
                <div className={'main_header__wrapperLeftSidebar'}>
                    <div className={'main_header__logo'}>
                        <div id="first_block">
                            <img src={logo} width="197" height="58" alt="logo"/>
                        </div>
                        <span className={'airportName'}>IATA: {settingsUI['AIRPORT_IATA']}</span>
                    </div>
                    <Menu
                        items={privetLinksV2}
                        mode="horizontal"
                        className={'headerNavigation'}
                        selectedKeys={[pathname]}
                    />

                </div>
                <div className={'main_header__wrapperRightSideBar'}>
                    <div className={'wrapperAlertLink'}>
                        {
                            WithoutViewerValidator.includes(user['role']) &&
                            <NavLink to="/alerts" className="link">
                                <Badge count={alert_amount} showZero text={<span className={'badge_alert_text'}>Alerts</span>}/>
                            </NavLink>
                        }
                    </div>
                    <div className={'wrapperSwitchTime'}>
                        <span className={'textSwitchTime'}>Time Zone</span>
                        <Switch
                            defaultChecked
                            checked={checkSwitchBoolean()}
                            onChange={handleTimeZone}
                            checkedChildren={'Local'}
                            unCheckedChildren={'GMT'}
                        />
                    </div>

                    <div className={'avatarIconProfileBlock'}>
                        <Popover
                            className={'avatarHeaderPopover'}
                            placement="bottomRight"
                            content={<PopoverAvatarContent
                                logout={logout} profile={handleGoToProfile}
                                _id={user['_id']
                                }
                                isAccountPage={pathname !== '/profile'}/>}
                            trigger="click" open={open}
                            onOpenChange={handleOpenChange}
                            overlayStyle={{top: '50px'}}
                        >
                            <Avatar size={'large'} icon={USER} onClick={handleOpenPopover}/>
                        </Popover>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
