import {Link} from "react-router-dom";
import {All, WithoutValidator, WithoutViewerValidator} from "../Roles";
import {CaretDownOutlined} from "@ant-design/icons";
import {MAIN_LINKS} from "../Links";
import {systemChildren} from "./systemChildren";

const {live_view, exports, turnarounds} = MAIN_LINKS;

export const primaryNavigationMenu = [
    {
        label: <Link to={live_view}>Live view</Link>,
        key: live_view,
        roles: WithoutValidator
    },
    {
        label: <Link to={turnarounds}>Turnarounds</Link>,
        key: turnarounds,
        roles: All
    },
    {
        label: <Link to={exports}>Export</Link>,
        key: exports,
        roles: WithoutValidator
    },
    {
        label: "System",
        key: "system",
        roles: WithoutViewerValidator,
        icon: <CaretDownOutlined />,
        children: systemChildren
    }
]