import axios from "axios";
import {handleLogout, handleUpdateUser} from "../store/slices/Api/Auth";
import {setLocalStorageValue} from "../utils/helpers/cacheHelpers";
import {baseUrl} from "../utils/constants/variables/api";
import {LOGIN} from "../utils/routeUtils/Paths";

export default async function RefreshToken(dispatch, navigate, location) {
    try {
        const {data} = await axios(`${baseUrl}/auth/refresh`, {withCredentials: true});
        const {token, role, _id, is_active} = data
        const res = {token, role, _id, is_active}
        setLocalStorageValue("profile", JSON.stringify(res));
        dispatch(handleUpdateUser(res));
        return data
    } catch (error) {
        const {data, status} = error?.response
        if (status === 401 ||
            status === 403 ||
            (status === 400 && (data.includes('Invalid token')))) {
            dispatch(handleLogout())
            navigate(LOGIN, {replace: true, state: {from: location}})
        }
        console.log(error.response, 'ERROR')
        throw new Error(error)
    }
}