export const userRoles = [
    {text: 'admin', value: 'admin'},
    {text: 'viewer', value: 'viewer'},
    {text: 'developer', value: 'developer'},
    {text: 'validator', value: 'validator'}
];

export const userStatuses = [
    {text: 'active', value: 'active'},
    {text: 'inactive', value: 'inactive'}
]