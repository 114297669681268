
export const isEqual = (data1, data2) => JSON.stringify(data1) === JSON.stringify(data2);

export const isJSON = (data) => {
    try {
        JSON.parse(data);
        return true;
    } catch (e) {
        return false;
    }
}