import {useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {yupCheckName} from "../../../utils/helpers/yupGateFormHelper";
import {initializeGateName, toggleStorage} from "../../../store/slices/Api/newGate";
import {useNavigate} from "react-router-dom";
import {ARROW_RIGHT} from "../../icons/antd";
import {Button, Form, Input, Select} from "antd";
import {getKnownGateNames} from "../../../store/asyncThunkFunctions/Api/GateRequest";
import {FORM_GATE_LINKS} from "../../../utils/routeUtils/Links";
import {selectKnownGateNames} from "../../../store/selectors/Api/gates";

const First = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const {knownLists_id, isLoadingKnown_id} = useSelector(selectKnownGateNames);
    const [form] = Form.useForm();

    const {step_two} = FORM_GATE_LINKS
    const onFinish = (form_state) => {
        dispatch(initializeGateName(form_state['_id']))
        navigate(step_two)
        dispatch(toggleStorage(true))
    }

    const filteredNames = useMemo(() => (_id) => {
        return knownLists_id.filter(({is_configured}) => is_configured).map(({gate_id}) => gate_id)
    },[knownLists_id]);

    const yupSync = {
        async validator({ field }, value) {
            await yupCheckName(filteredNames(field)).validateSyncAt(field, { [field]: value });
        },
    };


    const onDropVisibleNamesList = (isVisible) => {
        if (isVisible) dispatch(getKnownGateNames())
    }
    const onSelect_Id = (option) => form.setFieldsValue({_id: option})

    const listOfGateNameOptions = useMemo(() => {
        return knownLists_id.map(({gate_id, is_configured}, index) => {
            return {
                label: gate_id,
                value: gate_id,
                className: is_configured && "configured_gate_name_option",
                disabled: is_configured
            }
        })
    }, [knownLists_id])

    return (
        <Form className={'formName'} title={'Gate Name'} form={form} onFinish={onFinish}>
            <header><h1>Gate Name</h1></header>
            <section>
                <Form.Item name={'selected_gate_name'}>
                    <Select
                        placeholder={'select gate name'}
                        onDropdownVisibleChange={onDropVisibleNamesList}
                        options={listOfGateNameOptions}
                        loading={isLoadingKnown_id}
                        onSelect={onSelect_Id}
                    />
                </Form.Item>
                <Form.Item name={'_id'} rules={[yupSync]}>
                    <Input placeholder={'AAA2'}/>
                </Form.Item>
            </section>
            <footer>
                <Button
                    className={'defaultButton'}
                    type={'primary'}
                    size={'large'}
                    color={'#1f618d'}
                    icon={ARROW_RIGHT}
                    htmlType={'submit'}>Next</Button>
            </footer>
        </Form>
    );
};

export default First;