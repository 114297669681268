import {useEffect} from 'react';
import {Route, Routes, useParams} from "react-router-dom";
import Second from "./Steps/Second";
import Third from "./Steps/Third";
import Finish from "./Steps/Finish";
import First from "./Steps/First";
import {useDispatch} from "react-redux";
import {changeEditCameraData} from "../../store/slices/Api/newGate";
import {FORM_GATE} from "../../utils/routeUtils/Paths"

const SwitchForm = ({defaultValues, submitFunc, onSendRequest}) => {
    const {id} = useParams();
    const dispatch = useDispatch();
    const {step_one, step_two, step_three, preview} = FORM_GATE

    useEffect(() => {
        const oldDataCamera = JSON.parse(sessionStorage.getItem('camera'))

        if (id && oldDataCamera) dispatch(changeEditCameraData(oldDataCamera))
    }, [id]);

    return (
        <Routes>
            <Route path={'/'} element={<First/>}/>
            <Route path={step_one} element={<First/>}/>
            <Route path={step_two} element={<Second defaultValues={defaultValues} submitFunc={submitFunc}/>}/>
            <Route path={step_three} element={<Third defaultValues={defaultValues}/>}/>
            <Route path={preview} element={<Finish onSendRequest={onSendRequest}/>}/>
        </Routes>
    );
};

export default SwitchForm;