export const LOGIN = '/login'

export const STANDARD = '/';
export const fallbackPath = '/*'
export const homeLink = '/liveView'

export const MAIN_PATHS = {
    liveView: "liveView",
    live: "live/:gate_id/:cctv_id",
    turnarounds: "turnarounds",
    turnaround: "turnaround/:turnaround_id/:gate_id/:cctv_id",
    exports: "export",
    system: "system/*",
    profile: "profile",
    alerts: "alerts",
    formGate: "formGate/*",
}


export const SYSTEM_PATHS = {
    mock: "mock",
    configuration: "configuration/*",
    performance: "performance/*",
    administration: "administration/*",
}

export const CONFIGURATION_PATHS = {
    gates_cameras: "gates_cameras",
    import_export: "import_export",
    presentation: "presentation",
    events: "events",
    objects: "objects",
}


// old version

export const FORM_GATE = {
    add: "gate/:name/add_cameras/*",
    edit: "gate/:name/edit_camera/:id/*",
    step_one: "step_one",
    step_two: "step_two",
    step_three: "step_three",
    preview: "preview"
}