import {useEffect, useMemo, useRef} from 'react';
import {Button, Card, Form, Input, Select} from "antd";
import {defDownloadFileState} from "../../utils/constants/objects/formInitialStates";
import {onChangeIsOpenSession} from "../../store/slices/Websocket/General";
import {handleGetDownloadFileName, handleOnChangeIsDisabled} from "../../store/slices/Websocket/DownloadWsFile";
import {useDispatch, useSelector} from "react-redux";
import {selectSettingsUi} from "../../store/selectors/Api/system";
import {createOptionsSelect} from "../../utils/helpers/dataHelpers/arrayObjectModifiers";
import {selectCctvIds, selectGateNames} from "../../store/selectors/Api/gates";
import {getListOfCctvID, getListOfGateNames} from "../../store/asyncThunkFunctions/Api/GateRequest";
import {
    selectDownloadFileName,
    selectDownloadIsDisable,
    selectDownloadWsConnection
} from "../../store/selectors/Websocket/download";
import {
    connectionToDownloadFileSession,
    getMessage, sendToDownloadFileMessageSession
} from "../../store/asyncThunkFunctions/Weboscket/DownloadFileMessages";
import {getPrepareToast} from "../../store/slices/Ui/Notification";
import {toast} from "react-toastify";
import {selectIsOpenWsSession} from "../../store/selectors/Websocket/general";
import {selectProfile} from "../../store/selectors/Api/auth";

const Video = () => {
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const {AIRPORT_IATA} = useSelector(selectSettingsUi);
    const {gatesName} = useSelector(selectGateNames);
    const {cctvIds} = useSelector(selectCctvIds);
    const isDisableDownloadButton = useSelector(selectDownloadIsDisable);
    const isOpenSession = useSelector(selectIsOpenWsSession);
    const {isConnected} = useSelector(selectDownloadWsConnection);
    const download_file_name = useSelector(selectDownloadFileName);
    const profile = useSelector(selectProfile);

    const download_frame_params = useRef(null);
    const empty_rule = {required: true, message: "Mustn't be empty"};

    const getGateNameOptions = useMemo(() => createOptionsSelect(gatesName), [gatesName]);
    const getCctvIdOptions = useMemo(() => createOptionsSelect(cctvIds), [cctvIds]);

    const onChangeGateName = (_id) => {
        dispatch(getListOfCctvID({_id}))
        form.setFieldsValue({cctv_id: ''});
    }

    const onDropVisibleChangeGateName = (isOpen) => {
        if (isOpen) dispatch(getListOfGateNames());
    }

    const handleDownloadImages = async (params) => {
        const {gate_id, cctv_id, start, end} = params
        const file_name = `${AIRPORT_IATA}--${gate_id}--${cctv_id}--${start}--${end}`

        download_frame_params.current = {cctv_id, start: +start, end: +end}

        dispatch(onChangeIsOpenSession(true));
        dispatch(handleOnChangeIsDisabled(true));
        dispatch(handleGetDownloadFileName(file_name));
        console.log('onopen');
    };


    useEffect(() => {
        if (isOpenSession && !isConnected) {
            dispatch(connectionToDownloadFileSession());
            dispatch(getMessage())
            dispatch(onChangeIsOpenSession(false))
        }

    }, [isOpenSession, isConnected]);

    useEffect(() => {
        if (download_file_name && isConnected) {
            dispatch(sendToDownloadFileMessageSession({
                params: {...download_frame_params.current, token: profile?.token}, type: 'download_video_blob'
            }))
            dispatch(getPrepareToast({
                toast_name: 'download_video',
                toast_id: toast.loading('Prepare for download video', {closeButton: true})
            }))
        }
    }, [download_file_name, isConnected]);


    return (
        <Card title={'Video'} className={'downloadSection__systemCardBlock imagesCardDataBlock'}>
            <Form style={{width: '250px', margin: '0 auto 10px auto'}} onFinish={handleDownloadImages}
                  form={form} initialValues={defDownloadFileState}>
                <section>
                    <Form.Item name={'gate_id'} rules={[empty_rule]}>
                        <Select
                            placeholder={'select gate name'}
                            options={getGateNameOptions}
                            onChange={onChangeGateName}
                            onDropdownVisibleChange={onDropVisibleChangeGateName}
                        />
                    </Form.Item>
                    <Form.Item name={'cctv_id'} rules={[empty_rule]}>
                        <Select placeholder={'select cctv_id'} options={getCctvIdOptions} disabled={!gatesName.length}/>
                    </Form.Item>
                    <Form.Item name={'start'} rules={[empty_rule, {pattern: /^\d{13}$/, message: 'Incorrect format'}]}>
                        <Input placeholder={'from -> timestamp'}/>
                    </Form.Item>
                    <Form.Item name={'end'} rules={[empty_rule, {pattern: /^\d{13}$/, message: 'Incorrect format'}]}>
                        <Input placeholder={'to -> timestamp'}/>
                    </Form.Item>
                </section>
                <footer>
                    <Form.Item shouldUpdate>
                        {({getFieldsError}) => {
                            const isValidated = getFieldsError()?.some(({errors}) => errors.length > 0);
                            return (
                                <Button
                                    htmlType={'submit'}
                                    disabled={isValidated || !!isDisableDownloadButton}
                                >Download</Button>
                            )
                        }}
                    </Form.Item>
                </footer>
            </Form>
        </Card>
    );
};

export default Video;