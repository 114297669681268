import {useDispatch} from "react-redux";
import {
    editEventRequired,
    editEventVisibility,
    getEventModes
} from "../../store/asyncThunkFunctions/Api/EventsRequest";
import {useCallback} from "react";
import {modifiedVisibilityObjects} from "../../utils/helpers/system/visibilityHelper";

export default function ({gate, camera, events, onUpdate}) {
    const dispatch = useDispatch();

    const onGetEventVisibility = () => dispatch(getEventModes({gate, camera}));

    const onUpdateVisibility = ({event_name, process}) => {
        onUpdate( async ({visibility}) => {
            const events_visibility = [{event_name, process, visibility}]
            await dispatch(editEventVisibility({gate, camera, events_visibility}))
            await onGetEventVisibility()
        })
    }

    const onUpdatedEventModes = useCallback( async (fieldToggle, requestName) => {
        const updatedAllRequired = {gate, event_name: "*", process: "*", required: fieldToggle}
        const updatedAllVisibility = {gate, camera, events_visibility: modifiedVisibilityObjects(events, fieldToggle)}

        if (requestName === "visibility") await dispatch(editEventVisibility(updatedAllVisibility))
        else await dispatch(editEventRequired(updatedAllRequired))

        await onGetEventVisibility()
    }, [gate, camera, events]);

    return {onUpdatedEventModes, onUpdateVisibility}
}