import {useState} from 'react';
import {Button, Card, Upload, message} from "antd";
import {useDispatch} from "react-redux";
import {getBackupFile, sendRestoreFile} from "../../store/asyncThunkFunctions/Api/SystemRequests";
import {UploadOutlined} from "@ant-design/icons";

const ImportExport = () => {
    const dispatch = useDispatch();
    const [uploadFile, setUploadFile] = useState([]);

    const handleReadFile = (file) => {
        const reader = new FileReader();
        reader.readAsText(file['file']);
        reader.onload = (e) => dispatch(sendRestoreFile(e.target.result))
    }

    const uploadSettings = {
        name: 'file',
        fileList: uploadFile,
        beforeUpload: (file) => setUploadFile([file]),
        customRequest: (file) => handleReadFile(file),
        async onChange(info) {
            if (info.file.status !== "uploading") {
                console.log(info.file, info.fileList);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    return (
        <div>
            <Card title={'Download'}>
                <section className={'backupSection'}>
                    <Button onClick={() => dispatch(getBackupFile())}>Backup</Button>
                </section>
            </Card>
            <Card title={'Upload'}>
                <section className={'restoreSection'}>
                    <Upload {...uploadSettings}>
                        <Button icon={<UploadOutlined />}>Click to Upload</Button>
                    </Upload>
                </section>
            </Card>
        </div>
    );
};

export default ImportExport;