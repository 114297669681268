import {objectEventDropDownMenuItems} from "../../helpers/system/objectEventDropdownHelpers";
import VisibilityDropdownOptionRow from "../../../components/System/Common/VisibilityDropdownOptionRow";
import EmptyField from "../../../components/Customs/EmptyField";
import EditableVisibility from "../../../components/System/Common/EditableVisibility";


export const monitoringObjectColumns =
    ({
         getColumnSearchProps, isShowFilter, sysEventAsyncHandler,
         cancel, save, editingKey, edit, isEditing, isVisibility
    }) => ([
    {
        title: "№",
        dataIndex: '№',
        width: '50px',
        render: (array, value, index) => <span>{index + 1}</span>
    },
    {
        title: 'Events',
        dataIndex: 'obj_name',
        key: 'obj_name',
        ...getColumnSearchProps('obj_name', isShowFilter),
    },
    {
        title: () => {
            const menuItems = objectEventDropDownMenuItems(
                {
                    placement: "left", value: "Monitor all",
                    onConfirm: () => sysEventAsyncHandler(true)
                },
                {
                    placement: "left", value: "Ignore all",
                    onConfirm: () => sysEventAsyncHandler(false)
                }
            )
            return (
                <VisibilityDropdownOptionRow
                    classes={"monitoring_btn_edit_container__dropdown_title_block"}
                    title={"Positions Monitoring"}
                    menuItems={menuItems}
                    selectedKey={isVisibility}
                />
            )
        },
        dataIndex: 'visibility',
        editableKey: "positions",
        key: 'visibility',
        editable: true,
        className: "monitoring_btn_edit_container",
        render: (visibility, event) => {
            return (
                <EmptyField isEmpty={!visibility} message={'N/A'} tagColor={"#006d75"}>
                    <EditableVisibility
                        isEdited={isEditing(event?.uuid)}
                        event={event}
                        editingKey={editingKey}
                        onEdit={edit}
                        onCancel={cancel}
                        onSave={save}
                    />
                </EmptyField>
            )
        },
    },
])