import {createSlice} from "@reduxjs/toolkit";
import {sendRestoreFile, getUiSettings, editUiSettings} from "../../asyncThunkFunctions/Api/SystemRequests";

const initialState = {
    settingsUI: {
        TIMELINE_STEP_VALUE: null,
        TIMELINE_STEPS: null,
        USE_BASE64_IMAGE: false,
        TIMERS: {},
        CANVAS_LAYERS: {
            IMAGE: true,
            KEYPOINTS: false,
            DETECTIONS: false,
            TRACKERS: false,
            CLEARANCE_ZONE: false,
            VESTS: false,
            PROJECTION: false,
            EVENT_BBOX: false,
        },
        AIRPORT_IATA: '',
        WEBSOCKET_URL: '',
    },
    isSettingsLoading: false,
    isEditSettingsLoading: false,
    settingsError: null,
}


const systemSlice = createSlice({
    name: 'system',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getUiSettings.pending, (state) => {
            state.isSettingsLoading = true
            state.settingsError = null
        })
            .addCase(getUiSettings.fulfilled, (state, {payload}) => {
                state.settingsUI = payload
                state.isSettingsLoading = false
            })
            .addCase(getUiSettings.rejected, (state, {error}) => {
                state.isSettingsLoading = false
                state.settingsError = error.message
            })

        builder.addCase(editUiSettings.pending, state => {
            state.isEditSettingsLoading = true
            state.settingsError = null
        })
            .addCase(editUiSettings.fulfilled, (state, {payload}) => {
                state.settingsUI = payload
                state.isEditSettingsLoading = false
            })
            .addCase(editUiSettings.rejected, (state, {error}) => {
                state.isEditSettingsLoading = false
                state.settingsError = error.message
            })

        builder.addCase(sendRestoreFile.pending, (state) => {
            state.isSettingsLoading = true
            state.settingsError = null
        })
            .addCase(sendRestoreFile.fulfilled, (state, {payload}) => {
                state.isSettingsLoading = false;
            })
            .addCase(sendRestoreFile.rejected, (state, {error}) => {
                state.isSettingsLoading = false
                state.settingsError = error['message']
            })
    }
})


export default systemSlice.reducer