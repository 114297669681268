const required = true

const messChooseGen = (text) => `Please chose the ${text}`
const messWriteNumbGen = (text) => `Please write the number of ${text}`
const messAddSomeGen = (text) => `Please add the ${text}`

export const rules = {
    process: [{required, message: messChooseGen('process')}],
    event: [{required, message: messChooseGen('event')}],
    correct_time: [{required, message: messWriteNumbGen('correct_time')}],
    cctv_id: [{required, message: messWriteNumbGen('cctv_id')}],
    timestamp: [{required, message: messAddSomeGen('timestamp')}],
    reject_reason: [{required, message: messChooseGen('reason!')}]
}