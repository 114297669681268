import React from 'react';
import {PENCIL, TRASH, USER} from "../icons/antd";
import {Button} from "antd";

const Actions = ({handlePreviewUser, handleEditUser, remove, _id, hideButton}) => {

    return (
        <section className={'userActions'}>
            <Button onClick={() => handlePreviewUser(_id)} type={'primary'} icon={USER} className={'userActions__actionButton'}/>
            <Button onClick={() => handleEditUser(_id)} type={'primary'} icon={PENCIL} className={'userActions__actionButton'}/>
            {hideButton && <Button onClick={() => remove(_id)} type={'primary'} icon={TRASH} className={'userActions__actionButton'}/>}
        </section>
    )
}

export default Actions;