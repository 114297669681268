import React from 'react';
import {useSelector} from "react-redux";
import {Card} from "antd";
import '../styles/Account.sass'
import {selectProfile} from "../store/selectors/Api/auth";

const DetailsText = ({detail, label}) => {
    return (
        <div className={'detailsText'}>
            <span>{label} </span>
            <span>{detail}</span>
        </div>
    )
}

const Profile = () => {
    const profile = useSelector(selectProfile);

    return (
        <div className={'accountWrapper'}>
            <div className={'accountWrapper__userDetails'}>
                <Card title={'PROFILE'} className={'details'}>
                    <DetailsText detail={profile['_id']} label={'Login:'}/>
                    <DetailsText detail={profile['is_active'] ? 'active' : 'inactive'} label={'Status:'}/>
                    <DetailsText detail={profile['role']} label={'Role:'}/>
                </Card>
            </div>
        </div>
    )
}


export default Profile;