import {useEffect} from 'react';
import {Route, Routes} from "react-router-dom";
import Login from "./components/SignIn/Login";
import {handleUpdateUser} from "./store/slices/Api/Auth";
import {useDispatch, useSelector} from "react-redux";
import BigLoading from "./components/Loadings/BigLoading";
import PrimaryRoutes from "./routes/PrimaryRoutes/PrimaryRoutes";
import {selectAuthUser} from "./store/selectors/Api/auth";
import {fallbackPath, LOGIN} from "./utils/routeUtils/Paths";
import FallBackRoute from "./routes/security/FallBackRoute";
import {getCashProfile} from "./utils/helpers/cacheHelpers";

const Intell_Act = () => {
    const dispatch = useDispatch();
    const {isAuthLoading, isAuth} = useSelector(selectAuthUser);

    useEffect(() => {
        const userCash = getCashProfile();
        if (userCash) dispatch(handleUpdateUser(userCash))
    }, [])

    if (isAuthLoading) return <BigLoading/>

    if (!isAuth) {
        return (
            <Routes>
                <Route path={LOGIN} element={<Login/>}/>
                <Route path={fallbackPath} element={<FallBackRoute homeLink={LOGIN}/>}/>
            </Routes>
        )
    }

    return <PrimaryRoutes/>
}
export default Intell_Act;