import Role from "../../../components/Administration/Role";
import Status from "../../../components/Administration/Status";
import Actions from "../../../components/Administration/Actions";
import {ADMIN} from "../../routeUtils/Roles";
import {userRoles, userStatuses} from "../../constants/arrays/administration";

export const userColumns = (handlePreviewUser, handleEditUser, remove) => ([
    {
        key: '_id',
        title: 'Name',
        dataIndex: '_id',
    },
    {
        key: 'role',
        title: 'Role',
        dataIndex: 'role',
        filters: userRoles,
        filterSearch: true,
        onFilter: (value, {role}) => role.indexOf(value) === 0,
        render: (role) => <Role role={role}/>
    },
    {
        key: 'is_active',
        title: 'Status',
        dataIndex: 'is_active',
        filters: userStatuses,
        filterSearch: true,
        onFilter: (status, {is_active}) => is_active.indexOf(status) === 0,
        render : (status) => <Status status={status}/>,
    },
    {
        key: 'action',
        title: 'Action',
        dataIndex: '_id',
        render: (_id, record) => {
            return (
                <Actions
                    handleEditUser={handleEditUser}
                    remove={remove}
                    handlePreviewUser={handlePreviewUser}
                    _id={_id}
                    hideButton={record['role'] !== ADMIN}
                />
            )}
    }
])