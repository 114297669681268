import React from 'react';
import {useSelector} from "react-redux";
import EmptyComponent from "../Gate/EmptyComponent";
import {isValidDateStrict, returnCorrectTimeZoneTime} from "../../utils/helpers/timeHelper";
import {selectTimeZone} from "../../store/selectors/Ui/TimeZone";
import {arrivalKeyList, departureKeyList, generalKeyList} from "../../utils/constants/arrays/turnaround";
import PrintTurnaroundDetails from "./PrintTurnaroundDetails";

const TurnaroundDetails = ({turnaround}) => {
    const timeZone = useSelector(selectTimeZone);

    const transformedResult = (value) => {
        if (!value) return "N/A";
        return isValidDateStrict(value) ? returnCorrectTimeZoneTime(value, timeZone) : value
    }

    if (!turnaround || Object.keys(turnaround).length === 0) {
        return <EmptyComponent text={"There is no information about this turnaround"} emptyClassComponent={'gatesEmptyBlock'}/>
    }


    return (
        <div className={'turnaround-details-container'}>
            <PrintTurnaroundDetails
                arrayKeys={generalKeyList}
                turnaround={turnaround}
                header={"General"}
                transformedResult={transformedResult}
            />
            <PrintTurnaroundDetails
                arrayKeys={arrivalKeyList}
                turnaround={turnaround}
                header={"Arrival"}
                transformedResult={transformedResult}
            />
            <PrintTurnaroundDetails
                arrayKeys={departureKeyList}
                turnaround={turnaround}
                header={"Departure"}
                transformedResult={transformedResult
            }/>
        </div>
    );
};

export default TurnaroundDetails;