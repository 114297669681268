import React from 'react';

const PrintTurnaroundDetails = ({header, arrayKeys, turnaround, transformedResult}) => {
    return (
        <div className={"turnaround-details-container__details"}>
            <h2>{header}</h2>
            <section className={'details-section'}>
                {turnaround.map(([key, value]) => {
                    if (arrayKeys.includes(key)) {
                        return (
                            <p key={key} className={"details-section__text"}>{key}:
                                <span>{transformedResult(value)}</span>
                            </p>
                        )
                    }
                })}
            </section>
        </div>
    )
};

export default PrintTurnaroundDetails;