import React from 'react';
import EditableBtnToggle from "../../ANTD/EditableBtnToggle";
import {Button, Tag, Tooltip} from "antd";
import {DOWN_ARROW_CIRCLE} from "../../icons/antd";


const EditableVisibility = ({onSave, onCancel, onEdit, isEdited, editingKey, event}) => {
    const {visibility, uuid} = event
    const tag_value = visibility.length > 0 ? visibility.join(', ') : "not visible"

    return (
        <EditableBtnToggle isEdited={isEdited} onSave={() => onSave(event)} cancel={onCancel}>
            <div>
                <Tag color={visibility.length ? 'blue' : "red"} style={{color: '#000'}}>{tag_value}</Tag>
                <Tooltip title={!editingKey && "drop_down"}>
                    <Button onClick={() => onEdit({visibility}, uuid)} disabled={editingKey !== ''}>
                        {DOWN_ARROW_CIRCLE}
                    </Button>
                </Tooltip>
            </div>
        </EditableBtnToggle>
    );
};

export default EditableVisibility;