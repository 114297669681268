import {objectEventDropDownMenuItems} from "../../helpers/system/objectEventDropdownHelpers";
import VisibilityDropdownOptionRow from "../../../components/System/Common/VisibilityDropdownOptionRow";
import EmptyField from "../../../components/Customs/EmptyField";
import EditableVisibility from "../../../components/System/Common/EditableVisibility";
import {expectedEventModesOptions} from "../../constants/arrays/system";
import RequiredEventModes from "../../../components/System/EventModes/RequiredEventModes";
import {defaultEventColumns} from "./common";

export const eventModeColumns =
    ({
         processes, getColumnSearchProps,
         isEditing, edit, editingKey,
         cancel, save,
         isRequired, isVisibility,
         sysEventAsyncHandler
     }) => ([
        ...defaultEventColumns({processes, getColumnSearchProps}),
        {
            title: () => {
                const menuItems = objectEventDropDownMenuItems(
                    {
                        placement: "left", value: "Set all to visible",
                        onConfirm: () => sysEventAsyncHandler(true, "visibility")
                    },
                    {
                        placement: "left",
                        value: "Set all to invisible",
                        onConfirm: () => sysEventAsyncHandler(false, "visibility")
                    }
                )
                return (
                    <VisibilityDropdownOptionRow
                        classes={"event_mode_btn_edit_container__dropdown_title_block"}
                        title={"Positions visibility"}
                        menuItems={menuItems}
                        selectedKey={isVisibility}
                    />
                )
            },
            dataIndex: 'visibility',
            editableKey: "positions",
            key: 'positions',
            editable: true,
            className: "event_mode_btn_edit_container",
            render: (_, event) => {
                return (
                    <EmptyField isEmpty={!event.positions} message={'N/A'} tagColor={"#006d75"}>
                        <EditableVisibility
                            isEdited={isEditing(event.uuid)}
                            event={event}
                            editingKey={editingKey}
                            onEdit={edit}
                            onCancel={cancel}
                            onSave={save}
                        />
                    </EmptyField>
                )
            },
        },
        {
            title: () => {
                const menuItems = objectEventDropDownMenuItems(
                    {
                        placement: "left", value: "Set all to visible",
                        onConfirm: () => sysEventAsyncHandler(true, "required")
                    },
                    {
                        placement: "left", value: "Set all to invisible",
                        onConfirm: () => sysEventAsyncHandler(false, "required")
                    }
                )
                return (
                    <VisibilityDropdownOptionRow
                        classes={"event_mode_btn_edit_container__dropdown_title_block"}
                        title={"Expected"}
                        menuItems={menuItems}
                        selectedKey={isRequired}
                    />
                )
            },
            dataIndex: 'Expected',
            key: 'Expected',
            filters: expectedEventModesOptions,
            filterSearch: false,
            onFilter: (value, {required}) => required === value,
            render: (_, {visibility, required, uuid, ...rest}) => {
                return <RequiredEventModes isActive={required} event={rest}/>
            }
        },
    ])