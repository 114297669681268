import air_axios_settings from "../services/airAxios";
import {toast} from "react-toastify";

const setMethods = async ({method, path, body, config}) => {
    if (method === 'POST') return await air_axios_settings.post(path, body, config)
    if (method === 'GET') return await air_axios_settings.get(path, config)

}

export const getDataFile = async (settings) => {
    try {
        const {data} = await setMethods(settings)
        if (!data) toast.warning('There is no data');
        return data
    } catch (error) {
        toast.error(`Something happened, plz try again`)
        throw new Error(error)
    }
}