import {useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getMonitoredObjects} from "../../store/asyncThunkFunctions/Api/CamerasRequests";
import ModesMonitoringHeader from "../../components/System/Common/ModesMonitoringHeader";
import useSysEventHeaderStates from "../../hooks/System/useSysEventHeaderStates";
import {selectCctvIds} from "../../store/selectors/Api/gates";
import useTable from "../../hooks/useTable";
import {selectMonitoring} from "../../store/selectors/Api/cameras";
import {BUSY_SPACE_IN_PAGE_JUST_WITH_TABLE} from "../../utils/Sizes";
import {scrollByY} from "../../utils/helpers/tableScrollHelpers";
import {monitoringObjectColumns} from "../../utils/tableUtils/columns/monitoring";
import {getListOfGateNames} from "../../store/asyncThunkFunctions/Api/GateRequest";
import {resetGateState} from "../../store/slices/Api/sliceGateRequest";
import EditableSelectCell from "../../components/ANTD/EditableSelectCell";
import useConsistencyEventRowChecks from "../../hooks/System/useConsistencyEventRowChecks";
import {addUuidFiled} from "../../utils/helpers/dataHelpers/arrayObjectModifiers";
import EditableTable from "../../components/Customs/EditableTable";
import useMonitoringObjHandlers from "../../hooks/System/useMonitoringObjHandlers";
import {resetMonitoringObj} from "../../store/slices/Api/Cameras";

const MonitoringObjects = () => {
    const dispatch = useDispatch();

    const {cctvIds} = useSelector(selectCctvIds);
    const {monitoring, monitoringLoading} = useSelector(selectMonitoring)

    const {
        mergedColumns, getColumnSearchProps, cancel,
        editingKey, form,
        defaultEditPropsConfig, save
    } = useTable();

    const {gate: gate_id, camera: cctv_id, onSelectGate, onSelectCctvId} = useSysEventHeaderStates({
        cctvIds, position: "ramp", onCanselEditField: () => {if (editingKey !== "") cancel()}
    });
    const {isVisibility} = useConsistencyEventRowChecks(monitoring);
    const {onUpdateMonitoring, onUpdateMonitoringList} = useMonitoringObjHandlers({
        gate_id, cctv_id, monitoring, onUpdate: save
    })

    const monitoringList = useMemo(() => addUuidFiled(monitoring), [monitoring]);

    const columnsProps = {
        ...defaultEditPropsConfig,
        save: onUpdateMonitoring,
        sysEventAsyncHandler: onUpdateMonitoringList,
        getColumnSearchProps,
        isVisibility
    }

    const monitoringObjTableProps = {
        form: {form, component: false},
        table: {
            dataSource: monitoringList,
            loading: monitoringLoading,
            columns: mergedColumns(columnsProps, monitoringObjectColumns, "uuid"),
            components: {body: {cell: EditableSelectCell}},
            scroll: {y: scrollByY(BUSY_SPACE_IN_PAGE_JUST_WITH_TABLE)},
            rowKey: ({uuid}) => uuid,
            pagination: false,
            className: "monitoring_object_table",
        }
    }

    useEffect(() => {
        dispatch(getMonitoredObjects({gate_id, cctv_id}))
    }, [gate_id, cctv_id, dispatch]);


    useEffect(() => {
        dispatch(getListOfGateNames());
        return () => {
            dispatch(resetGateState());
            dispatch(resetMonitoringObj())
        }
    }, []);

    return (
        <div className={"monitoring_object_container"}>
            <ModesMonitoringHeader
                gate={gate_id}
                camera={cctv_id}
                onSelectGate={onSelectGate}
                onSelectCctvId={onSelectCctvId}
                mainClass={"monitoring_object_container__header"}
            />
            <EditableTable {...monitoringObjTableProps} />
        </div>
    );
};

export default MonitoringObjects;