import {Button, Tooltip} from "antd";
import EmptyField from "../../../components/Customs/EmptyField";
import {PLUS_CIRCLE} from "../../../components/icons/antd";
import {defaultEventColumns} from "./common";

export const potentialEventTurnColumns = ({handleGetPairedEvent, processes, getColumnSearchProps, isShowFilter, isStillTurnInRedis}) => ([
    ...defaultEventColumns({processes, getColumnSearchProps, isShowFilter}),
    {
        title: 'Paired Event',
        dataIndex: 'paired_event',
        key: 'paired_event',
        render: (paired_events) => {
            return (
                <EmptyField isEmpty={!paired_events || !paired_events.length} message={"N/A"} tagColor={"#006d75"}>
                    {paired_events?.map(event => <p key={event}>{event}</p>)}
                </EmptyField>
            )
        }
    },
    {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        render: (_, {event_name: event, process}) => {
            return (
                <Tooltip placement={'bottom'} title={isStillTurnInRedis && "disabled while turn is active"}>
                    <Button disabled={isStillTurnInRedis} onClick={() => handleGetPairedEvent({process, event})}
                            style={{padding: '5px', fontSize: '1rem', lineHeight: '1rem'}}>{PLUS_CIRCLE}</Button>
                </Tooltip>
            )
        }
    }
])