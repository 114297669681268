import React from 'react';
import {Button, Input, Space} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import {tableDefStyles} from "../../utils/constants/objects/defaultStyles";


const SearchComponent = (
    {
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
        searchInput,
        dataIndex,
        search,
        reset,
    }) => {

    return (
        <div style={{...tableDefStyles.filterDpd}}>
            <Input ref={searchInput} placeholder={`Search ${dataIndex}`}
                   value={selectedKeys[0]} onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                   onPressEnter={() => search(selectedKeys, confirm, dataIndex)} style={{...tableDefStyles.input}}
            />
            <Space>
                <Button onClick={() => clearFilters && reset(clearFilters)} size="small" style={{...tableDefStyles.button}} disabled={!selectedKeys.length}>
                    Reset
                </Button>
                <Button
                    type="primary"
                    onClick={() => search(selectedKeys, confirm, dataIndex)}
                    icon={<SearchOutlined />} size="small"
                    style={{...tableDefStyles.button}}>
                    OK
                </Button>

                {/*<Button type="link" size="small" onClick={() => {confirm({closeDropdown: false,});*/}
                {/*    setSearchText(selectedKeys[0]);setSearchedColumn(dataIndex)}}>*/}
                {/*    Filter*/}
                {/*</Button>*/}
                {/*<Button type="link" size="small" onClick={close}>close</Button>*/}
            </Space>
        </div>
    );
};

export default SearchComponent;