import React from 'react';
import {CLOSE} from "../icons/antd";
import {Card} from "antd";
import Role from "./Role";
import Status from "./Status";

const User = ({_id, role, is_active, className, close}) => {

    return (
        <Card title={_id} size={'small'} className={className}>
            <button className={'closeButtonCard'} onClick={close}>{CLOSE}</button>
            <header className={'userCardHeader'}>
                <h3>Details</h3>
                <h3>Permissions</h3>
            </header>
            <main className={'userCardBody'}>
                <section className={'sectionUserDetails'}>
                    <div className={'userDetail'}>
                        <span>Login: </span>
                        <span>{_id}</span>
                    </div>
                    <div className={'userDetail'}>
                        <span>Role: </span>
                        <Role role={role}/>
                    </div>
                    <div className={'userDetail'}>
                        <span>Status: </span>
                        <Status status={is_active ? 'active' : 'inactive'}/>
                    </div>
                    <div className={'userDetail'}>
                        <span>Name: </span>
                        <span>{_id}</span>
                    </div>

                    <div className={'userDetail'}>
                        <span>E-mail: </span>
                        <span>dddd@.com</span>
                    </div>
                </section>
                <section className={'previewUserPermissions'}>
                    Permissions
                </section>
            </main>
        </Card>
    );
};

export default User;