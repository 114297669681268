export const object_color = {
    "aircraft": "rgb(0, 0, 255)",
    "structure": "rgb(255, 0, 0)",
    "vehicle": "rgb(0, 255, 0)",
    "person": "rgb(255, 0, 255)",
    "object": "rgb(225, 225, 0)",
    "default": "rgb(255, 128, 0)"
}

export const projection_colors = {
    "aircraft": "#4096ff",
    "structure": "#ff4d4f",
    "vehicle": "#52c41a",
    "person": "#eb2f96",
    "object": "#fffb8f",
    "default": "#d48806"
};


export const canvas_layers = {
    IMAGE: 'IMAGE',
    KEYPOINTS: 'KEYPOINTS',
    DETECTIONS: 'DETECTIONS',
    CLEARANCE_ZONE: 'CLEARANCE_ZONE',
    VESTS: 'VESTS',
    TRACKERS: 'TRACKERS',
    PROJECTIONS: 'PROJECTIONS',
    EVENT_BBOX: 'EVENT_BBOX',
    OTHER_PROJECTIONS: "OTHER_PROJECTIONS",
};


export const position_layers = {
    "ramp": ['KEYPOINTS', 'DETECTIONS', 'TRACKERS', 'CLEARANCE_ZONE', 'VESTS', 'PROJECTIONS', "OTHER_PROJECTIONS"],
    "bridge": ['DETECTIONS', 'TRACKERS', 'VESTS']
};

export const changeCanvasLayersFieldPositions = (canvas_layers) => {
    const {
        IMAGE, KEYPOINTS, DETECTIONS, TRACKERS, CLEARANCE_ZONE, VESTS, PROJECTIONS, OTHER_PROJECTIONS, EVENT_BBOX
    } = canvas_layers

    return {IMAGE, KEYPOINTS, DETECTIONS, TRACKERS, CLEARANCE_ZONE, VESTS, PROJECTIONS, OTHER_PROJECTIONS, EVENT_BBOX}
}