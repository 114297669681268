export const drawCross = ({context, strokeStyle, lineWidth, crossSize, x, y, opacity}) => {
    context.globalAlpha = opacity || 1
    context.beginPath();
    context.moveTo(x - crossSize, y - crossSize);
    context.lineTo(x + crossSize, y + crossSize);
    context.moveTo(x + crossSize, y - crossSize);
    context.lineTo(x - crossSize, y + crossSize);
    context.strokeStyle = strokeStyle;
    context.lineWidth = lineWidth
    context.stroke();
};