import {useEffect, useMemo} from 'react';
import {useSelector} from "react-redux";
import {selectSettingsUi} from "../../store/selectors/Api/system";
import {Checkbox, Tooltip} from "antd";
import {filterElements} from "../../utils/helpers/dataHelpers/arrayObjectFilters";
import {position_layers} from "../../utils/constants/objects/canvas";
import {extraCanvasLayerKeys, extraViewerCanvasLayerKeys} from "../../utils/constants/arrays/canvas";
import {selectProfile} from "../../store/selectors/Api/auth";
import {VIEWER} from "../../utils/routeUtils/Roles";

const DetectionCheckboxes = ({camera_position, checkedBoxes, setCheckBoxes}) => {
    const {CANVAS_LAYERS} = useSelector(selectSettingsUi);
    const {role} = useSelector(selectProfile);

    const onRemoveIncorrectCanvasCheckbox = () => {
        setCheckBoxes(prevCheckboxes => {
            Object.keys(prevCheckboxes).forEach(checkboxName => {
                if (!position_layers[camera_position].includes(checkboxName)) {
                    delete prevCheckboxes[checkboxName]
                }
            })
            return prevCheckboxes
        });
    }

    const onChangeCheckBox  = (checkBox) => setCheckBoxes(prevCheckboxes => ({...prevCheckboxes, ...checkBox}));

    const filteredCheckBoxesByRole = useMemo(() => {
        const condition = (canvas_layer) => {
            return role === VIEWER ? extraViewerCanvasLayerKeys.includes(canvas_layer) : canvas_layer
        }
        return filterElements(Object.keys(CANVAS_LAYERS), condition)
    }, [role, CANVAS_LAYERS]);


    const checkBoxes = useMemo(() => {
        const condition = (canvas_layer) => {
            return !extraCanvasLayerKeys.includes(canvas_layer) &&
                camera_position && position_layers[camera_position].includes(canvas_layer);
        }
        return filterElements(filteredCheckBoxesByRole, condition);
    }, [camera_position, filteredCheckBoxesByRole]);

    useEffect(() => {
        if (camera_position && Object.keys(checkedBoxes).length > 0) {
            onRemoveIncorrectCanvasCheckbox()
        }
    }, [camera_position]);

    return (
        <div className={'canvasFrameCheckBoxes'}>
            {
                checkBoxes.map((checkbox, index) => {
                    const validText = checkbox.replace(/_/g, " ")
                    return (
                        <Tooltip
                            key={`${checkbox} -> ${index}`}
                            title={!CANVAS_LAYERS[checkbox] && `${validText} was disabled in configs`}
                            placement={'bottomRight'}
                        >
                            <Checkbox
                                disabled={!CANVAS_LAYERS[checkbox]}
                                key={checkbox}
                                checked={checkedBoxes[checkbox]}
                                onChange={({target: {checked}}) => onChangeCheckBox({[checkbox]: checked})}>
                                {`${validText[0]}${validText.slice(1, validText.length).toLowerCase()}`}
                            </Checkbox>
                        </Tooltip>
                    )
                })
            }
        </div>
    )
};

export default DetectionCheckboxes;