import {Link} from "react-router-dom";
import {ADMINISTRATION_LINKS} from "../Links";

const {users, audit} = ADMINISTRATION_LINKS;

export const administrationChildren = [
    {
        label: <Link to={users}>Users</Link>,
        key: users
    },
    {
        label: <Link to={audit}>Audit</Link>,
        key: audit
    },
]