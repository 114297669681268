import React from "react";
import {TransformWrapper, TransformComponent} from "react-zoom-pan-pinch";
import {drawRectangle} from "../../utils/helpers/drawHelpers/drawRectangle";
import {drawText} from "../../utils/helpers/drawHelpers/drawText";
import {drawCircle} from "../../utils/helpers/drawHelpers/drawCircle";
import {resetCanvas} from "../../utils/helpers/drawHelpers/resetCanvas";
import {object_color, projection_colors, canvas_layers} from "../../utils/constants/objects/canvas"
import {connect} from "react-redux";
import {DOWNLOAD} from "../icons/antd";
import {All, WithoutViewer} from "../../utils/routeUtils/Roles";
import {defaultZoomProps} from "../../utils/constants/objects/zoomPanPinch";

class Canvas extends React.Component {
    constructor(props) {
        super(props);
            this.state = {}
            this.arrayCanvas = [];
            for (let key in this.props?.CanvasLayers) {
                this.arrayCanvas.push({
                    className: `${key.toLowerCase()}_layer`,
                    id: `${key.toLowerCase()}_canvas`,
                    hidden: !this.props?.CanvasLayers[key],
                    ref: React.createRef(),
                    canvas_key: key,
                    roles: ['DETECTIONS', 'CLEARANCE_ZONE', 'IMAGE', 'EVENT_BBOX'].includes(key) ? All : WithoutViewer
                })
            }

            this.contexts = []
            this.orig_image_size = [0, 0];
            this.window_size = [window.screen.width, window.screen.height];
    }

    Init() {
        let contexts = []
        const arrayCanvas = this.filterArrayCanvas()
        for (let i = 0; i < arrayCanvas.length; i++) {
            contexts.push({
                roles: arrayCanvas[i]['roles'],
                context: arrayCanvas[i]['ref'].current.getContext("2d"),
                canvas_key: arrayCanvas[i]['canvas_key']
            },);
            arrayCanvas[i]['ref'].current.width = this.window_size[0];
            arrayCanvas[i]['ref'].current.height = this.window_size[1];
        }
        if (contexts.length) contexts['0']['context'].imageSmoothingEnabled = false;


        this.contexts = contexts.filter(({roles}) => roles.includes(this.props['role']));
    }

    filterArrayCanvas() {
        return this.arrayCanvas.filter(({roles}) => roles.includes(this.props['role']))
    }

    findCorrectDrawContext(value) {
        if (value) return this.contexts?.find(({canvas_key}) => canvas_key === value)?.context
    }

    componentDidMount() {

        this.Init();
        this.SetImageMyVersion()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        this.SetImageMyVersion();
    }

    async SetImageMyVersion() {
        const {image, inference, image_path, checkedBoxes, bbox} = this.props

        if (inference && inference.image_size) this.orig_image_size = [inference.image_size[0], inference.image_size[1]];

        const img = new Image();
        img.src = image !== "" ? image : window.location.origin + image_path;
        img.onload = () => {
            this.contexts.forEach(({context}) => {if (context.canvas['className'] !== 'image_layer') resetCanvas(context)});

            this.contexts['0']?.context.drawImage(img, 0, 0, this.window_size[0], this.window_size[1]);

            if (inference) {
                this.DrawDetectionsTrackers(checkedBoxes['DETECTIONS'], checkedBoxes['TRACKERS']);
                this.DrawVests(checkedBoxes['VESTS']);

                this.DrawKeypoints(checkedBoxes['KEYPOINTS']);
                this.DrawObservedArea(checkedBoxes['PROJECTION']);
                this.DrawProjections(checkedBoxes['PROJECTIONS']);

            }
            if (bbox && Object.keys(bbox).length) {
                this.DrawBbox(canvas_layers['EVENT_BBOX'], bbox['positions'], bbox['label']);
            }

            this.DrawClearanceZone(checkedBoxes['CLEARANCE_ZONE']);
            this.DrawOtherProjections(checkedBoxes["OTHER_PROJECTIONS"]);
        }
    }

    AlignX(x) {
        return (x / this.orig_image_size[0]) * this.window_size[0];
    }

    AlignY(y) {
        return (y / this.orig_image_size[1]) * this.window_size[1];
    }

    CanvasPointAllignment(bbox) {
        return {x: this.AlignX(bbox[0]), y: this.AlignY(bbox[1])};
    }

    DrawProjectionText({ctx, textColor, index, x, y, text}) {
        ctx.font = "35px serif"
        ctx.fillStyle = textColor
        if (index === 0) ctx.fillText(text, x, y)
    }

    DrawBbox(layer, bbox, label) {
        drawRectangle(
            this.findCorrectDrawContext(layer),
            this.AlignX(bbox[0]),
            this.AlignY(bbox[1]), this.AlignX(bbox[2] - bbox[0]),
            this.AlignY(bbox[3] - bbox[1]), 2, object_color['default'], label, false
        );
    }

    SetProjectionCameraParameters({trans_projections, main_camera, superclass, cctv_id}) {
        const cameraParameters = {strokeLineColor: "", drawList: []};
        const projectionColor = superclass in projection_colors ? projection_colors[superclass] : projection_colors['default'];

        if (!trans_projections[cctv_id] && trans_projections[main_camera]) {
            cameraParameters.strokeLineColor = "#000";
            cameraParameters.drawList = Object.entries(trans_projections[main_camera]);
        }

        if (trans_projections[cctv_id]) {
            cameraParameters.strokeLineColor = projectionColor;
            cameraParameters.drawList = Object.entries(trans_projections[cctv_id]);
        }

        return cameraParameters
    }

    DrawKeypoints(status) {
        if (status) {
            const {inference} = this.props

            resetCanvas(this.findCorrectDrawContext(canvas_layers['KEYPOINTS']));

            let all_aircraft_data = inference?.detected_objects;
            for (let i=0; i<all_aircraft_data?.length; i++) {
                let obj = all_aircraft_data[i];

                if (!obj.keypoints) {
                    continue;
                }

                let superclass = obj['superclass_name']
                let moved = obj['moved']
                let obj_type_color = superclass in object_color ? object_color[superclass] : object_color['default'];

                for (let key in obj.keypoints) {
                    const {x, y} = this.CanvasPointAllignment(obj.keypoints[key]);
                    drawCircle(
                        this.findCorrectDrawContext(canvas_layers['KEYPOINTS']),
                        x, y, 5, 2, obj_type_color, moved
                    );
                }
            }
        } else {
            resetCanvas(this.findCorrectDrawContext(canvas_layers['KEYPOINTS']));
        }
    }

    DrawObservedArea(status) {
        const {inference: {observed_area}, isCorrectOption} = this.props
        let ctx = this.findCorrectDrawContext(canvas_layers['PROJECTIONS']);

        if (status && ctx && isCorrectOption) {
            ctx.beginPath();
            ctx.setLineDash([])
            ctx.strokeStyle = 'turquoise';
            ctx.lineWidth = 2.5;
            for (let i = 0; i < observed_area?.length; i++) {
                const {x, y} = this.CanvasPointAllignment(observed_area[i]);
                ctx.lineTo(x, y);
            }
            ctx.closePath();
            ctx.stroke();

        } else {
            resetCanvas(ctx);
        }
    }

    DrawProjections(status) {
        const {selectedData, camera} = this.props
        // const cctv_id = camera?.cctv_id
        // const entities = selectedData?.projection
        const oldProjections = selectedData?.selected

        let ctx = this.findCorrectDrawContext(canvas_layers['PROJECTIONS']);
        if (status && ctx) {

            oldProjections.forEach(({projected_bbox, superclass_name, entity_id}) => {
                console.log(entity_id, "entity_id")
                const lineColor = superclass_name in projection_colors ? projection_colors[superclass_name] : projection_colors['default']
                ctx.beginPath();
                ctx.lineWidth = 6;
                ctx.setLineDash([4, 6])
                ctx.strokeStyle = lineColor;
                Array.isArray(projected_bbox) && projected_bbox?.forEach((projected, index) => {
                    const {x, y} = this.CanvasPointAllignment(projected);
                    ctx.lineTo(x, y);
                    entity_id && this.DrawProjectionText({
                        ctx, textColor: lineColor, index, x, y, text: `${entity_id}`
                    })
                })
                ctx.closePath();
                ctx.stroke();
            })

            // entities?.forEach(({trans_projections, main_camera, superclass, type, entity_id}) => {
            //
            //     const {strokeLineColor, drawList} = this.SetProjectionCameraParameters({
            //         trans_projections, main_camera, superclass, cctv_id
            //     });
            //
            //     ctx.beginPath();
            //     ctx.lineWidth = 6;
            //     ctx.setLineDash([4, 6]);
            //     ctx.strokeStyle = strokeLineColor;
            //
            //     drawList.forEach(([key, projectionCamera], index) => {
            //         const {x, y} = this.CanvasPointAllignment(projectionCamera);
            //         this.DrawProjectionText({
            //             ctx, textColor: strokeLineColor, index, x, y, text: `${entity_id}_${type}`
            //         })
            //         ctx.lineTo(x, y)
            //     });
            //
            //     ctx.closePath();
            //     ctx.stroke();
            // })
        } else {
            resetCanvas(this.findCorrectDrawContext(canvas_layers['PROJECTIONS']));
        }
    }

    DrawOtherProjections (status) {
        const ctx = this.findCorrectDrawContext(canvas_layers['OTHER_PROJECTIONS']);
        const {selectedData, camera: {cctv_id}, goToCameras} = this.props
        const projection = selectedData?.projection

        if (status && ctx) {
            projection?.forEach(({trans_projections, type, entity_id}) => {
                Object.entries(trans_projections).forEach(([projectionCamera, projectionCameraValue]) => {
                    const projectedCamera = goToCameras.find(camera => camera.cctv_id === projectionCamera)

                    ctx.strokeStyle = projectedCamera?.cameraColor;
                    ctx.beginPath();
                    ctx.lineWidth = 3;

                    if (projectionCamera !== cctv_id) {
                        projectionCameraValue.forEach((position, index) => {
                            const {x, y} = this.CanvasPointAllignment(position);
                            this.DrawProjectionText({
                                ctx, textColor: projectedCamera?.cameraColor, index, x, y, text: `${entity_id}_${type}`
                            })
                            ctx.lineTo(x, y);
                        })
                    }

                    ctx.closePath();
                    ctx.stroke();
                })
            })
        } else {
            resetCanvas(ctx);
        }
    }

    DrawDetectionsTrackers(detections, trackers) {
        const {selectedData} = this.props
        const selected = selectedData?.selected

        const detections_context = this.findCorrectDrawContext(canvas_layers['DETECTIONS']);
        const trackers_context = this.findCorrectDrawContext(canvas_layers['TRACKERS']);

        if (!detections) resetCanvas(detections_context);
        if (!trackers) resetCanvas(trackers_context);

        for (let i = 0; i < selected?.length; i++) {
            const {bbox, superclass_name, subclass_name, superclass_confidence, track_id, subclass_prob, filtered_out} = selected[i]
            const color = superclass_name in object_color ? object_color[superclass_name] : object_color['default'];
            if (detections) {
                let broken_line = filtered_out !== undefined ? filtered_out : false
                drawRectangle(detections_context,
                    this.AlignX(bbox[0]),
                    this.AlignY(bbox[1]),
                    this.AlignX(bbox[2] - bbox[0]),
                    this.AlignY(bbox[3] - bbox[1]),
                    3, color, superclass_name === 'aircraft' ? superclass_name : subclass_name, false, broken_line);
            }
            if (trackers) {
                let sub_confidence = parseInt(subclass_prob * 100)
                drawText(
                    trackers_context,
                    this.AlignX(bbox[0]), this.AlignY(bbox[1]) + this.AlignX(bbox[3] - bbox[1]),
                    color, `(${!track_id ? "N/A" : track_id},${sub_confidence}%)`
                );
            }
        }
    }

    DrawVests(status) {
        const ctx = this.findCorrectDrawContext(canvas_layers['VESTS'])
        if (status && ctx) {
            const {inference} = this.props
            const detected_objects = inference?.detected_objects;

            detected_objects?.forEach(element => {
                if (element.superclass_name === 'person') {
                        let bbox = element['bbox'];
                        const track_id = element['track_id'] || "N/A";
                        let confidence = parseInt(element['superclass_confidence'] * 100);
                        let label = "";



                        let color = element['vest_color'];
                        if (color === "no_vest" || color === "not_sure") {
                            label = `${color}`;
                            color = "black";
                        } else {
                            color = color?.split('_')[1];
                            label = `${element['vest_color'] || "N/A"}`;
                        }

                        label = `${label}(${track_id}),${confidence}%`;
                    drawRectangle(this.findCorrectDrawContext(canvas_layers['VESTS']),
                            this.AlignX(bbox[0]),
                            this.AlignY(bbox[1]),
                            this.AlignX(bbox[2] - bbox[0]),
                            this.AlignY(bbox[3] - bbox[1]),
                            2, color, label, false);
                    }
            })
        } else {
            resetCanvas(ctx);
        }
    }

    DrawClearanceZone(status) {
        let ctx = this.findCorrectDrawContext(canvas_layers['CLEARANCE_ZONE']);
        if (!status) resetCanvas(ctx);

        if (status && ctx) {
            ctx.beginPath();
            ctx.lineWidth = 1.5;
            ctx.strokeStyle = 'blue';
            const clearance_points = this.props.camera['clearance_points'];
            for (let i = 0; i < clearance_points.length; i++) {
                let point = clearance_points[i];
                let after = [point[0] * this.window_size[0], point[1] * this.window_size[1]];
                ctx.lineTo(after[0], after[1]);
            }
            ctx.stroke();
            ctx.closePath();
        }
    }

    componentWillUnmount() {
        console.log('Unmount Canvas Component');

    }

    render() {
        return (
            <div className="canvas_div">
                <div className={'canvas_div__downloadImage'}>
                    <button onClick={this.props.downloadImage} className={'buttonDownloadImage'}>{DOWNLOAD}</button>
                </div>
                <TransformWrapper {...defaultZoomProps}>
                    <TransformComponent>
                        {this.filterArrayCanvas()?.map((item, i) => {
                            return <canvas
                                key={item.className}
                                className={item.className}
                                id={item.id}
                                ref={item?.ref}
                                hidden={item.hidden}
                            />
                        })}
                    </TransformComponent>
                </TransformWrapper>
            </div>
        )
    }
}


const mapStateToProps = (state) => ({
    camera: state.api.formGate.editCamera.camera,
    CanvasLayers: state.api.systemSlice.settingsUI?.CANVAS_LAYERS,
    role: state.api.Auth.profile['role'],
})

export default connect(mapStateToProps, null, null, ({forwardRef: true}))(Canvas)