import {useDispatch} from "react-redux";
import {getMonitoredObjects, updateMonitoredObject} from "../../store/asyncThunkFunctions/Api/CamerasRequests";
import {useCallback} from "react";
import {modifiedVisibilityObjects} from "../../utils/helpers/system/visibilityHelper";

export default function ({monitoring, gate_id, cctv_id, onUpdate}) {
    const dispatch = useDispatch();

    const onGetMonitoringList = () => dispatch(getMonitoredObjects({gate_id, cctv_id}))

    const onUpdateMonitoringList = useCallback( async (isVisible) => {
        const monitored_objects = modifiedVisibilityObjects(monitoring, isVisible)
        await dispatch(updateMonitoredObject({gate_id, cctv_id, monitored_objects}))
        await onGetMonitoringList()
    }, [gate_id, cctv_id, monitoring])

    const onUpdateMonitoring = ({obj_name}) => {
        onUpdate(async ({visibility}) => {
            const updatedObj = {gate_id, cctv_id, monitored_objects: [{obj_name, visibility}]}
            await dispatch(updateMonitoredObject(updatedObj))
            await onGetMonitoringList()
        })
    }

    return {onUpdateMonitoringList, onUpdateMonitoring}
}