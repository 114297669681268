import {useEffect, useMemo, useState} from 'react';
import {Route, Routes, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {getPerformanceInformation} from "../store/asyncThunkFunctions/Api/PerformanceRequests";
import {getMillisecondsUnixTimestamp, returnCorrectTimeZoneTime} from "../utils/helpers/timeHelper";
import {defPerformanceState} from "../store/slices/Api/Performance";
import PerformanceForm from "../components/Performance/PerformanceForm";
import Hours24 from "../pages/performance/Hours24";
import PerformanceInfo from "../pages/performance/PerformanceInfo";
import {Form} from "antd";
import {createFilterOptionsSelect} from "../utils/helpers/dataHelpers/arrayObjectModifiers";
import {getUniqueValues} from "../utils/helpers/dataHelpers/arrayObjectFilters";
import {performanceColumns, performanceInfoColumns} from "../utils/tableUtils/columns/performance";
import {selectTimeZone} from "../store/selectors/Ui/TimeZone";
import {selectPerformanceInfoList, selectPerformanceList} from "../store/selectors/Api/performance";

const Performance = () => {
    const [form] = Form.useForm()
    const {pathname} = useLocation();
    const dispatch = useDispatch();
    const [day, setDay] = useState({})

    const {performances, perfsTotal, isLoadingPerformance} = useSelector(selectPerformanceList);
    const {performanceInfo, perfInfoTotal, isLoadingPerformanceInfo} = useSelector(selectPerformanceInfoList);
    const timeZone = useSelector(selectTimeZone);

    const getPerfValues = useMemo(() => (key) => createFilterOptionsSelect(getUniqueValues(performances, key)), [performances])
    const getPerfInfoValues = useMemo(() => (key) => createFilterOptionsSelect(getUniqueValues(performanceInfo, key)), [performanceInfo])

    const transformDate = (time) => returnCorrectTimeZoneTime(time, timeZone);

    const handleGetPerfInfo = (page, limit) => dispatch(getPerformanceInformation({page, limit, body: day}));

    const initialState = {interval: '', turnaround: '', runtime: '',}

    const onFinish = (value) => {
        const {interval, turnaround, runtime} = value
        const [first, second] = interval

        const body = {
            start: getMillisecondsUnixTimestamp(first['$d']),
            end: getMillisecondsUnixTimestamp(second['$d']),
            run_time: parseFloat(runtime) || 0,
            active_turnarounds: parseInt(turnaround) || 0
        }
        dispatch(getPerformanceInformation({page: 1, limit: 10, body}))
        if (!Object.keys(day).length) setDay(body)
        form.resetFields()
    }

    useEffect(() => {
        return () => dispatch(defPerformanceState())
    }, [pathname])

    return (
        <div className={'performanceWrapper'}>
            {!pathname.endsWith('24hours') && <PerformanceForm onFinish={onFinish} initialState={initialState} form={form}/>}
            <Routes>
                <Route path={'24hours'} element={
                    <Hours24
                        columns={performanceColumns(getPerfValues, transformDate)}
                        dataSource={performances}
                        loading={isLoadingPerformance}
                        total={perfsTotal}
                    />
                }/>
                <Route path={'minmax'} element={
                    <PerformanceInfo
                        columns={performanceInfoColumns(getPerfInfoValues, 'min-max', transformDate)}
                        dataSource={performanceInfo}
                        loading={isLoadingPerformanceInfo}
                        total={perfInfoTotal}
                        handleGetPerfInfo={handleGetPerfInfo}
                    />}
                />
                <Route path={'dateCompare'} element={
                    <PerformanceInfo
                        columns={performanceInfoColumns(getPerfInfoValues, 'date-compare', transformDate)}
                        dataSource={performanceInfo}
                        loading={isLoadingPerformanceInfo}
                        total={perfInfoTotal}
                        handleGetPerfInfo={handleGetPerfInfo}
                    />}
                />
            </Routes>
        </div>
    );
};

export default Performance;