import {sortByDate, sortByKeysLength} from "../../helpers/dataHelpers/arrayObjectSorts";


export const performanceColumns = (getUniqueValues, transformDate) => ([
    {
        key: 'datetime',
        title: 'datetime',
        dataIndex: 'datetime',
        sorter: (a, b) => sortByDate(a['datetime'], b['datetime']),
        render: (time) => transformDate(time)
    },
    {
        key: 'service',
        title: 'service',
        dataIndex: 'service',
        filters: getUniqueValues('service'),
        onFilter: (value, record) => record?.service?.indexOf(value) === 0,
        filterSearch: true,
    },
    {
        key: 'function',
        title: 'function',
        dataIndex: 'function',
        filters: getUniqueValues('function'),
        onFilter: (value, record) => record?.function?.indexOf(value) === 0,
        filterSearch: true,
    },
    {
        key: 'key_name',
        title: 'key',
        dataIndex: 'key_name',
        width: '10%',
        filters: getUniqueValues('key_name'),
        onFilter: (value, record) => record?.key_name?.indexOf(value) === 0,
    },
    {
        key: 'iterations',
        title: 'iterations',
        dataIndex: 'iterations',
        width: '10%',
    },
    {
        key: 'active_turnarounds',
        title: 'active_turnarounds',
        dataIndex: 'active_turnarounds',
        width: '10%',
        sorter: (a, b) => sortByKeysLength(a['active_turnarounds'], b['active_turnarounds']),
        render: (turns, _) => {
            const tooltip = JSON.stringify(turns)
            return <span title={tooltip}>{Object.keys(turns).length}</span>
        }
    },
    {
        key: 'run_time',
        title: 'run_time',
        dataIndex: 'run_time',
        width: '10%',
        sorter: (a, b) => b['run_time'] - a['run_time']
    }
]);

export const performanceInfoColumns = (getUniqueValues, type, transformDate) => ([
    {
        key: 'service',
        title: 'service',
        dataIndex: 'service',
        width: '15%',
        filters: getUniqueValues('service'),
        onFilter: (value, record) => record?.service?.indexOf(value) === 0,
    },
    {
        key: 'method',
        title: 'function',
        dataIndex: 'method',
        width: '15%',
        filters: getUniqueValues('method'),
        onFilter: (value, record) => record?.method?.indexOf(value) === 0,
    },
    {
        key: 'key_name',
        title: 'key',
        dataIndex: 'key_name',
        width: '10%',
        filters: getUniqueValues('key_name'),
        onFilter: (value, record) => record?.key_name?.indexOf(value) === 0,
    },
    {
        key: 'first_entry',
        title: 'from',
        dataIndex: 'first_entry',
        width: '20%',
        hidden: type !== 'date-compare',
        sorter: (a, b) => b['first_entry']['run_time'] - a['first_entry']['run_time'],
        render: (value, _) => {
            return <span>{value['run_time']}</span>
        }
    },
    {
        key: 'last_entry',
        title: 'to',
        dataIndex: 'last_entry',
        width: '20%',
        hidden: type !== 'date-compare',
        sorter: (a, b) => b['last_entry']['run_time'] - a['last_entry']['run_time'],
        render: (value, _) => {
            return <span>{value['run_time']}</span>
        }
    },
    {
        key: 'min_entry',
        title: 'min runtime',
        dataIndex: 'min_entry',
        width: '20%',
        hidden: type !== 'min-max',
        sorter: (a, b) => b['min_entry']['run_time'] - a['min_entry']['run_time'],
        render: (entry, _) => {
            const color = entry['run_time'] > '0.1' ? (entry['run_time'] > '0.2' ? 'red' : 'black') : 'green';
            return <span style={{color: color}}
                         title={JSON.stringify(entry)}>{entry['run_time']} ({transformDate(entry['datetime'])})</span>
        }
    },
    {
        key: 'max_entry',
        title: 'max runtime',
        dataIndex: 'max_entry',
        width: '20%',
        hidden: type !== 'min-max',
        sorter: (a, b) => b['max_entry']['run_time'] - a['max_entry']['run_time'],
        render: (entry, _) => {
            const color = entry['run_time'] > '0.1' ? (entry['run_time'] > '0.2' ? 'red' : 'black') : 'green';
            return <span style={{color: color}}
                         title={JSON.stringify(entry)}>{entry['run_time']} ({transformDate(entry['datetime'])})</span>
        }
    },
    {
        key: 'average_runtime',
        title: 'average runtime',
        dataIndex: 'average_runtime',
        width: '20%',
        sorter: (a, b) => b['average_runtime'] - a['average_runtime']
    }
].filter(item => !item.hidden));

export const performanceExpandableColumns = () => ([
    {
        key: 'package',
        title: 'package',
        dataIndex: 'package',
    },
    {
        key: 'version',
        title: 'version',
        dataIndex: 'version',
    }
])

export const performanceInfoExpandableColumns = () => ([
    {
        key: 'pkg',
        title: 'package',
        dataIndex: 'pkg',
        render: (value, object) => {
            const color = object['version_min'] !== object['version_max'] ? 'red' : 'green';
            return <span style={{color: color}}>{value}</span>
        }
    },
    {
        key: 'version_min',
        title: 'version_min',
        dataIndex: 'version_min',
    },
    {
        key: 'version_max',
        title: 'version_max',
        dataIndex: 'version_max',
    }
])