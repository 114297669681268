import DropDownLabelPopConfirmItem from "../../../components/Customs/DropDownLabelPopConfirmItem";

export const objectEventDropDownMenuItems = (popConfirmTrueProps, popConfirmFalseProps) => [
    {
        key: true,
        label: (<DropDownLabelPopConfirmItem {...popConfirmTrueProps} />)
    },
    {
        key: false,
        label: (<DropDownLabelPopConfirmItem {...popConfirmFalseProps} />)
    },
]
