import {useMemo} from 'react';
import {useSelector} from "react-redux";
import {selectCctvIds, selectGateNames} from "../../../store/selectors/Api/gates";
import {createOptionsSelect} from "../../../utils/helpers/dataHelpers/arrayObjectModifiers";
import {Select} from "antd";

const ModesMonitoringHeader = ({gate, camera, onSelectGate, onSelectCctvId, mainClass}) => {
    const {gatesName, isGateNamesLoading} = useSelector(selectGateNames);
    const {cctvIds, isCctvIDsLoading} = useSelector(selectCctvIds);

    const modifiedGateNameList = useMemo(() => createOptionsSelect(["default", ...gatesName]),[gatesName]);
    const modifiedCctvIdList = useMemo(() => createOptionsSelect(["default", ...cctvIds]),[cctvIds]);

    return (
        <header className={mainClass}>
            <div className={"header_select_container"}>
                <label htmlFor={"custom-select-gate"} className={"header_select_container__label"}>Gate: </label>
                <Select
                    id={"custom-select-gate"}
                    placeholder={'Select gate name'}
                    value={gate}
                    onChange={onSelectGate}
                    loading={isGateNamesLoading}
                    options={modifiedGateNameList}
                />
            </div>

            <div className={"header_select_container"}>
                <label htmlFor={"custom-select-camera"} className={"header_select_container__label"}>Camera: </label>
                <Select
                    id={"custom-select-camera"}
                    placeholder={'Select cctv_id'}
                    value={camera}
                    onChange={onSelectCctvId}
                    loading={isCctvIDsLoading}
                    options={modifiedCctvIdList}
                    disabled={!cctvIds.length}
                />
            </div>

        </header>
    )
}

export default ModesMonitoringHeader;