import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../services/airAxios";
import {toggleIsFetching} from "../../slices/Api/Performance";


export const getPerformances = createAsyncThunk('performance/getPerformances',
    async (settings = {page: '', limit: '', body: {}}, {dispatch}) => {
    try {
        const {page, limit, body} = settings
        const {data} = await air_axios_settings.post(`/reporting/performance_paging?page=${page}&limit=${limit}`, body);
        return data;
    } catch (error) {
        throw new Error(error)
    } finally {
        dispatch(toggleIsFetching(false))
    }
})


export const getPerformanceInformation = createAsyncThunk('performance/analyzing',
    async (settings = {page: '', limit: '', body: {}}, {dispatch}) => {
    try {
        const {page, limit, body} = settings
        const {data} = await air_axios_settings.post(`/reporting/analyzing_paging?page=${page}&limit=${limit}`, body);
        return data
    } catch (error) {
        throw new Error(error)
    } finally {
        dispatch(toggleIsFetching(false))
    }
})
