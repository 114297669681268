import {createSlice} from "@reduxjs/toolkit";
import {login} from "../../asyncThunkFunctions/Api/AuthReqeusts";
import {clearLocalStorage, isAuthenticated} from "../../../utils/helpers/cacheHelpers";

const initialState = {
    profile: {},
    isAuth: Boolean(isAuthenticated()),
    isAuthLoading: false,
    error: null,
}

const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        handleLogout(state) {
            clearLocalStorage()
            state.isAuth = false
            state.user = {}
        },
        handleUpdateUser(state, {payload}) {
            state.profile = payload
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isAuthLoading = true
            state.error = null
        })
            .addCase(login.fulfilled, (state, {payload}) => {
                state.profile = payload
                state.isAuth = true
                state.isAuthLoading = false
            })
            .addCase(login.rejected, (state, {error}) => {
                state.isAuthLoading = false
                state.error = error.message
            })
    }
})


export default AuthSlice.reducer
export const {handleLogout, handleUpdateUser} = AuthSlice.actions