import {createSlice} from "@reduxjs/toolkit";
import {
    getCalibrationResult,
    getLastFrame, getUploadedFrame,
    getVideoFrame,
    readStream,
} from "../../asyncThunkFunctions/Api/ImageRequests";
import {updateCctvFrameState, updateUploadFrameState} from "../../../utils/constants/functions/redux";

const initialState = {
    cctv_frames: {},
    uploadFramesObj: {},
    calibrationResultObj: {
        calibrationResult: null,
        isCalibrationResLoading: false,
        calibrationResultError: null
    }
}

const imagesSlice = createSlice({
    name: 'imagesSlice',
    initialState,
    reducers: {
        onRemoveCamera(state, {payload}) {
            delete state.cctv_frames[payload]
        },
        onUploadInMultiFrameObj(state, {payload: {cctv_id, uploadedFrame}}) {

            if (!state.uploadFramesObj[cctv_id]) {
                state.uploadFramesObj[cctv_id] = {
                    uploadedFrame, uploadedError: null
                }
            }
            state.uploadFramesObj[cctv_id].uploadedFrame = uploadedFrame

            if (state.uploadFramesObj[cctv_id].uploadError) {
                state.uploadFramesObj[cctv_id].uploadError = null
            }

            if (state.cctv_frames[cctv_id].imgError) state.cctv_frames[cctv_id].imgError = null
        },
        onReturnInitCalibrationResult(state) {
            state.calibrationResultObj = initialState.calibrationResultObj
        },
        clearImgStates: () => initialState
    },
    extraReducers: (builder) => {

        const actions = [
            {action: getLastFrame, errorPrefix: 'lastFrame'},
            {action: readStream, errorPrefix: 'readStream'},
            {action: getVideoFrame, errorPrefix: 'videoFrame'},
        ];

        actions.forEach(({ action, errorPrefix }) => {
            const {pending, fulfilled, rejected} = action

            builder
                .addCase(pending, (state, { meta: { arg: { cctv_id } } }) => {
                    updateCctvFrameState(state, cctv_id, null, true, null, 'pending');
                })
                .addCase(fulfilled, (state, { payload, meta: { arg: { cctv_id } } }) => {
                    updateCctvFrameState(state, cctv_id, payload, false, null, 'fulfilled');
                })
                .addCase(rejected, (state, { error, meta }) => {
                    const { cctv_id, isReSendLastFrameHere, isReSendReadStreamHere } = meta.arg
                    const correct_prefix = {
                        readStream: isReSendLastFrameHere,
                        lastFrame: isReSendReadStreamHere
                    };
                    const isNotFound = error.message === "404"


                    if (correct_prefix[errorPrefix] && isNotFound) {
                        updateCctvFrameState(state, cctv_id, null, true, null, 'rejected');
                        console.log(errorPrefix, `found ${errorPrefix}`, isNotFound, 'status');
                    } else {
                        updateCctvFrameState(state, cctv_id, null, false, error.message, 'rejected');
                        console.log(`${errorPrefix} error in ${rejected}`);
                    }
                });
        });

        builder.addCase(getCalibrationResult.pending, (state) => {
            state.calibrationResultObj.isCalibrationResLoading = true
            state.calibrationResultObj.calibrationResultError = false

        })
            .addCase(getCalibrationResult.fulfilled, (state, {payload}) => {
                state.calibrationResultObj.calibrationResult = payload
                state.calibrationResultObj.isCalibrationResLoading = false
            })
            .addCase(getCalibrationResult.rejected, (state, {error}) => {
                state.calibrationResultObj.calibrationResultError = error.message
                state.calibrationResultObj.isCalibrationResLoading = false
            })


        builder.addCase(getUploadedFrame.pending, (state, {meta: {arg: cctv_id}}) => {
            updateUploadFrameState(state, cctv_id, null, true, null, 'pending');
        })
            .addCase(getUploadedFrame.fulfilled, (state, {payload, meta: {arg: cctv_id}}) => {
                updateUploadFrameState(state, cctv_id, payload, false, null, 'fulfilled');
            })

            .addCase(getUploadedFrame.rejected, (state, { error, meta: {arg: cctv_id}} ) => {
                console.log(error, "error")
                updateUploadFrameState(state, cctv_id, null, false, error.message, 'rejected');
            })
    }
})

const {reducer, actions} = imagesSlice

export const {clearImgStates, onRemoveCamera, onUploadNewFrame, onUploadInMultiFrameObj, onReturnInitCalibrationResult} = actions
export default reducer

