import NotFoundImage from '../../assets/fallback/not_found.jpg'
import ServerErrorImage from '../../assets/fallback/server_error.png'
import UnprocessableEntity from '../../assets/fallback/Http-422.jpg'

export const setErrorImage = (status, image) => {
    if (image) return image
    if (status === 404) return NotFoundImage
    if (status === 422) return UnprocessableEntity

    if (status >= 500) return ServerErrorImage
}