import {Modal} from "antd";
import InfoContent from "../../components/Customs/InfoContent";

export const showModalInfoContent = ({title, width, infoContent}) => {
    const {
        mainTitleText, toolsTitleText,
        toolsList, buttonsToolsList
    } = infoContent
    Modal.info({
        title,
        width,
        content: (
            <InfoContent
                footerData={buttonsToolsList}
                footerTitleText={'Buttons: '}
                headerData={mainTitleText}
                sectionData={toolsList}
                sectionTitleText={toolsTitleText}
            />
        )
    })
}