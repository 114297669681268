import {createSlice} from "@reduxjs/toolkit";
import {getMonitoredObjects} from "../../asyncThunkFunctions/Api/CamerasRequests";


const initialState = {
    monitoringObj: {
        monitoring: [],
        monitoringLoading: false,
        monitoringError: null,
    }
}


const CamerasSlice = createSlice({
    name: "cameras",
    initialState,
    reducers: {
      resetMonitoringObj (state) {
          state.monitoringObj = initialState.monitoringObj
      }
    },
    extraReducers: (builder) => {
        builder.addCase(getMonitoredObjects.pending, state => {
            state.monitoringObj.monitoringLoading = true;
            state.monitoringObj.monitoringError = null;
        })
            .addCase(getMonitoredObjects.fulfilled, (state, {payload}) => {
                state.monitoringObj.monitoringLoading = false;
                state.monitoringObj.monitoring = payload
            })
            .addCase(getMonitoredObjects.rejected, (state, {error: {message}}) => {
                state.monitoringObj.monitoringLoading = false;
                state.monitoringObj.monitoringError = message;
                state.monitoringObj.monitoring = []
            })
    }
})

const {reducer, actions} = CamerasSlice

export const {resetMonitoringObj} = actions

export default reducer;