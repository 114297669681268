import {CIRCLE_CLOSE, CLEAR} from "../../../components/icons/antd";

export const extrinsicDrawButtonsTools  = {
    "reset": {
        title: "Are you sure ?",
        okText: "Yes",
        cancelText: "No",
        icon: CLEAR,
        name: 'Delete All',
    },
    "save": {
        title: '"Discard" or "Save" changes ?',
        okText: "Save",
        cancelText: "Discard",
        name: "Close"
    },
    "close": {
        icon: CIRCLE_CLOSE,
        name: "Close",
    }
}