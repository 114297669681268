import {useMemo} from "react";
import {isEqual} from "../../utils/constants/functions/analiza";

export default function (array) {

    const isVisibility = useMemo(() => {
        const allMatchVisibilityAndPosition = array.every(({ visibility, positions }) => {
            return isEqual(visibility, positions);
        });
        const allVisibilityEmpty = array.every(({ visibility }) => visibility.length === 0);

        if (allMatchVisibilityAndPosition) return true;
        if (allVisibilityEmpty) return false;

        return null;
    }, [array]);


    const isRequired = useMemo(() => {
        const isExpectedAll = array.every(({required}) => required);
        const isSomeExpected = array.some(({required}) => required);

        if (isExpectedAll) return true
        if (isSomeExpected) return null
        return false
    }, [array]);

    return {isVisibility, isRequired}
}