import {useEffect} from 'react';
import {Route, Routes, useNavigate} from "react-router-dom";
import EditCamera from "./EditCamera";
import {useDispatch, useSelector} from "react-redux";
import {getLocalStorageGate, initialStateFormGate, toggleStorage} from "../../store/slices/Api/newGate";
import FormGate from "./FormGate";
import {sendCameras, sendDataGate} from "../../store/asyncThunkFunctions/Api/CamerasRequests";
import {FORM_GATE} from "../../utils/routeUtils/Paths"
import {CONFIGURATION_LINKS} from "../../utils/routeUtils/Links";
import {clearImgStates} from "../../store/slices/Api/Frames";
import {selectFormGate} from "../../store/selectors/Api/formAddGate";
import {clearSessionStorage} from "../../utils/helpers/cacheHelpers";
import {setInitialFileState} from "../../store/slices/Ui/Files";

const WrapperFormGate = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const {gate: {_id, cameras}, saveInStorage} = useSelector(selectFormGate);

    const {add, edit} = FORM_GATE

    const handleGoToConfiguration = () => {
        const {gateCameras} = CONFIGURATION_LINKS
        return navigate(gateCameras)
    }

    const handleAddNewGate = async () => {
        await dispatch(sendDataGate({_id, cameras}))
        await handleGoToConfiguration()
    }

    const handleAddNewCameras = async () => {
        await dispatch(sendCameras({cameras, _id}))
        await handleGoToConfiguration()
    }

    useEffect(() => {
        if (saveInStorage) {
            sessionStorage.setItem('gate', JSON.stringify({_id, cameras}))
        }
        return () => {
            dispatch(toggleStorage(false))
        }
    }, [saveInStorage])

    useEffect(() => {
        const gateStorage = JSON.parse(sessionStorage.getItem('gate'));

        if (gateStorage) dispatch(getLocalStorageGate(gateStorage))

        return () => {
            dispatch(initialStateFormGate())
            dispatch(clearImgStates())
            dispatch(setInitialFileState())
            clearSessionStorage()
        }
    }, []);

    return (
        <Routes>
            <Route path={'*'} element={<FormGate onSendRequest={handleAddNewGate}/>}/>
            <Route path={add} element={<FormGate onSendRequest={handleAddNewCameras}/>}/>
            <Route path={edit} element={<EditCamera/>}/>
        </Routes>
    );
};

export default WrapperFormGate;