import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../services/airAxios";
import {getTurnaround} from "./TurnaroundRequests";
import {handleAddAbortController, returnDefAbortControllerState} from "../../slices/Api/AbortController";
import {getTimestampInCorrectMilliSeconds} from "../../../utils/helpers/timeHelper";
import {addCameraId} from "../../slices/Api/newGate";

export const readStream = createAsyncThunk('imagesSlice/readStream',
    async (tools = {url: '', cctv_id: '', isReSendLastFrameHere: false}, {
        dispatch,
        getState
    }) => {

        dispatch(handleAddAbortController()) // AbortController func
        const {url, cctv_id, isReSendLastFrameHere} = tools
        const {AbortController: {abortController}} = getState().api // AbortController state

        dispatch(addCameraId(cctv_id))


        try {
            const configs = {responseType: 'blob', signal: abortController.signal}
            const {data, status} = await air_axios_settings.post(`/cameras/read_stream`, {url}, configs)

            if (status >= 200 && status <= 299) {
                dispatch(returnDefAbortControllerState()) // AbortController func
                return URL.createObjectURL(data)
            }
        } catch (error) {
            if (error.response.status === 404) {
                if (isReSendLastFrameHere) dispatch(getLastFrame({cctv_id, url}))
            }

            if (error.response && error.response.status) {
                console.log(error.response.status, 'status');
                throw new Error(error.response.status);
            } else {
                console.log('Unknown error occurred');
                throw new Error('500');
            }
        }
    })

export const getLastFrame = createAsyncThunk('imagesSlice/getLastFrame',
    async (tools = {cctv_id: '', url: '', isReSendReadStreamHere: false, width: null, height: null}, {
        dispatch,
        getState
    }) => {
        dispatch(handleAddAbortController()) // AbortController func
        const {cctv_id, url, isReSendReadStreamHere, ...rest} = tools
        const {AbortController: {abortController}} = getState().api

        dispatch(addCameraId(cctv_id))


        try {
            const configs = {params: {cctv_id, ...rest}, responseType: 'blob', signal: abortController.signal}
            const {data, status} = await air_axios_settings.get('/cameras/last_frame', configs);

            if (status >= 200 && status <= 299) dispatch(returnDefAbortControllerState()) // AbortController func

            return URL.createObjectURL(data);
        } catch (error) {
            const status = error.response.status
            if (status === 404) {
                if (isReSendReadStreamHere) dispatch(readStream({cctv_id, url}))
            }

            if (error.response && status) {
                throw new Error(status);
            } else {
                console.log('Unknown error occurred');
                throw new Error('500');
            }
        }
    })


export const getVideoFrame = createAsyncThunk('imagesSlice/getVideoFrame',
    async (params = {cctv_id: '', timestamp: ''}) => {
        try {
            const {data} = await air_axios_settings.get(
                '/videos/frame',
                {params, responseType: 'blob'}
            )
            return URL.createObjectURL(data)
        } catch (error) {
            const {response} = error
            if (!error.response) throw new Error('500')
            throw new Error(response.status)
        }
    })


// this request send inference data
export const getLive = createAsyncThunk('gates/getLive',
    async (params = {cctv_id: '', gate_id: ''}, {dispatch}) => {
        const cctv_id = params.cctv_id
        try {
            const {data} = await air_axios_settings.get('/cameras/live', {params})
            const {inference: {timestamp}, turnaround_id} = data
            if (turnaround_id) dispatch(getTurnaround(turnaround_id))

            if (timestamp) {
                dispatch(getVideoFrame({cctv_id, timestamp: getTimestampInCorrectMilliSeconds(timestamp)}));
            } else {
                dispatch(getLastFrame({cctv_id}));
            }
            return data
        } catch ({response: {status}}) {
            throw new Error(status)
        }
})

export const getCalibrationResult = createAsyncThunk("imageSlice/getCalibrationResult",
    async (cctv_id) => {
        try {
            const {data} = await air_axios_settings("cameras/satellite_frame", {params: {cctv_id}, responseType: "blob", headers: {"Cache-Control": "no-cache"}})
            return URL.createObjectURL(data)
        } catch (error) {
            throw new Error(error?.response?.status)
        }
    })

export const getUploadedFrame = createAsyncThunk('imageSlice/getUploadFrame',
    async (cctv_id) => {
    try {
        const {data} = await air_axios_settings("cameras/calibration_frame", {
            params: {cctv_id},
            responseType: "blob",
            headers: {"Cache-Control": "no-cache"}
        })
        return URL.createObjectURL(data)
    } catch (error) {
        throw new Error(error?.response?.status)
    }
})
