import {createAsyncThunk} from "@reduxjs/toolkit";
import air_axios_settings from "../../../services/airAxios";
import {toast} from "react-toastify";

export const getLogs = createAsyncThunk('Audit/getLogs',
    async (params) => {
        try {
            const {func, ...rest} = params
            const {data} = await air_axios_settings.get(
                '/audit',
                {
                    params: {...rest, function: func},
                    paramsSerializer: {indexes: null}
                })
            return data
        } catch (error) {
            const {response: {status}} = error
            if (status && status === 404) toast.error("Not Found")
            throw new Error(error)
        }
})

export const getUniqueLogsValues = createAsyncThunk("Audit/getUniqLogsValues",
    async (key) => {
    try {
        const {data} = await air_axios_settings.get(`/audit/unique/${key}`)
        return data
    } catch (error) {
        throw new Error(error)
    }
})